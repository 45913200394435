import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import GeoLocationResponseInterface from '../../interfaces/response/GeoLocationResponseInterface';
import Region from '../../interfaces/Region';

const searchAddresses: QueryFunction<GeoLocationResponseInterface | null> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) return null;
	const response = await api.get<GeoLocationResponseInterface>(`/api/geo-map/address?searchTerm=${queryKey[0]}&region=${queryKey[1]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetGeoAddresses = (searchTerm: string, region: Region) => {
	const { data, isLoading, isFetching, error } = useQuery({
		queryKey: [searchTerm, region, 'searchAddresses'],
		queryFn: searchAddresses,
		enabled: !!searchTerm,
	});

	return {
		addresses: data,
		isFetching,
		isLoading,
		error,
	};
};

export default useGetGeoAddresses;
