import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveUserInfo, AppState, AppStateState, initialState } from './AppState';
import MaintenanceMessage from '../../../interfaces/response/MaintenanceMessage';

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setMaintenanceMessage: (state: AppState, action: PayloadAction<MaintenanceMessage>) => {
			state.maintenanceMessage = action.payload;
		},
		setActiveUserInfo: (state: AppState, action: PayloadAction<ActiveUserInfo>) => {
			state.activeUserInfo = action.payload;
		},
		setAppStateState: (state: AppState, action: PayloadAction<AppStateState>) => {
			state.state = action.payload;
		},
		setUserAllowedTools: (state: AppState, action: PayloadAction<string[]>) => {
			state.availableTools = action.payload;
		},
	},
});

export const {
	setActiveUserInfo,
	setMaintenanceMessage,
	setAppStateState,
	setUserAllowedTools,
} = appSlice.actions;

export default appSlice.reducer;
