import React, { useCallback, useMemo, useState } from 'react';
import CreateArchiveFolderJobRequestInterface from '../../interfaces/request/CreateArchiveFolderJobRequestInterface';
import {
	Button,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Divider,
	Input,
	Label,
	Link,
	makeStyles,
	Spinner,
	Text,
	tokens,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import useGetFolderInfo from '../../hooks/queries/useGetFolderInfo';
import { getSizeInMegabytes } from '../../helpers/numberHelper';

type Props = {
	createArchiveJob: (request: CreateArchiveFolderJobRequestInterface) => void;
}

const useClasses = makeStyles({
	container: {
		maxWidth: '50%',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
	},
	fetchButton: {
		marginTop: tokens.spacingVerticalS,
	},
	folderInfoContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: tokens.spacingVerticalS,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: tokens.spacingHorizontalSNudge,
	},
	divider: {
		marginTop: tokens.spacingVerticalS,
		marginBottom: tokens.spacingVerticalS,
	},
	jobNameInput: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: tokens.spacingVerticalS,
	},
});

const CreateArchiveFolderJobModal: React.FC<Props> = ({ createArchiveJob }) => {
	const classes = useClasses();
	const { t, i18n: { language } } = useTranslation();
	const [sharepointUrl, setSharepointUrl] = useState<string>('');
	const getFolderInfo = useGetFolderInfo(sharepointUrl);

	const CloseButton = useMemo(() => {
		return (
			<DialogTrigger action="close">
				<Button
					appearance="subtle"
					aria-label="close"
					icon={<Dismiss24Regular />}
				/>
			</DialogTrigger>
		);
	}, []);

	const onChangeSharepointUrl: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
		setSharepointUrl(event.target.value);
	}, []);

	const fetchFolderInfo = () => {
		getFolderInfo.refetch();
	};

	const createPressed = () => {
		if (!getFolderInfo.folderInfo?.folderUrl || !getFolderInfo.folderInfo?.driveName) return;
		createArchiveJob({
			name: `ArchiveJob_${getFolderInfo.folderInfo.driveName}${getFolderInfo.folderInfo.folderPath}`,
			folderUrl: getFolderInfo.folderInfo.folderUrl,
			fileCount: getFolderInfo.folderInfo.fileCount,
			size: getFolderInfo.folderInfo.size,
			siteName: getFolderInfo.folderInfo.driveName,
		});
	};

	return (
		<DialogSurface className={classes.container}>
			<DialogBody>
				<DialogTitle action={CloseButton}>{t('toolsPage.archive.createNewTitle')}</DialogTitle>
				<DialogContent>
					<div className={classes.column}>
						<Label>{t('toolsPage.archive.sharepointShareUrl')}</Label>
						<Input value={sharepointUrl} onChange={onChangeSharepointUrl} />
					</div>
					<Button appearance={'primary'} disabled={sharepointUrl.length === 0} className={classes.fetchButton} onClick={fetchFolderInfo}>{t('toolsPage.archive.fetchFolderInfo')}</Button>
					{getFolderInfo.isFetching ? <Spinner /> : getFolderInfo.folderInfo && (
						<div className={classes.folderInfoContainer}>
							<Divider className={classes.divider} />
							<div className={classes.row}>
								<Text>{t('toolsPage.archive.siteName')}:</Text>
								<Text><b>{getFolderInfo.folderInfo.driveName}</b></Text>
							</div>
							<div className={classes.row}>
								<Text>{t('toolsPage.archive.folderPath')}:</Text>
								<Text><b>{getFolderInfo.folderInfo.folderPath}</b></Text>
							</div>
							<div className={classes.row}>
								<Text>{t('toolsPage.archive.totalSize')}:</Text>
								<Text><b>{getSizeInMegabytes(getFolderInfo.folderInfo.size, language)}</b></Text>
							</div>
							<div className={classes.row}>
								<Text>{t('toolsPage.archive.fileCount')}:</Text>
								<Text><b>{getFolderInfo.folderInfo.fileCount}</b></Text>
							</div>
							<div className={classes.row}>
								<Text>{t('toolsPage.archive.sharepointShareUrl')}:</Text>
								<Link href={getFolderInfo.folderInfo.folderUrl} target={'_blank'}><b>{getFolderInfo.folderInfo.folderUrl}</b></Link>
							</div>
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button appearance={'primary'}
							  disabled={!getFolderInfo.folderInfo?.folderUrl}
							  className={classes.fetchButton}
							  onClick={createPressed}>{t('toolsPage.archive.createJob')}</Button>
				</DialogActions>
			</DialogBody>
		</DialogSurface>
	);
};

export default CreateArchiveFolderJobModal;
