import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import TypeformFormDetailsResponseInterface, { TypeformFormDetailsField } from '../../interfaces/response/TypeformFormDetailsResponseInterface';
import TypeformPaginatedResponseInterface from '../../interfaces/response/TypeformPaginatedResponseInterface';
import TypeformFormResponseResponseInterface from '../../interfaces/response/TypeformFormResponseResponseInterface';

type TypeformResponseAnswer = {
	question: string;
	answer: string;
	id: string;
	type: string;
}

type TypeformResponse = {
	id: string;
	fields: TypeformFormDetailsField[],
	answers: TypeformResponseAnswer[];
	projectId?: string;
	projectLead?: string;
	date: Date;
	hidden: Map<string, string>;
}

const findField = (fields: TypeformFormDetailsField[], id: string): TypeformFormDetailsField | null => {
	const found = fields.find(f => f.id === id);
	if (found) return found;
	for (const field of fields) {
		if (field.id === id) {
			return field;
		}
		const child = findField(field.properties.fields, id);
		if (child) {
			return child;
		}
	}
	return null;
};

const getTypeformFormResponses: QueryFunction<TypeformResponse[]> = async ({ queryKey }: QueryFunctionContext) => {
	const [form, responses] = await Promise.all([
		api.get<TypeformFormDetailsResponseInterface>(`/api/tools/typeform/forms/${queryKey[0]}`),
		api.get<TypeformPaginatedResponseInterface<TypeformFormResponseResponseInterface>>(`/api/tools/typeform/forms/${queryKey[0]}/responses?since=${queryKey[1]}&until=${queryKey[2]}&pageSize=${1000}`),
	]);
	if (form.status !== 200) {
		throw new Error(form.data as any);
	}
	if (responses.status !== 200) {
		throw new Error(responses.data as any);
	}
	return responses.data.items.map(item => ({
		id: item.typeformResponse.responseId,
		projectId: item.projectId,
		projectLead: item.projectLead,
			fields: form.data.fields,
		date: item.typeformResponse.landedAt,
		answers: item.typeformResponse.answers?.map(answer => {
				const field = findField(form.data.fields, answer.field.id);
				if (field === null) {
					return null;
				}
				return {
					question: field.title,
					type: answer.type,
					answer: answer.value,
					id: answer.field.id,
				};
			})
				.filter(s => s !== null)
				.map(s => s as TypeformResponseAnswer) ?? [],
		hidden: item.typeformResponse.hidden,
		}),
	) ?? [];
};

const useGetTypeformFormResponses = (startDate: Date, endDate: Date, id: string) => {
	const { data, isLoading } = useQuery({
		queryKey: [id, startDate.toISOString(), endDate.toISOString(), 'getTypeformFormResponses'],
		queryFn: getTypeformFormResponses,
	});

	return {
		form: data,
		isLoading,
	};
};

export type { TypeformResponse };
export default useGetTypeformFormResponses;
