import { QueryFunction, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const emptyResult: NameAndId[] = [];

const getCompanies: QueryFunction<NameAndId[]> = async () => {
	let url = `/api/document/companies`;
	const response = await api.get<NameAndId[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetCompanies = () => {
	const { data, isLoading, error } = useQuery({
		queryKey: ['getCompanies'],
		queryFn: getCompanies,
	});

	return {
		results: data || emptyResult,
		isLoading,
		error,
	};
};

export default useGetCompanies;
