import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import UserInfoResponseInterface from '../../interfaces/response/UserInfoResponseInterface';

const getUserInfo: QueryFunction<UserInfoResponseInterface> = async ({ queryKey }: QueryFunctionContext) => {
	const response = await api.get<UserInfoResponseInterface>(`/api/users?email=${encodeURIComponent(queryKey[0] as string)}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetUserInfo = (email?: string) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [email, 'getUserInfo'],
		queryFn: getUserInfo,
		enabled: !!email,
	});

	return {
		userInfo: data,
		isLoading,
		error,
	};
};

export default useGetUserInfo;
