import NameAndId from '../NameAndId';
import GenerateDocumentDataInterface from '../request/GenerateDocumentDataInterface';
import Region from '../Region';

type GeneratedDocumentResponseInterface = {
	id: string;
	project?: NameAndId;
	contact?: NameAndId;
	client?: NameAndId;
	template: NameAndId;
	company: NameAndId;
	documentName: string;
	sharepointUrl?: string;
	status: Status;
	creationTime: Date;
	startTime?: Date;
	endTime?: Date;
	region: Region;
	data: GenerateDocumentDataInterface;
}

export enum Status {
	Queued,
	Processing,
	Success,
	Failed
}

export default GeneratedDocumentResponseInterface;
