import React, { useMemo } from 'react';
import { makeStyles, Title2, Toaster, tokens, useId } from '@fluentui/react-components';
import ToolsItem from '../components/tools/ToolsItem';
import { ReactComponent as ExcelLogo } from '../svg/xlsx.svg';
import { ReactComponent as TypeformLogo } from '../svg/typeform.svg';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ToolsItemDialog from '../components/tools/ToolsItemDialog';
import TloOfferteImport from '../components/tools/TloOfferteImport';
import TloOfferteExport from '../components/tools/TloOfferteExport';
import TypeformDialog from '../components/tools/TypeformDialog';
import DownloadTemplateToolDialog from '../components/tools/DownloadTemplateToolDialog';
import useValidateToolPermissions from '../hooks/useValidateToolPermissions';
import ToolId from '../interfaces/ToolId';
import { useAppSelector } from '../redux/useAppSelector';
import SharepointCleanupDialog from '../components/tools/SharepointCleanupDialog';

const useClasses = makeStyles({
	root: {
		flexDirection: 'column',
		flex: 1,
		display: 'flex',
		marginTop: tokens.spacingVerticalXXXL,
		marginBottom: tokens.spacingVerticalXXXL,
		marginLeft: tokens.spacingHorizontalXXXL,
		marginRight: tokens.spacingHorizontalXXXL,
	},
	toolsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: tokens.spacingVerticalL,
	},
	title: {
		marginBottom: tokens.spacingVerticalL,
	},
	headerLogo: {
		width: '3em',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
	},
});


const ToolsPage = () => {
	useValidateToolPermissions(ToolId.Tools);
	const tloOfferExportToast = useId();
	const classes = useClasses();
	const { t } = useTranslation();
	const excelIcon = useMemo(() => <div className={classes.headerLogo}><ExcelLogo /></div>, [classes]);
	const typeformIcon = useMemo(() => <div className={classes.headerLogo}><TypeformLogo /></div>, [classes]);
	const sharepointIcon = useMemo(() => <div className={classes.headerLogo}><img src={'sharepoint.png'} alt={'Sharepoint logo'} /></div>, [classes]);
	const navigate = useNavigate();
	const location = useLocation();
	const appState = useAppSelector(state => state.app);

	const navigateToArchivering = () => {
		navigate('archive');
	};

	return location?.pathname === '/tools' ? (
		<div className={classes.root}>
			<Title2 className={classes.title}>{t('toolsPage.yourTools')}</Title2>
			<Toaster toasterId={tloOfferExportToast} />
			<div className={classes.toolsContainer}>
				{appState.availableTools.includes(ToolId.ToolsTloOffers) && (
					<ToolsItemDialog
						content={t('toolsPage.tloOfferImport.description')}
						icon={excelIcon}
						title={t('toolsPage.tloOfferImport.title')}
						subTitle={'Teamleader Orbit'}>
						<TloOfferteImport />
					</ToolsItemDialog>
				)}
				{appState.availableTools.includes(ToolId.ToolsTloOffers) && (
					<ToolsItemDialog
						content={t('toolsPage.tloOfferExport.description')}
						icon={excelIcon}
						title={t('toolsPage.tloOfferExport.title')}
						subTitle={'Teamleader Orbit'}>
						{setIsOpen => <TloOfferteExport updateModalOpen={setIsOpen} toastId={tloOfferExportToast} />}
					</ToolsItemDialog>
				)}
				{appState.availableTools.includes(ToolId.ToolsArchive) && (
					<ToolsItem
						onClick={navigateToArchivering}
						content={t('toolsPage.archive.description')}
						icon={sharepointIcon}
						title={t('toolsPage.archive.title')}
						subTitle={'Sharepoint'}
					/>
				)}
				{appState.availableTools.includes(ToolId.ToolsArchive) && (
					<ToolsItemDialog
						content={t('toolsPage.cleanup.description')}
						icon={sharepointIcon}
						title={t('toolsPage.cleanup.title')}
						subTitle={'Sharepoint'}>
						<SharepointCleanupDialog />
					</ToolsItemDialog>
				)}
				{appState.availableTools.includes(ToolId.ToolsSatisfactionSurvey) && (
					<ToolsItemDialog
						content={t('toolsPage.tevredenheidsBevraging.description')}
						icon={typeformIcon}
						title={t('toolsPage.tevredenheidsBevraging.title')}
						subTitle={'Typeform'}>
						<TypeformDialog />
					</ToolsItemDialog>
				)}
				{appState.availableTools.includes(ToolId.ToolsTemplates) && (
					<ToolsItemDialog
						content={t('toolsPage.templates.downloadTitle')}
						icon={sharepointIcon}
						title={t('toolsPage.templates.downloadDescription')}
						subTitle={'Sharepoint'}>
						<DownloadTemplateToolDialog method={'Download'} />
					</ToolsItemDialog>
				)}
				{appState.availableTools.includes(ToolId.ToolsTemplates) && (
					<ToolsItemDialog
						content={t('toolsPage.templates.uploadTitle')}
						icon={sharepointIcon}
						title={t('toolsPage.templates.uploadDescription')}
						subTitle={'Sharepoint'}>
						{setIsOpen => <DownloadTemplateToolDialog updateModalOpen={setIsOpen} method={'Upload'} />}
					</ToolsItemDialog>
				)}
			</div>
		</div>
	) : <Outlet />;
};

export default ToolsPage;
