import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ArchiveProjectJobListResponseInterface from '../../interfaces/response/ArchiveProjectJobListResponseInterface';

const emptyResult: ArchiveProjectJobListResponseInterface[] = [];

const getArchiveProjectJobList: QueryFunction<ArchiveProjectJobListResponseInterface[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	const response = await api.get<ArchiveProjectJobListResponseInterface[]>(`/api/archive/projects?count=${queryKey[0]}&page=${queryKey[1]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetArchiveProjectJobList = (count: number, pageNr: number) => {
	const { data, isLoading, error, refetch } = useQuery({
		queryKey: [count, pageNr, 'getArchiveProjectJobList'],
		queryFn: getArchiveProjectJobList,
	});

	return {
		archiveJobs: data ?? emptyResult,
		isLoading,
		error,
		refetchArchiveJobs: refetch,
	};
};

export default useGetArchiveProjectJobList;
