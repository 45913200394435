import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ArchiveJobListResponseInterface from '../../interfaces/response/ArchiveJobListResponseInterface';

const emptyResult: ArchiveJobListResponseInterface[] = [];

const getArchiveFolderJobList: QueryFunction<ArchiveJobListResponseInterface[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	const response = await api.get<ArchiveJobListResponseInterface[]>(`/api/archive/folders?count=${queryKey[0]}&page=${queryKey[1]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetArchiveJobList = (count: number, pageNr: number) => {
	const { data, isLoading, error, refetch } = useQuery({
		queryKey: [count, pageNr, 'getArchiveFolderJobList'],
		queryFn: getArchiveFolderJobList,
	});

	return {
		archiveJobs: data ?? emptyResult,
		isLoading,
		error,
		refetchArchiveJobs: refetch,
	};
};

export default useGetArchiveJobList;
