enum ToolId {
	Documents = 'documents',
	Maps = 'maps',
	Chat = 'chat',
	Tools = 'tools',
	ToolsArchive = 'tools/archive',
	ToolsTloOffers = 'tools/tlo-offers',
	ToolsTemplates = 'tools/templates',
	ToolsSatisfactionSurvey = 'tools/satisfaction-survey',
	Telephony = 'telephony'
}

export default ToolId;
