import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useGenerateDocumentMutation from '../hooks/mutations/useGenerateDocumentMutation';
import { DocumentType } from '../interfaces/response/DocumentInfo';
import SharepointDocumentTemplateConfigurationResponse from '../interfaces/response/SharepointDocumentTemplateConfigurationResponse';
import SharepointDocumentReference from '../interfaces/request/SharepointDocumentReference';
import NameAndId from '../interfaces/NameAndId';
import { Button, Carousel, CarouselButton, CarouselCard, CarouselSlider, makeStyles, PositioningShorthand, ProgressBar, Text, tokens } from '@fluentui/react-components';
import Stepper from '../components/Stepper';
import DocumentGenerationSelectProjectStep from '../components/document-generation/DocumentGenerationSelectProjectStep';
import DocumentGenerationSelectDocumentOptions from '../components/document-generation/DocumentGenerationSelectDocumentOptions';
import DocumentGenerationSelectDocumentContent from '../components/document-generation/DocumentGenerationSelectDocumentContent';
import { useTranslation } from 'react-i18next';
import GenerateDocumentDataInterface from '../interfaces/request/GenerateDocumentDataInterface';
import Region from '../interfaces/Region';
import useValidateToolPermissions from '../hooks/useValidateToolPermissions';
import ToolId from '../interfaces/ToolId';
import GeneratedDocumentsOverview from '../components/GeneratedDocumentsOverview';
import GeneratedDocumentResponseInterface from '../interfaces/response/GeneratedDocumentResponseInterface';
import DocumentGenerationContext, { DocumentGenerationContextType } from '../context/DocumentGenerationContext';
import DocumentGenerationRequestDataContext from '../context/DocumentGenerationRequestDataContext';

const useClasses = makeStyles({
	root: {
		marginTop: tokens.spacingVerticalXXXL,
		flexDirection: 'column',
		flex: 1,
		display: 'flex',
	},
	title: {
		fontSize: tokens.fontSizeBase500,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalXXXL,
		display: 'flex',
	},
	generatingDocumentContainer: {
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		display: 'flex',
	},
	generatingDocumentSpinnerContainer: {
		width: '40em',
		flexDirection: 'column',
		alignItems: 'center',
		display: 'flex',
	},
	carouselContainer: {
		flexDirection: 'column',
		marginLeft: tokens.spacingHorizontalXXXL,
		marginRight: tokens.spacingHorizontalXXXL,
		flex: 1,
		marginTop: tokens.spacingVerticalXXXL,
		display: 'flex',
	},
	carouselButtonsContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: tokens.spacingVerticalXXXL,
		display: 'flex',
	},
	carouselSlider: {
		minHeight: 0,
	},
	flex1: {
		flex: 1,
		display: 'flex',
	},
	newGenerationButton: {
		marginTop: tokens.spacingVerticalL,
	},
	column: {
		flexDirection: 'column',
		width: '20em',
		display: 'flex',
	},
	flex: {
		flexDirection: 'column',
		display: 'flex',
	},
	scroll: {
		flexDirection: 'column',
		display: 'flex',
		minHeight: 0,
		overflow: 'scroll',
	},
});

const DocumentsPage: React.FC = () => {
	useValidateToolPermissions(ToolId.Documents);
	const { t } = useTranslation();
	const classes = useClasses();
	const generateDocumentMutation = useGenerateDocumentMutation();
	const [activeWizardStep, setActiveWizardStep] = useState<number>(0);
	const [selectedRegion, setSelectedRegion] = useState<Region>('Flanders');
	const [templateTypes, setTemplateTypes] = useState<DocumentType[]>([]);
	const [autofillBoundaryRef, setAutofillBoundaryRef] = useState<HTMLDivElement | null>(null);
	const [requestData, setRequestData] = useState<GenerateDocumentDataInterface>({ RequestData: {} });

	const setSelectedProject = useCallback((selectedProject?: NameAndId) => {
		setRequestData(prev => {
			const copy: GenerateDocumentDataInterface = { RequestData: { ...prev.RequestData } };
			copy.RequestData.ProjectId = selectedProject?.id;
			return copy;
		});
		setDocumentGenerationContext(prev => ({ ...prev, selectedProject }));
	}, []);

	const setSelectedClient = useCallback((selectedClient?: NameAndId) => {
		setRequestData(prev => {
			const copy: GenerateDocumentDataInterface = { RequestData: { ...prev.RequestData } };
			copy.RequestData.ClientId = selectedClient?.id;
			return copy;
		});
		setDocumentGenerationContext(prev => ({ ...prev, selectedClient }));
	}, []);

	const setSelectedContact = useCallback((selectedContact?: NameAndId) => {
		setRequestData(prev => {
			const copy: GenerateDocumentDataInterface = { RequestData: { ...prev.RequestData } };
			copy.RequestData.ContactId = selectedContact?.id;
			return copy;
		});
		setDocumentGenerationContext(prev => ({ ...prev, selectedContact }));
	}, []);

	const setSelectedCompany = useCallback((selectedCompany?: NameAndId) => {
		setRequestData(prev => {
			const copy: GenerateDocumentDataInterface = { RequestData: { ...prev.RequestData } };
			copy.RequestData.Company = selectedCompany?.id;
			return copy;
		});
		setDocumentGenerationContext(prev => ({ ...prev, selectedCompany }));
	}, []);

	const setSelectedDocument = useCallback((selectedDocument?: NameAndId) => {
		setRequestData(prev => {
			const copy: GenerateDocumentDataInterface = { RequestData: { ...prev.RequestData } };
			copy.RequestData.TemplateId = selectedDocument?.id;
			return copy;
		});
		setDocumentGenerationContext(prev => ({ ...prev, selectedDocument }));
	}, []);

	const updateRequestDataSection = useCallback((section: string, data: any) => {
		setRequestData(prev => {
			const copy = { ...prev };
			copy[section] = { ...data };
			return copy;
		});
	}, []);

	const [documentGenerationContext, setDocumentGenerationContext] = useState<DocumentGenerationContextType>({
		isCopyingExistingSettings: false,
		setSelectedClient,
		setSelectedContact,
		setSelectedProject,
		setSelectedCompany,
		setSelectedDocument,
		updateRequestDataSection,
	});

	const positioning = useMemo<PositioningShorthand>(() => ({
		autoSize: true,
		overflowBoundary: autofillBoundaryRef,
	}), [autofillBoundaryRef]);

	useEffect(() => {
		if (documentGenerationContext.isCopyingExistingSettings) return;
		const newTypeList: DocumentType[] = [];
		if (documentGenerationContext.selectedContact) {
			newTypeList.push('Contact');
		}
		if (documentGenerationContext.selectedClient) {
			newTypeList.push('Client');
		}
		if (documentGenerationContext.selectedProject) {
			newTypeList.push('Project');
		}
		if (newTypeList.join(',') === templateTypes.join(',')) return;
		setTemplateTypes(newTypeList);
		setDocumentGenerationContext(prev => ({ ...prev, selectedDocument: undefined }));
	}, [documentGenerationContext.selectedContact, documentGenerationContext.selectedClient, documentGenerationContext.selectedProject, templateTypes, documentGenerationContext.isCopyingExistingSettings]);

	const resetState = useCallback(() => {
		setActiveWizardStep(0);
		setRequestData(prevState => ({
			RequestData: {
				TemplateId: prevState.RequestData.TemplateId,
				Company: prevState.RequestData.Company,
			},
		}));
		setSelectedProject(undefined);
		setSelectedClient(undefined);
		setSelectedContact(undefined);
		setDocumentGenerationContext(prev => ({ ...prev, isCopyingExistingSettings: false }));
		generateDocumentMutation.reset();
	}, [generateDocumentMutation, setSelectedProject, setSelectedClient, setSelectedContact]);

	const isNextDisabled = () => {
		if (activeWizardStep === 0) {
			return templateTypes.length === 0;
		}
		if (activeWizardStep === 1) {
			return !documentGenerationContext.selectedDocument;
		}
		return false;
	};

	const generateDocument = (content: SharepointDocumentTemplateConfigurationResponse[]) => {
		if (!documentGenerationContext.selectedCompany || !documentGenerationContext.selectedDocument) return;
		generateDocumentMutation.mutate({
			DocumentName: documentGenerationContext.selectedDocument.name,
			DocumentSections: content.map(item => ({
				DocumentId: item.sharepointDocumentId,
				DriveId: item.sharepointDriveId,
			} as SharepointDocumentReference)),
			ProjectId: documentGenerationContext.selectedProject?.id,
			ContactId: documentGenerationContext.selectedContact?.id,
			ClientId: documentGenerationContext.selectedClient?.id,
			Data: requestData,
			Company: documentGenerationContext.selectedCompany.id,
			TemplateId: documentGenerationContext.selectedDocument.id,
			Region: selectedRegion,
		});
	};

	const onCarouselIndexChange = (_: any, { index }: { index: number }) => {
		setActiveWizardStep(index);
	};

	const generationText = useMemo(() => {
		if (generateDocumentMutation.generatedDocumentResponse) {
			return generateDocumentMutation.isError ? t('documentsPage.failedGeneratingDocument') : t('documentsPage.generatedDocument');
		} else {
			return t('documentsPage.generatingDocument');
		}
	}, [generateDocumentMutation, t]);

	const onCopyDocument = useCallback((document: GeneratedDocumentResponseInterface) => {
		setSelectedDocument(document.template);
		setSelectedProject(document.project);
		setSelectedCompany(document.company);
		setSelectedClient(document.client);
		setSelectedContact(document.contact);
		setRequestData(document.data);
		setActiveWizardStep(1);
		setDocumentGenerationContext(prev => ({ ...prev, isCopyingExistingSettings: true }));
	}, [setSelectedDocument, setSelectedProject, setSelectedCompany, setSelectedClient, setSelectedContact]);

	return (
		<DocumentGenerationContext.Provider value={documentGenerationContext}>
			<DocumentGenerationRequestDataContext.Provider value={requestData}>
				<div className={classes.root}>
					<Stepper currentStep={activeWizardStep} steps={['1', '2', '3']} />
					{generateDocumentMutation.isPending || !!generateDocumentMutation.generatedDocumentResponse ? (
						<div className={classes.generatingDocumentContainer}>
							<div className={classes.generatingDocumentSpinnerContainer}>
								<Text className={classes.title}>{generationText}</Text>
								{!generateDocumentMutation.generatedDocumentResponse && <ProgressBar thickness={'large'} />}
								{!!generateDocumentMutation.generatedDocumentResponse && (
									<div className={classes.column}>
										<Button className={classes.newGenerationButton} appearance={'primary'} size={'medium'} onClick={resetState}>{t('documentsPage.newGeneration')}</Button>
									</div>
								)}
							</div>
						</div>
					) : (
						<Carousel groupSize={1} className={classes.carouselContainer} onActiveIndexChange={onCarouselIndexChange} activeIndex={activeWizardStep}>
							<CarouselSlider className={classes.carouselSlider}>
								<CarouselCard className={classes.scroll}>
									<DocumentGenerationSelectProjectStep
										positioning={positioning}
										setSelectedRegion={setSelectedRegion}
									/>
									<GeneratedDocumentsOverview onCopyDocument={onCopyDocument} />
								</CarouselCard>
								<CarouselCard className={classes.flex}>
									<DocumentGenerationSelectDocumentOptions
										region={selectedRegion}
										types={templateTypes}
									/>
								</CarouselCard>
								<CarouselCard className={classes.flex}>
									<DocumentGenerationSelectDocumentContent
										region={selectedRegion}
										generateDocument={generateDocument}
									/>
								</CarouselCard>
							</CarouselSlider>
							<div className={classes.flex1} ref={setAutofillBoundaryRef} />
							<div className={classes.carouselButtonsContainer}>
								<CarouselButton navType={'prev'} type={'button'} appearance={'primary'}>
									{t('documentsPage.previous')}
								</CarouselButton>
								<div className={classes.flex1} />
								{activeWizardStep < 2 && (
									<CarouselButton navType={'next'} type={'button'} iconPosition={'after'} appearance={'primary'} disabled={isNextDisabled()}>
										{t('documentsPage.next')}
									</CarouselButton>)
								}
							</div>
						</Carousel>
					)}
				</div>
			</DocumentGenerationRequestDataContext.Provider>
		</DocumentGenerationContext.Provider>
	);
};

export default DocumentsPage;
