import React, { memo, useContext, useEffect, useReducer } from 'react';
import OptionSectionApiTemplateConfigurationResponse from '../../interfaces/response/OptionSectionApiTemplateConfigurationResponse';
import useGetDocumentApiOptionsList from '../../hooks/queries/useGetDocumentApiOptionsList';
import DocumentGenerationOption from './DocumentGenerationOption';
import { Button, makeStyles, Spinner, Text, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import DocumentGenerationContext from '../../context/DocumentGenerationContext';
import DocumentGenerationRequestDataContext from '../../context/DocumentGenerationRequestDataContext';
import { OptionTemplateConfiguration } from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';

type Props = {
	section: OptionSectionApiTemplateConfigurationResponse;
}

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},
	sectionName: {
		fontSize: tokens.fontSizeBase400,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalM,
	},
	emptyResultContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: tokens.spacingHorizontalL,
	},
});

type SectionProps = {
	section: OptionSectionApiTemplateConfigurationResponse,
	sectionData: any,
	refreshData: () => void;
	options: OptionTemplateConfiguration[];
	isFetching: boolean;
}

const Section: React.FC<SectionProps> = memo(({ section, sectionData, refreshData, options, isFetching }) => {
	const { selectedDocument } = useContext(DocumentGenerationContext);
	const classes = useClasses();
	const { t } = useTranslation();

	return (
		<div className={classes.container}>
			<Text className={classes.sectionName}>{section.name}</Text>
			{isFetching ? <Spinner /> : options.length > 0 ? options.map(option => (
				<DocumentGenerationOption
					data={sectionData}
					section={section.id}
					key={option.id + selectedDocument?.id}
					option={option} />
			)) : (
				<div className={classes.emptyResultContainer}>
					<Text>{t('documentsPage.nothingFound')}</Text>
					<Button onClick={refreshData}>{t('refresh')}</Button>
				</div>
			)}
		</div>
	);
});

const DocumentGenerationOptionSectionApi: React.FC<Props> = ({ section }) => {
	const requestData = useContext(DocumentGenerationRequestDataContext);
	const options = useGetDocumentApiOptionsList(requestData, section.endpoint);
	const [sectionData, updateSectionData] = useReducer(() => requestData[section.id], requestData[section.id] ?? {});

	useEffect(() => {
		if (!requestData?.[section.id] || JSON.stringify(sectionData) === JSON.stringify(requestData[section.id])) return;
		updateSectionData();
	}, [requestData, sectionData, section.id]);

	return <Section section={section} sectionData={sectionData} options={options.results} isFetching={options.isFetching} refreshData={options.refetch} key={section.id} />;
};

export default DocumentGenerationOptionSectionApi;
