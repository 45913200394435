import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ArchiveJobDetailsResponseInterface from '../../interfaces/response/ArchiveJobDetailsResponseInterface';


const getArchiveProjectJobDetails: QueryFunction<ArchiveJobDetailsResponseInterface> = async ({ queryKey }: QueryFunctionContext) => {
	const response = await api.get<ArchiveJobDetailsResponseInterface>(`/api/archive/projects/${queryKey[0]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetArchiveJobList = (jobId?: number) => {
	const { data, isLoading, error, refetch } = useQuery({
		queryKey: [jobId, 'getArchiveProjectJobDetails'],
		queryFn: getArchiveProjectJobDetails,
		enabled: !!jobId || jobId === 0,
	});

	return {
		jobDetails: data,
		isLoading,
		error,
		refetch,
	};
};

export default useGetArchiveJobList;
