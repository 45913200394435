import React from 'react';
import { makeStyles, Title1, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { ShieldProhibited24Regular } from '@fluentui/react-icons';

const useClasses = makeStyles({
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
		flexDirection: 'column',
		color: tokens.colorBrandForeground1,
	},
	icon: {
		marginBottom: tokens.spacingVerticalM,
		height: '16em',
		width: '16em',
	},
});

const UnauthorizedPage: React.FC = () => {
	const classes = useClasses();
	const { t } = useTranslation();
	return (
		<div className={classes.container}>
			<ShieldProhibited24Regular className={classes.icon} />
			<Title1>
				{t('unauthorized')}
			</Title1>
		</div>
	);
};

export default UnauthorizedPage;
