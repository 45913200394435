import React from 'react';
import useGetEntityInfo from '../hooks/queries/useGetEntityInfo';
import { Body1, Caption1, Link, makeStyles, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

type Props = {
	entityId: string;
}

const useClasses = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '15em',
	},
	smallLineHeight: {
		lineHeight: tokens.lineHeightBase200,
	},
	skeletonSpacing: {
		height: tokens.spacingHorizontalSNudge,
	},
});

const EntityInfo: React.FC<Props> = ({ entityId }) => {
	const { t } = useTranslation();
	const entityInfo = useGetEntityInfo(entityId);

	const classes = useClasses();

	if (!entityInfo.data) {
		return (
			<Skeleton className={classes.container}>
				<SkeletonItem size={16} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
			</Skeleton>
		);
	}
	return (
		<div className={classes.container}>
			<Body1>
				<Link as={'a'} target={'_blank'} href={`https://teamleaderorbit.unitedexperts.be/client/${entityInfo.data.clientId}`}>{entityInfo.data.name}</Link>
			</Body1>
			<Caption1 className={classes.smallLineHeight}>{!!entityInfo.data.address ? entityInfo.data.address : t('telephony.noAddressFound')}</Caption1>
			<Caption1 className={classes.smallLineHeight}>{entityInfo.data.legalEntityName}</Caption1>
			<Caption1 className={classes.smallLineHeight}>{t('telephony.projectCount', { count: entityInfo.data.projectCount })}</Caption1>
		</div>
	);
};

export default EntityInfo;
