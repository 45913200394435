import { MutateFunction, useMutation } from '@tanstack/react-query';
import { api } from '../../index';
import { AxiosResponse } from 'axios';

type UpdateExcelOfferRequestParams = {
	file: File;
	offerId: string;
	sheetIndex: string;
	totalValueColumn: string;
	singleValueColumn: string;
	amountColumn: string;
}

const updateExcelOfferMutation: MutateFunction<AxiosResponse, Error, UpdateExcelOfferRequestParams> = async (params) => {
	const formData = new FormData();
	formData.append('excelFile', params.file);
	const url = `/api/tools/update-excel-offer?offerId=${params.offerId}&sheetIndex=${params.sheetIndex}&amountColumn=${params.amountColumn}&singleValueColumn=${params.singleValueColumn}&totalValueColumn=${params.totalValueColumn}`;
	return await api.post(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		responseType: 'blob'
	});
};

const useUpdatExcelOfferMutation = () => {
	const { mutateAsync, isPending, data: response } = useMutation({
		mutationFn: updateExcelOfferMutation,
	});

	return {
		mutateAsync,
		isPending,
		response
	};
};

export default useUpdatExcelOfferMutation;
