import { CalendarStrings, DatePicker, DatePickerProps } from '@fluentui/react-datepicker-compat';
import { DayOfWeek } from '@fluentui/react-calendar-compat';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const LocalizedDatePicker: React.FC<DatePickerProps> = (props) => {
	const { t } = useTranslation();
	const calendarStrings = useMemo<CalendarStrings>(() => ({
		days: [
			t('datepicker.days.sunday'),
			t('datepicker.days.monday'),
			t('datepicker.days.tuesday'),
			t('datepicker.days.wednesday'),
			t('datepicker.days.thursday'),
			t('datepicker.days.friday'),
			t('datepicker.days.saturday'),
		], shortDays: [
			t('datepicker.shortDays.sunday'),
			t('datepicker.shortDays.monday'),
			t('datepicker.shortDays.tuesday'),
			t('datepicker.shortDays.wednesday'),
			t('datepicker.shortDays.thursday'),
			t('datepicker.shortDays.friday'),
			t('datepicker.shortDays.saturday'),
		],
		months: [
			t('datepicker.months.january'),
			t('datepicker.months.february'),
			t('datepicker.months.march'),
			t('datepicker.months.april'),
			t('datepicker.months.may'),
			t('datepicker.months.june'),
			t('datepicker.months.july'),
			t('datepicker.months.august'),
			t('datepicker.months.september'),
			t('datepicker.months.october'),
			t('datepicker.months.november'),
			t('datepicker.months.december'),
		],
		shortMonths: [
			t('datepicker.shortMonths.january'),
			t('datepicker.shortMonths.february'),
			t('datepicker.shortMonths.march'),
			t('datepicker.shortMonths.april'),
			t('datepicker.shortMonths.may'),
			t('datepicker.shortMonths.june'),
			t('datepicker.shortMonths.july'),
			t('datepicker.shortMonths.august'),
			t('datepicker.shortMonths.september'),
			t('datepicker.shortMonths.october'),
			t('datepicker.shortMonths.november'),
			t('datepicker.shortMonths.december'),
		],
		goToToday: t('datepicker.goToToday'),
	}), [t]);

	const onFormatDate = useCallback((date?: Date) => !date ? '' : `${date.getDate()} ${calendarStrings.months[date.getMonth()]} ${date.getFullYear()}`, [calendarStrings]);

	return <DatePicker {...props} strings={calendarStrings} formatDate={onFormatDate} firstDayOfWeek={DayOfWeek.Monday} />;
};

export default LocalizedDatePicker;
