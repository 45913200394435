import {
	Button,
	Carousel,
	CarouselCard,
	CarouselSlider,
	Checkbox,
	CheckboxOnChangeData,
	createTableColumn,
	DataGrid,
	DataGridBody,
	DataGridCell,
	DataGridHeader,
	DataGridHeaderCell,
	DataGridRow,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Input,
	Label,
	Link,
	makeStyles,
	OnSelectionChangeData,
	Spinner,
	Subtitle2,
	TableCellLayout,
	TableColumnDefinition,
	tokens,
} from '@fluentui/react-components';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ArchiveFilled, CheckmarkFilled, ChevronLeftFilled, ChevronRightFilled, Dismiss24Regular, DismissFilled } from '@fluentui/react-icons';
import LocalizedDatePicker from '../LocalizedDatePicker';
import useGetArchivableProjects from '../../hooks/queries/useGetArchivableProjects';
import ArchivableProjectResponseEntryInterface from '../../interfaces/response/ArchivableProjectResponseEntryInterface';
import UseGetArchivableProjectFolderInfo from '../../hooks/queries/useGetArchivableProjectFolderInfo';
import ArchiveJobListResponseInterface from '../../interfaces/response/ArchiveJobListResponseInterface';
import CreateArchiveProjectJobRequestEntryInterface from '../../interfaces/request/CreateArchiveProjectJobRequestEntryInterface';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getSizeInMegabytes } from '../../helpers/numberHelper';
import { SelectionItemId } from '@fluentui/react-utilities';
import { TableRowData } from '@fluentui/react-table';

type Props = {
	createArchiveJob: (entries: CreateArchiveProjectJobRequestEntryInterface[]) => void;
}

const useClasses = makeStyles({
	container: {
		maxWidth: '75%',
	},
	carouselContainer: {
		flexDirection: 'column',
		display: 'flex',
	},
	carouselButtonsContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: tokens.spacingVerticalXXXL,
		display: 'flex',
	},
	carouselSlider: {
		minHeight: 0,
	},
	flex1: {
		flex: 1,
		display: 'flex',
	},
	fieldsRow: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: tokens.spacingHorizontalM,
		marginBottom: tokens.spacingVerticalL,
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
	},
	table: {
		maxHeight: '60vh',
		overflowY: 'scroll',
	},
});

type SelectedProjectInfo = {
	projectId: string;
	projectNumber: string;
	companyName: string;
	projectName: string;
	fileCount: number;
	size: number;
	isTloProject: boolean;
	daysArchived: number;
	folderUrl?: string;
}

const createArchivableProjectsListColumns = (t: TFunction): TableColumnDefinition<ArchivableProjectResponseEntryInterface>[] => [
	createTableColumn<ArchivableProjectResponseEntryInterface>({
		columnId: 'projectNr',
		renderHeaderCell: () => t('toolsPage.archive.projectNumber'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.projectNumber}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchivableProjectResponseEntryInterface>({
		columnId: 'bedrijfsnaam',
		renderHeaderCell: () => t('toolsPage.archive.company'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.companyName}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchivableProjectResponseEntryInterface>({
		columnId: 'projectName',
		renderHeaderCell: () => t('toolsPage.archive.projectName'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.projectName}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchivableProjectResponseEntryInterface>({
		columnId: 'projectLead',
		renderHeaderCell: () => t('toolsPage.archive.projectLead'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.projectLead}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchivableProjectResponseEntryInterface>({
		columnId: 'isTloProject',
		renderHeaderCell: () => t('toolsPage.archive.tloProject'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.isTloProject ? <CheckmarkFilled /> : <DismissFilled />}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchivableProjectResponseEntryInterface>({
		columnId: 'daysArchived',
		renderHeaderCell: () => t('toolsPage.archive.daysArchived'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.daysArchived}
			</TableCellLayout>
		),
	}),
];

const createSelectedArchivableProjectsListColumns = (language: string, t: TFunction): TableColumnDefinition<SelectedProjectInfo>[] => [
	createTableColumn<SelectedProjectInfo>({
		columnId: 'projectNr',
		renderHeaderCell: () => t('toolsPage.archive.projectNumber'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.projectNumber}
			</TableCellLayout>
		),
	}),
	createTableColumn<SelectedProjectInfo>({
		columnId: 'bedrijfsnaam',
		renderHeaderCell: () => t('toolsPage.archive.company'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.companyName}
			</TableCellLayout>
		),
	}),
	createTableColumn<SelectedProjectInfo>({
		columnId: 'projectName',
		renderHeaderCell: () => t('toolsPage.archive.projectName'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.projectName}
			</TableCellLayout>
		),
	}),
	createTableColumn<SelectedProjectInfo>({
		columnId: 'fileCount',
		renderHeaderCell: () => t('toolsPage.archive.fileCount'),
		renderCell: (item) => (
			<TableCellLayout description={getSizeInMegabytes(item.size, language)}>
				{item.fileCount.toString()}
			</TableCellLayout>
		),
	}),
	createTableColumn<SelectedProjectInfo>({
		columnId: 'folderUrl',
		renderHeaderCell: () => t('sharepointUrl'),
		renderCell: (item) => (
			<TableCellLayout>
				{!!item.folderUrl ? <Link as={'a'} rel={'noreferrer'} href={item.folderUrl} target={'_blank'}>{t('toolsPage.archive.linkToSharepoint')}</Link> : t('toolsPage.archive.noUrlFound')}
			</TableCellLayout>
		),
	}),
	createTableColumn<SelectedProjectInfo>({
		columnId: 'isTloProject',
		renderHeaderCell: () => t('toolsPage.archive.tloProject'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.isTloProject ? <CheckmarkFilled /> : <DismissFilled />}
			</TableCellLayout>
		),
	}),
	createTableColumn<SelectedProjectInfo>({
		columnId: 'daysArchived',
		renderHeaderCell: () => t('toolsPage.archive.daysArchived'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.daysArchived}
			</TableCellLayout>
		),
	}),
];

const today = new Date();

const CreateArchiveProjectJobModal: React.FC<Props> = ({ createArchiveJob }) => {
	const [activeStep, setActiveStep] = useState(0);
	const { i18n: { language }, t } = useTranslation();
	const [amount, setAmount] = useState(100);
	const [monthsUnchanged, setMonthsUnchanged] = useState(12);
	const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear() - 2, 0));
	const [findTloProjects, setFindTloProjects] = useState<boolean>(true);
	const [projectLeadName, setProjectLeadName] = useState<string>('');
	const [projectNumber, setProjectNumber] = useState<string>('');
	const archiveableProjects = useGetArchivableProjects(monthsUnchanged, amount, startDate, findTloProjects, projectLeadName, projectNumber);
	const classes = useClasses();
	const [selectedRows, setSelectedRows] = useState<SelectionItemId[]>([]);
	const projectFolderInfoRequestList = useMemo(() => archiveableProjects.projects.filter(p => selectedRows.includes(p.id)), [archiveableProjects.projects, selectedRows]);
	const projectFolderInfo = UseGetArchivableProjectFolderInfo(projectFolderInfoRequestList);
	const selectedArchivableProjectsListColumns = useMemo(() => createSelectedArchivableProjectsListColumns(language, t), [language, t]);
	const archivableProjectsListColumns = useMemo(() => createArchivableProjectsListColumns(t), [t]);
	const selectedProjectsInfo = useMemo<SelectedProjectInfo[]>(() => {
		const list: SelectedProjectInfo[] = [];
		projectFolderInfo.folderInfoList.forEach(info => {
			const project = archiveableProjects.projects.find(p => p.id === info.projectId);
			if (project) {
				list.push({
					size: info.size,
					projectId: info.projectId,
					fileCount: info.fileCount,
					projectNumber: project.projectNumber,
					companyName: project.companyName,
					projectName: project.projectName,
					isTloProject: project.isTloProject,
					folderUrl: info.folderUrl,
					daysArchived: project.daysArchived,
				});
			}
		});
		return list;
	}, [projectFolderInfo.folderInfoList, archiveableProjects.projects]);

	useEffect(() => {
		const minMonthsArchived = 12;
		const minMonthsArchivedIfDecanalLiability = 18;
		const newSelectedRows: string[] = [];

		archiveableProjects.projects.forEach(p => {
			const archiveDate = new Date();
			archiveDate.setDate(archiveDate.getDate() - p.daysArchived);
			if (p.decanalLiability && archiveDate.setMonth(archiveDate.getMonth() + minMonthsArchivedIfDecanalLiability) < Date.now()) {
				newSelectedRows.push(p.id);
			} else if (!p.decanalLiability && archiveDate.setMonth(archiveDate.getMonth() + minMonthsArchived) < Date.now()) {
				newSelectedRows.push(p.id);
			}
		});
		setSelectedRows(newSelectedRows);
		// eslint-disable-next-line
	}, [archiveableProjects.projects]);

	const CloseButton = useMemo(() => {
		return (
			<DialogTrigger action="close">
				<Button
					appearance="subtle"
					aria-label="close"
					icon={<Dismiss24Regular />}
				/>
			</DialogTrigger>
		);
	}, []);

	const onCarouselIndexChange = (_: any, { index }: { index: number }) => {
		setActiveStep(index);
	};

	const onConfirmClicked = () => {
		if (activeStep === 0) {
			projectFolderInfo.refetch();
			setActiveStep(1);
		} else {
			const request: CreateArchiveProjectJobRequestEntryInterface[] = [];
			selectedProjectsInfo.forEach(project => {
				const archivableProject = archiveableProjects.projects.find(c => c.id === project.projectId);
				if (!archivableProject) return;
				request.push({
					archivableProject,
					fileCount: project.fileCount,
					hasValidFolderUrl: !!project.folderUrl,
					size: project.size,
				});
			});
			createArchiveJob(request);
		}
	};

	const onBackClicked = () => {
		setActiveStep(0);
	};

	const onChangeAmount: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
		setAmount(+event.target.value);
	}, []);

	const onChangeMonthsUnchanged: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
		setMonthsUnchanged(+event.target.value);
	}, []);

	const onChangeProjectLeadName: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
		setProjectLeadName(event.target.value);
	}, []);

	const onChangeProjectNumber: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
		setProjectNumber(event.target.value);
	}, []);

	const onStartDateChange = useCallback((date: Date | null | undefined) => {
		if (date) {
			setStartDate(date);
		}
	}, []);

	const onArchivableProjectSelectionChange = useCallback((_: any, data: OnSelectionChangeData) => {
		const projectIds: string[] = [];
		data.selectedItems.forEach(item => {
			projectIds.push(item.toString());
		});
		setSelectedRows(projectIds);
	}, []);

	const SelectedProjectsDataGrid = useMemo(() => (
		<DataGrid
			items={selectedProjectsInfo}
			columns={selectedArchivableProjectsListColumns}
			sortable
			resizableColumns={false}
			getRowId={(item) => item.id}
		>
			<DataGridHeader>
				<DataGridRow>
					{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
				</DataGridRow>
			</DataGridHeader>
			<DataGridBody<ArchiveJobListResponseInterface> className={classes.table}>
				{({ item, rowId }) => (
					<DataGridRow<ArchiveJobListResponseInterface> key={rowId}>
						{({ renderCell }) => (
							<DataGridCell>{renderCell(item)}</DataGridCell>
						)}
					</DataGridRow>
				)}
			</DataGridBody>
		</DataGrid>
	), [selectedProjectsInfo, classes.table, selectedArchivableProjectsListColumns]);

	const ProjectDataGrid = useMemo(() => (
		<DataGrid
			selectionMode={'multiselect'}
			selectionAppearance={'brand'}
			onSelectionChange={onArchivableProjectSelectionChange}
			items={archiveableProjects.projects}
			columns={archivableProjectsListColumns}
			resizableColumns={false}
			selectedItems={selectedRows}
			getRowId={(item) => item.id}
		>
			<DataGridHeader>
				<DataGridRow>
					{({ renderHeaderCell, columnId }) => <DataGridHeaderCell key={columnId}>{renderHeaderCell()}</DataGridHeaderCell>}
				</DataGridRow>
			</DataGridHeader>
			<DataGridBody<ArchivableProjectResponseEntryInterface> className={classes.table}>
				{(props) => <MemoizedProjectRow {...props} key={props.rowId} />}
			</DataGridBody>
		</DataGrid>
	), [archiveableProjects.projects, classes.table, onArchivableProjectSelectionChange, archivableProjectsListColumns, selectedRows]);

	const onFindTloProjectsChange = (_: any, data: CheckboxOnChangeData) => {
		setFindTloProjects(data.checked === true);
	};

	return (
		<DialogSurface className={classes.container}>
			<DialogBody>
				<DialogTitle action={CloseButton}>{t('toolsPage.archive.createNewTitle')}</DialogTitle>
				<DialogContent>
					<Carousel groupSize={1} className={classes.carouselContainer} onActiveIndexChange={onCarouselIndexChange} activeIndex={activeStep}>
						<CarouselSlider className={classes.carouselSlider}>
							<CarouselCard>
								<div className={classes.fieldsRow}>
									<div className={classes.column}>
										<Label>{t('toolsPage.archive.maximumResultsCount')}</Label>
										<Input min={1} value={amount.toString()} type={'number'} onChange={onChangeAmount} />
									</div>
									<div className={classes.column}>
										<Label>{t('toolsPage.archive.archivedAfterDate')}</Label>
										<LocalizedDatePicker value={startDate} onSelectDate={onStartDateChange} maxDate={today} />
									</div>
									<div className={classes.column}>
										<Label>{t('toolsPage.archive.monthsArchived')}</Label>
										<Input min={1} value={monthsUnchanged.toString()} type={'number'} onChange={onChangeMonthsUnchanged} />
									</div>
									<div className={classes.column}>
										<Label>{t('toolsPage.archive.nameProjectLead')}</Label>
										<Input value={projectLeadName} type={'text'} onChange={onChangeProjectLeadName} />
									</div>
									<div className={classes.column}>
										<Label>{t('toolsPage.archive.projectNumber')}</Label>
										<Input value={projectNumber} type={'text'} onChange={onChangeProjectNumber} />
									</div>
									<div className={classes.column}>
										<Label>{t('toolsPage.archive.searchTloProjects')}</Label>
										<Checkbox checked={findTloProjects} onChange={onFindTloProjectsChange} />
									</div>
								</div>
								{archiveableProjects.isFetching ? <Spinner /> : archiveableProjects.projects.length > 0 ? ProjectDataGrid : <Subtitle2>{t('toolsPage.archive.noProjectsFound')}</Subtitle2>}
							</CarouselCard>
							<CarouselCard>
								{projectFolderInfo.isFetching ? <Spinner /> : SelectedProjectsDataGrid}
							</CarouselCard>
						</CarouselSlider>
					</Carousel>
				</DialogContent>
				<DialogActions>
					<Button type={'button'}
							  iconPosition={'after'}
							  icon={activeStep === 0 ? <ChevronRightFilled /> : <ArchiveFilled />}
							  appearance={activeStep === 0 ? 'secondary' : 'primary'}
							  disabled={activeStep === 0 ? selectedRows.length === 0 : projectFolderInfo.isFetching}
							  onClick={onConfirmClicked}
					>
						{activeStep === 0 ? t('toolsPage.archive.verify') : t('toolsPage.archive.createJob')}
					</Button>
					{activeStep === 1 && (
						<Button type={'button'} iconPosition={'before'} icon={<ChevronLeftFilled />} appearance={'secondary'} onClick={onBackClicked}>
							{t('toolsPage.archive.backToSelection')}
						</Button>
					)}
				</DialogActions>
			</DialogBody>
		</DialogSurface>
	);
};

const ProjectRow: React.FC<TableRowData<ArchivableProjectResponseEntryInterface>> = (({ rowId, item }) => (
	<DataGridRow<ArchivableProjectResponseEntryInterface> key={rowId}>
		{({ renderCell, columnId }) => <DataGridCell key={columnId}>{renderCell(item)}</DataGridCell>}
	</DataGridRow>
));
const MemoizedProjectRow = memo(ProjectRow, (prev, next) => prev.rowId === next.rowId);

export default CreateArchiveProjectJobModal;
