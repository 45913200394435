import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const searchUsers: QueryFunction<NameAndId[]> = async ({ queryKey }: QueryFunctionContext) => {
	const url = `/api/tlo/users?name=${queryKey[0]}`;
	const response = await api.get<NameAndId[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const emptyResult: NameAndId[] = [];

const useSearchUsers = (name?: string) => {
	const { data, isLoading, isFetching, isError } = useQuery({
		queryKey: [name, 'searchUsers'],
		queryFn: searchUsers,
		enabled: !!name,
	});

	return {
		data: data ?? emptyResult,
		isLoading,
		isFetching,
		isError,
	};
};

export default useSearchUsers;
