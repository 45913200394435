import MaintenanceMessage from '../../../interfaces/response/MaintenanceMessage';

export interface ActiveUserInfo {
	id: string;
	username: string;
	accessToken: string;
	isAdmin: boolean;
}

export type AppState = {
	activeUserInfo?: ActiveUserInfo;
	maintenanceMessage: MaintenanceMessage;
	availableTools: string[];
	state: AppStateState;
}

export enum AppStateState {
	SettingUserInfo,
	SettingLanguage,
	CheckingMaintenance,
	FetchingAllowedTools,
	LoadingChats,
	Ready,
	Error
}

export const initialState: AppState = {
	maintenanceMessage: {
		inMaintenance: false,
	},
	availableTools: [],
	state: AppStateState.SettingUserInfo,
};
