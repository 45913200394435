import React, { memo, useMemo, useState } from 'react';
import {
	Body1,
	Button,
	createTableColumn,
	DataGrid,
	DataGridBody,
	DataGridCell,
	DataGridHeader,
	DataGridHeaderCell,
	DataGridRow,
	Link,
	makeStyles,
	Spinner,
	TableCellLayout,
	TableColumnDefinition,
	Title2,
	tokens,
	Tooltip,
} from '@fluentui/react-components';
import useGetGeneratedDocumentsList from '../hooks/queries/useGetGeneratedDocumentsList';
import GeneratedDocumentResponseInterface from '../interfaces/response/GeneratedDocumentResponseInterface';
import generatedDocumentResponseInterface, { Status } from '../interfaces/response/GeneratedDocumentResponseInterface';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ArrowClockwiseFilled, ArrowSyncCircleFilled, CheckmarkCircleFilled, CopyRegular, ErrorCircleFilled, OpenRegular, SubtractCircleFilled } from '@fluentui/react-icons';

type Props = {
	onCopyDocument: (document: generatedDocumentResponseInterface) => void;
}

const useClasses = makeStyles({
	title: {
		marginTop: tokens.spacingHorizontalXXXL,
	},
	container: {
		paddingLeft: tokens.spacingHorizontalXXXL,
		paddingRight: tokens.spacingHorizontalXXXL,
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: tokens.spacingVerticalM,
	},
	grid: {
		userSelect: 'none',
	},
	noBackgroundColor: {
		backgroundColor: 'unset !important',
	},
	refreshButton: {
		marginTop: tokens.spacingVerticalM,
		marginBottom: tokens.spacingVerticalM,
		width: '10em',
		flexShrink: 0,
	},
	noOverflow: {
		overflow: 'hidden',
	}
});

const dateStringToDisplayName = (date: Date, language: string): string => {
	const dateObject = new Date(date);
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	};
	return dateObject.toLocaleDateString(language === 'nl' ? 'nl-BE' : language === 'fr' ? 'fr-BE' : language, options);
};

const getStatusIcon = (status: Status, t: TFunction) => {
	switch (status) {
		case Status.Queued:
			return (
				<Tooltip
					content={t('documentsPage.stateWaiting')}
					positioning="above-start"
					withArrow
					relationship="label">
					<SubtractCircleFilled style={{ fontSize: tokens.fontSizeHero700 }} />
				</Tooltip>
			);
		case Status.Processing:
			return (
				<Tooltip
					content={t('documentsPage.stateGenerating')}
					positioning="above-start"
					withArrow
					relationship="label">
					<ArrowSyncCircleFilled style={{ color: tokens.colorPaletteYellowForeground1, fontSize: tokens.fontSizeHero700 }} />
				</Tooltip>
			);
		case Status.Success:
			return (
				<Tooltip
					content={t('documentsPage.stateSuccess')}
					positioning="above-start"
					withArrow
					relationship="label">
					<CheckmarkCircleFilled style={{ color: tokens.colorPaletteGreenForeground1, fontSize: tokens.fontSizeHero700 }} />
				</Tooltip>
			);
		case Status.Failed:
			return (
				<Tooltip
					content={t('documentsPage.stateError')}
					positioning="above-start"
					withArrow
					relationship="label">
					<ErrorCircleFilled style={{ color: tokens.colorPaletteRedForeground1, fontSize: tokens.fontSizeHero700 }} />
				</Tooltip>
			);

	}
};

const createColumns = (t: TFunction, language: string, onCopyDocument: (document: GeneratedDocumentResponseInterface) => void): TableColumnDefinition<GeneratedDocumentResponseInterface>[] => ([
	createTableColumn<GeneratedDocumentResponseInterface>({
		columnId: 'name',
		renderHeaderCell: () => t('documentsPage.nameColumn'),
		renderCell: (item) => (
			<TableCellLayout>
				<Tooltip
					content={item.documentName}
					positioning="above-start"
					withArrow
					relationship="label">
					<Body1 wrap={false}>{item.documentName}</Body1>
				</Tooltip>
			</TableCellLayout>
		),
	}),
	createTableColumn<GeneratedDocumentResponseInterface>({
		columnId: 'client',
		renderHeaderCell: () => t('documentsPage.clientColumn'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.client ? <Link target={'_blank'} href={`https://teamleaderorbit.unitedexperts.be/client/${item.client.id}`}>{item.client.name}</Link> : '/'}
			</TableCellLayout>
		),
	}),
	createTableColumn<GeneratedDocumentResponseInterface>({
		columnId: 'contact',
		renderHeaderCell: () => t('documentsPage.contactColumn'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.contact ? <Link target={'_blank'} href={`https://teamleaderorbit.unitedexperts.be/contact/${item.contact.id}`}>{item.contact.name}</Link> : '/'}
			</TableCellLayout>
		),
	}),
	createTableColumn<GeneratedDocumentResponseInterface>({
		columnId: 'project',
		renderHeaderCell: () => t('documentsPage.projectColumn'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.project ? <Link target={'_blank'} href={`https://teamleaderorbit.unitedexperts.be/project/${item.project.id}`}>{item.project.name}</Link> : '/'}
			</TableCellLayout>
		),
	}),
	createTableColumn<GeneratedDocumentResponseInterface>({
		columnId: 'company',
		renderHeaderCell: () => t('documentsPage.company'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.company.name}
			</TableCellLayout>
		),
	}),
	createTableColumn<GeneratedDocumentResponseInterface>({
		columnId: 'creationDate',
		renderHeaderCell: () => t('documentsPage.creationDateColumn'),
		renderCell: (item) => (
			<TableCellLayout>
				{dateStringToDisplayName(item.creationTime, language)}
			</TableCellLayout>
		),
	}),
	createTableColumn<GeneratedDocumentResponseInterface>({
		columnId: 'state',
		renderHeaderCell: () => t('documentsPage.stateColumn'),
		renderCell: (item) => (
			<TableCellLayout>
				{getStatusIcon(item.status, t)}
			</TableCellLayout>
		),
	}),
	createTableColumn<GeneratedDocumentResponseInterface>({
		columnId: 'sharepointLink',
		renderHeaderCell: () => t('documentsPage.actionsColumn'),
		renderCell: (item) => (
			<TableCellLayout>
				<Tooltip
					content={t('documentsPage.copyThisDocument')}
					positioning="above-start"
					withArrow
					relationship="label">
					<Button icon={<CopyRegular />} onClick={() => onCopyDocument(item)} />
				</Tooltip>
				{item.sharepointUrl && (
					<Tooltip
						content={t('documentsPage.toTheDocument')}
						positioning="above-start"
						withArrow
						relationship="label">
						<Link target={'_blank'} href={item.sharepointUrl}><Button icon={<OpenRegular />} /></Link>
					</Tooltip>
				)}
			</TableCellLayout>
		),
	}),
]);

const GeneratedDocumentsOverview: React.FC<Props> = ({ onCopyDocument }) => {
	const { t, i18n } = useTranslation();
	const classes = useClasses();
	const [page] = useState(0);
	const [count] = useState(10);
	const documentsList = useGetGeneratedDocumentsList(page, count);
	const columns = useMemo(() => createColumns(t, i18n.language, onCopyDocument), [t, i18n, onCopyDocument]);

	const refreshList = () => {
		documentsList.refetch();
	};

	return (
		<div className={classes.container}>
			<Title2 className={classes.title}>{t('documentsPage.yourGeneratedDocuments')}</Title2>
			<Button onClick={refreshList} className={classes.refreshButton} appearance={'primary'} icon={<ArrowClockwiseFilled />}>{t('refresh')}</Button>
			{documentsList.isFetching ? <Spinner /> : documentsList.data ? (
				<DataGrid
					items={documentsList.data}
					columns={columns}
					sortable={false}
					className={classes.grid}
					getRowId={(item) => item.id}>
					<DataGridHeader>
						<DataGridRow>
							{({ renderHeaderCell }) => (
								<DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
							)}
						</DataGridRow>
					</DataGridHeader>
					<DataGridBody<GeneratedDocumentResponseInterface>>
						{({ item, rowId }) => (
							<DataGridRow<GeneratedDocumentResponseInterface> key={rowId} className={classes.noBackgroundColor}>
								{({ renderCell }) => (
									<DataGridCell className={classes.noOverflow}>{renderCell(item)}</DataGridCell>
								)}
							</DataGridRow>
						)}
					</DataGridBody>
				</DataGrid>
			) : (
				<Body1>{t('documentsPage.loadingDocumentsErrorMessage')}</Body1>
			)}
		</div>
	);
};

export default memo(GeneratedDocumentsOverview);
