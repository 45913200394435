import {
	Button,
	Combobox,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Label,
	makeStyles,
	OptionOnSelectData,
	Select,
	SelectionEvents,
	Spinner,
	Toast,
	ToastBody,
	ToastTitle,
	tokens,
	useComboboxFilter,
	useToastController,
} from '@fluentui/react-components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dismiss24Regular } from '@fluentui/react-icons';
import AutofillSearchBar from '../AutofillSearchBar';
import NameAndId from '../../interfaces/NameAndId';
import useGetClientList from '../../hooks/queries/useGetClientList';
import useGetEntitiesList from '../../hooks/queries/useGetEntitiesList';
import useGetOffersList from '../../hooks/queries/useGetOffersList';
import useUpdatExcelOfferMutation from '../../hooks/mutations/useUpdateExcelOfferMutation';
import { downloadBlob } from '../../helpers/axiosHelper';
import { useTranslation } from 'react-i18next';
import FileInput from '../FileInput';

type Props = {
	updateModalOpen: (open: boolean) => void;
	toastId: string;
}

const useClasses = makeStyles({
	content: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '10px',
		maxHeight: '70vh',
	},
	listContainer: {
		flexDirection: 'column',
		display: 'flex',
		rowGap: tokens.spacingVerticalS,
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
});
const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const TloOfferteExport: React.FC<Props> = ({ updateModalOpen, toastId }) => {
	const classes = useClasses();
	const [selectedEntity, setSelectedEntity] = useState<NameAndId>();
	const [selectedClient, setSelectedClient] = useState<NameAndId>();
	const [selectedOffer, setSelectedOffer] = useState<NameAndId>();
	const [clientSearchTerm, setClientSearchTerm] = useState<string>('');
	const [entitySearchTerm, setEntitySearchTerm] = useState<string>('');
	const [offerSearchTerm, setOfferSearchTerm] = useState<string>('');
	const searchedClients = useGetClientList(clientSearchTerm);
	const searchedEntities = useGetEntitiesList(selectedClient?.id);
	const searchedOffers = useGetOffersList('Draft', selectedEntity?.id);
	const fileUploadRef = useRef<HTMLInputElement>(null);
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	const [selectedFileName, setSelectedFileName] = useState<string>();
	const updateExcelOfferMutation = useUpdatExcelOfferMutation();
	const [selectedTotalValueColumn, setSelectedTotalValueColumn] = useState<string>('G');
	const [selectedSingleValueColumn, setSelectedSingleValueColumn] = useState<string>('F');
	const [selectedAmountColumn, setSelectedAmountColumn] = useState<string>('E');
	const { dispatchToast } = useToastController(toastId);
	const { t } = useTranslation();

	const CloseButton = useMemo(() => {
		return (
			<DialogTrigger action="close">
				<Button
					ref={closeButtonRef}
					appearance="subtle"
					aria-label="close"
					icon={<Dismiss24Regular />}
				/>
			</DialogTrigger>
		);
	}, []);

	useEffect(() => {
		setSelectedEntity(undefined);
		setEntitySearchTerm('');
	}, [selectedClient]);

	useEffect(() => {
		setSelectedOffer(undefined);
		setOfferSearchTerm('');
	}, [selectedEntity]);

	const onSearchEntity = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
		setEntitySearchTerm(event.target.value);
	}, []);

	const onSearchOffer = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
		setOfferSearchTerm(event.target.value);
	}, []);

	const onSelectEntity = useCallback((_: SelectionEvents, data: OptionOnSelectData) => {
		const option = searchedEntities.entityResults.find(entity => entity.id === data.optionValue);
		setSelectedEntity(option);
		setEntitySearchTerm(option?.name ?? '');
	}, [searchedEntities]);

	const onSelectOffer = useCallback((_: SelectionEvents, data: OptionOnSelectData) => {
		const option = searchedOffers.offerResults.find(offer => offer.id === data.optionValue);
		setSelectedOffer(option);
		setOfferSearchTerm(option?.name ?? '');
	}, [searchedOffers]);

	const entityOptions = useComboboxFilter(entitySearchTerm, searchedEntities.entityResults.map(entity => ({
		children: entity.name,
		value: entity.id,
	})), {
		optionToText: (option) => option.children,
		noOptionsMessage: searchedEntities.isLoading ? t('loading') : searchedEntities.isFetched ? t('toolsPage.noEntitiesFound') : t('toolsPage.selectClientFirst'),
	});

	const offerOptions = useComboboxFilter(offerSearchTerm, searchedOffers.offerResults.map(offer => ({
		children: offer.name,
		value: offer.id,
	})), {
		optionToText: (option) => option.children,
		noOptionsMessage: searchedOffers.isLoading ? t('loading') : searchedOffers.isFetched ? t('toolsPage.tloOfferExport.noOffersFound') : t('toolsPage.tloOfferExport.selectEntityFirst'),
	});

	const onExportClicked: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
		if (!selectedOffer || !fileUploadRef.current?.files || fileUploadRef.current.files.length === 0) return;
		const name = `UPDATED-${fileUploadRef.current!.files![0].name}`;
		updateExcelOfferMutation.mutateAsync({
			file: fileUploadRef.current!.files![0],
			offerId: selectedOffer.id,
			sheetIndex: '0',
			amountColumn: selectedAmountColumn,
			totalValueColumn: selectedTotalValueColumn,
			singleValueColumn: selectedSingleValueColumn,
		}).then(response => {
			downloadBlob(response, name)
		}).then(() => {
			dispatchToast(
				<Toast>
					<ToastTitle>{t('toolsPage.tloOfferExport.toast.title')}</ToastTitle>
					<ToastBody>{t('toolsPage.tloOfferExport.toast.description')}</ToastBody>
				</Toast>,
				{ intent: 'success' },
			);
			updateModalOpen(false);
		});
	}, [updateExcelOfferMutation, selectedOffer, updateModalOpen, dispatchToast, t, selectedAmountColumn, selectedTotalValueColumn, selectedSingleValueColumn]);

	const onChangeSelectedAmountColumn: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
		setSelectedAmountColumn(event.target.value);
	};

	const onChangeSelectedSingleValueColumn: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
		setSelectedSingleValueColumn(event.target.value);
	};

	const onChangeSelectedTotalValueColumn: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
		setSelectedTotalValueColumn(event.target.value);
	};

	return (
		<DialogSurface>
			<DialogBody>
				<DialogTitle action={CloseButton}>{t('toolsPage.tloOfferExport.title')}</DialogTitle>
				<DialogContent className={classes.content}>
					{updateExcelOfferMutation.isPending ? <Spinner /> : (
						<>
							<div className={classes.listContainer}>
								<AutofillSearchBar required
														 loading={searchedClients.isFetching}
														 onSearch={setClientSearchTerm}
														 searchedSearchTerm={clientSearchTerm}
														 label={t('toolsPage.client')}
														 options={searchedClients.clientResults}
														 onSelectEntry={setSelectedClient}
														 placeholder={t('typeToSearch')}
														 selectedEntry={selectedClient}
								/>
								<div className={classes.column}>
									<Label required>Entity</Label>
									<Combobox
										clearable
										freeform
										onOptionSelect={onSelectEntity}
										placeholder={t('typeToSearch')}
										onChange={onSearchEntity}
										value={entitySearchTerm}
									>
										{entityOptions}
									</Combobox>
								</div>
								<div className={classes.column}>
									<Label required>Offer</Label>
									<Combobox
										clearable
										freeform
										onOptionSelect={onSelectOffer}
										placeholder={t('typeToSearch')}
										onChange={onSearchOffer}
										value={offerSearchTerm}
									>
										{offerOptions}
									</Combobox>
								</div>
							</div>
							<div className={classes.column}>
								<Label>{t('toolsPage.tloOfferImport.amountColumn')}</Label>
								<Select value={selectedAmountColumn} onChange={onChangeSelectedAmountColumn}>
									{columns.map(c => <option value={c} key={c}>{c}</option>)}
								</Select>
							</div>
							<div className={classes.column}>
								<Label>{t('toolsPage.tloOfferImport.singleValueColumn')}</Label>
								<Select value={selectedSingleValueColumn} onChange={onChangeSelectedSingleValueColumn}>
									{columns.map(c => <option value={c} key={c}>{c}</option>)}
								</Select>
							</div>
							<div className={classes.column}>
								<Label>{t('toolsPage.tloOfferImport.totalValueColumn')}</Label>
								<Select value={selectedTotalValueColumn} onChange={onChangeSelectedTotalValueColumn}>
									{columns.map(c => <option value={c} key={c}>{c}</option>)}
								</Select>
							</div>
							<FileInput title={t('toolsPage.tloOfferExport.excelFile')}
										  accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
										  setFilename={setSelectedFileName}
										  ref={fileUploadRef} />
						</>
					)}
				</DialogContent>
				<DialogActions>
					{!updateExcelOfferMutation.isPending &&
						<Button appearance={'primary'} disabled={!selectedFileName || !selectedOffer} onClick={onExportClicked}>{t('toolsPage.tloOfferExport.export')}</Button>}
				</DialogActions>
			</DialogBody>
		</DialogSurface>
	);
};

export default TloOfferteExport;
