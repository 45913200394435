import { MutateFunction, useMutation } from '@tanstack/react-query';
import { api } from '../../index';
import CreateTloOfferRequestInterface from '../../interfaces/request/CreateTloOfferRequestInterface';

const createOfferMutation: MutateFunction<string, Error, CreateTloOfferRequestInterface> = async (body) => {
	const response = await api.post<string>(`/api/tlo/offer`, JSON.stringify(body), {
		headers: {
			'Content-Type': 'application/json',
		},
	});
	if (response.status !== 201) {
		throw new Error(response.data);
	}
	return response.headers['location'] ?? response.headers['Location'];
};

const useCreateOfferMutation = () => {
	const { isSuccess, mutate, isError, error, data } = useMutation({
		mutationFn: createOfferMutation,
	});

	return {
		mutate,
		isError,
		isSuccess,
		error,
		response: data,
	};
};

export default useCreateOfferMutation;
