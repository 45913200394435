import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import GeneratedDocumentResponseInterface from '../../interfaces/response/GeneratedDocumentResponseInterface';


const getGeneratedDocumentsList: QueryFunction<GeneratedDocumentResponseInterface[]> = async ({ queryKey }: QueryFunctionContext) => {
	const response = await api.get<GeneratedDocumentResponseInterface[]>(`/api/document/list?page=${queryKey[0]}&count=${queryKey[1]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetGeneratedDocumentsList = (page: number, count: number) => {
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: [page, count, 'getGeneratedDocumentsList'],
		queryFn: getGeneratedDocumentsList,
	});

	return {
		data,
		isFetching,
		error,
		refetch,
	};
};

export default useGetGeneratedDocumentsList;
