import {
	Button,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Dropdown,
	Label,
	makeStyles,
	Option,
	OptionOnSelectData,
	SelectionEvents,
	Spinner,
	Text,
	tokens,
} from '@fluentui/react-components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import useGetDocumentInfoList from '../../hooks/queries/useGetDocumentInfoList';
import Region from '../../interfaces/Region';
import useGetDocumentSectionInfoList from '../../hooks/queries/useGetDocumentSectionInfoList';
import SharepointDocumentTemplateConfigurationResponse from '../../interfaces/response/SharepointDocumentTemplateConfigurationResponse';
import { api } from '../../index';
import useUploadSharepointDocument from '../../hooks/mutations/useSharepointDocument';
import FileInput from '../FileInput';
import NameAndId from '../../interfaces/NameAndId';

type Props = {
	method: 'Upload' | 'Download'
	updateModalOpen?: (open: boolean) => void;
}

const CloseButton = (
	<DialogTrigger action="close">
		<Button
			appearance="subtle"
			aria-label="close"
			icon={<Dismiss24Regular />}
		/>
	</DialogTrigger>
);

const useClasses = makeStyles({
	column: {
		flexDirection: 'column',
		gap: '2px',
		width: '100%',
		display: 'flex',
	},
	flex: {
		display: 'flex',
	},
	container: {
		gap: tokens.spacingVerticalSNudge,
		display: 'flex',
		flexDirection: 'column',
	},
	button: {
		marginTop: tokens.spacingVerticalSNudge,
	},
});

const DownloadTemplateToolDialog: React.FC<Props> = ({ method, updateModalOpen }) => {
	const fileUploadRef = useRef<HTMLInputElement>(null);
	const uploadSharepointDocument = useUploadSharepointDocument();
	const { t } = useTranslation();
	const classes = useClasses();
	const [selectedRegion, setSelectedRegion] = useState<Region>('Flanders');
	const documents = useGetDocumentInfoList(['Project', 'Client', 'Contact'], selectedRegion);
	const [selectedDocument, setSelectedDocument] = useState<NameAndId>();
	const templates = useGetDocumentSectionInfoList(selectedRegion, selectedDocument?.id);
	const sharepointDocuments = useMemo(() =>
			templates.results.filter(c => c.type === 'SharepointDocument').map(c => c as SharepointDocumentTemplateConfigurationResponse)
		, [templates.results],
	);
	const [selectedTemplate, setSelectedTemplate] = useState<SharepointDocumentTemplateConfigurationResponse>();
	const regions = useMemo<{ id: Region, name: string }[]>(() => {
		return [
			{
				id: 'Flanders',
				name: t('navigation.flanders'),
			},
			{
				id: 'Wallonia',
				name: t('navigation.wallonia'),
			},
		];
	}, [t]);

	useEffect(() => {
		if (documents.results.length === 0 || documents.isLoading || !!selectedDocument) return;
		setSelectedDocument(documents.results[0]);
	}, [documents.results, selectedDocument, documents.isLoading]);

	useEffect(() => {
		if (sharepointDocuments.length === 0 || templates.isLoading || !!selectedTemplate) return;
		setSelectedTemplate(sharepointDocuments[0]);
	}, [sharepointDocuments, selectedTemplate, templates.isLoading]);

	const onRegionSelect = (_: SelectionEvents, data: OptionOnSelectData) => {
		setSelectedRegion(data.optionValue! as Region);
		setSelectedDocument(undefined);
		setSelectedTemplate(undefined);
	};

	const onDocumentSelected = (_: SelectionEvents, data: OptionOnSelectData) => {
		setSelectedDocument(documents.results.find(document => document.id === data.optionValue)!);
		setSelectedTemplate(undefined);
	};

	const onTemplateSelected = (_: SelectionEvents, data: OptionOnSelectData) => {
		setSelectedTemplate(sharepointDocuments.find(document => document.id === data.optionValue)!);
	};

	const downloadUrl = useMemo(() => {
		if (!selectedTemplate) return '';
		return `${api.defaults.baseURL}/api/sharepoint/download?driveId=${selectedTemplate.sharepointDriveId}&documentId=${selectedTemplate.sharepointDocumentId}`;
	}, [selectedTemplate]);

	const uploadDocument = useCallback(() => {
		if (!selectedTemplate || !fileUploadRef.current!.files || fileUploadRef.current!.files.length === 0) return;
		uploadSharepointDocument.mutateAsync({
			documentId: selectedTemplate.sharepointDocumentId,
			driveId: selectedTemplate.sharepointDriveId,
			file: fileUploadRef.current!.files![0],
		}).then(() => {
			updateModalOpen?.(false);
		});
	}, [selectedTemplate, uploadSharepointDocument, updateModalOpen]);

	return (
		<DialogSurface>
			<DialogBody>
				<DialogTitle action={CloseButton}>{method === 'Download' ? t('toolsPage.templates.downloadTitle') : t('toolsPage.templates.uploadTitle')}</DialogTitle>
				<DialogContent className={classes.container}>
					<div className={classes.column}>
						<Label>{t('documentsPage.region')}</Label>
						<Dropdown onOptionSelect={onRegionSelect} defaultValue={t('navigation.flanders')}>
							{regions.map((region) => (
								<Option key={region.id} value={region.id}>
									{region.name}
								</Option>
							))}
						</Dropdown>
					</div>
					{(documents.isLoading || (documents.results.length > 0 && !selectedDocument)) ? (
						<div className={classes.flex}>
							<Spinner />
						</div>
					) : documents.results.length === 0 ? <Text>{t('documentsPage.noTemplates')}</Text> : (
						<div className={classes.column}>
							<Label>{t('toolsPage.templates.documentType')}</Label>
							<Dropdown onOptionSelect={onDocumentSelected} defaultValue={selectedDocument!.name} defaultSelectedOptions={[selectedDocument!.id]}>
								{documents.results.map((document) => (
									<Option key={document.id} value={document.id}>
										{document.name}
									</Option>
								))}
							</Dropdown>
						</div>
					)}
					{(templates.isLoading || (templates.results.length > 0 && !selectedTemplate)) ? (
						<div className={classes.flex}>
							<Spinner />
						</div>
					) : templates.results.length === 0 ? <Text>{t('documentsPage.noTemplates')}</Text> : (
						<div className={classes.column}>
							<Label>{t('toolsPage.templates.templates')}</Label>
							<Dropdown onOptionSelect={onTemplateSelected} defaultValue={selectedTemplate!.name} defaultSelectedOptions={[selectedTemplate!.id]}>
								{sharepointDocuments.map((template) => (
									<Option key={template.id} value={template.id}>
										{template.name}
									</Option>
								))}
							</Dropdown>
						</div>
					)}
					{method === 'Upload' && (
						<FileInput title={t('toolsPage.templates.uploadButtonTitle')}
									  accept={'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
									  ref={fileUploadRef} />

					)}
				</DialogContent>
				<DialogActions>
					{method === 'Upload' ? (
						<Button appearance={'primary'}
								  onClick={uploadDocument}
								  className={classes.button}
								  disabled={!selectedTemplate || uploadSharepointDocument.isPending}>{t('toolsPage.templates.upload')}</Button>
					) : (
						<Button appearance={'primary'}
								  as={'a'}
								  href={downloadUrl}
								  target={'_self'}
								  className={classes.button}
								  disabled={!selectedTemplate}>{t('toolsPage.templates.download')}</Button>
					)}
				</DialogActions>
			</DialogBody>
		</DialogSurface>
	);
};

export default DownloadTemplateToolDialog;
