import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const emptyResult: NameAndId[] = [];

const getOfferList: QueryFunction<NameAndId[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	const response = await api.get<NameAndId[]>(`/api/tlo/offers?entityId=${queryKey[1]}&state=${queryKey[0]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetOffersList = (offerState: 'Draft' | 'Aanvaard' | 'Interne goedkeuring' | 'Wacht' | 'Geweigerd', entityId?: string) => {
	const { data, isLoading, error, isFetched } = useQuery({
		queryKey: [offerState, entityId, 'getOfferList'],
		queryFn: getOfferList,
		enabled: !!entityId,
	});

	return {
		offerResults: !!entityId ? (data || emptyResult) : emptyResult,
		isLoading,
		error,
		isFetched
	};
};

export default useGetOffersList;
