import React, { useMemo } from 'react';
import useGetUserInfo from '../hooks/queries/useGetUserInfo';
import useGetUserPresence from '../hooks/queries/useGetUserPresence';
import { Body1, Button, makeStyles, Persona, PersonaProps, PresenceBadgeStatus, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components';
import { api } from '../index';
import { UserPresenceActivity } from '../interfaces/response/UserPresenceResponseInterface';
import { useTranslation } from 'react-i18next';
import UserInfoResponseInterface from '../interfaces/response/UserInfoResponseInterface';

type Props = {
	userMail: string;
	onClick?: (userInfo: UserInfoResponseInterface) => void;
}

const convertAvailabilityToPresence = (activity: UserPresenceActivity): PresenceBadgeStatus => {
	switch (activity) {
		case 'Available':
			return 'available';
		case 'Away':
		case 'BeRightBack':
		case 'Inactive':
			return 'away';
		case 'Busy':
			return 'busy';
		case 'DoNotDisturb':
		case 'Presenting':
		case 'UrgentInterruptionsOnly':
			return 'do-not-disturb';
		case 'InACall':
		case 'InAConferenceCall':
		case 'InAMeeting':
			return 'blocked';
		case 'Offline':
			return 'offline';
		case 'OffWork':
		case 'OutOfOffice':
			return 'out-of-office';
		case 'PresenceUnknown':
			return 'unknown';

	}
};

const useClasses = makeStyles({
	button: {
		minWidth: '12em',
	},
	skeletonContainer: {
		minWidth: '12em',
		display: 'flex',
		flexDirection: 'row',
	},
	skeletonInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		marginLeft: tokens.spacingHorizontalSNudge,
	},
	skeletonSpacing: {
		height: tokens.spacingHorizontalSNudge,
	},
});

const UserInfo: React.FC<Props> = ({ userMail, onClick }) => {
	const classes = useClasses();
	const userInfo = useGetUserInfo(userMail);
	const { t } = useTranslation();
	const userPresence = useGetUserPresence(userMail);
	const profileImg = useMemo<PersonaProps['avatar']>(() => ({
		image: {
			src: `${api.defaults.baseURL}/api/users/photo?email=${userMail}`,
		},
		name: userInfo.userInfo ? userInfo.userInfo.name : undefined,
		size: 32,
	}), [userMail, userInfo.userInfo]);

	const presence = useMemo<PersonaProps['presence']>(() => ({
		status: userPresence.presence ? convertAvailabilityToPresence(userPresence.presence.activity) : 'unknown',
		size: 'small',
	}), [userPresence.presence]);

	const onClickPersona = () => {
		if (!userInfo.userInfo) return;
		onClick?.(userInfo.userInfo);
	};

	return (!userInfo.userInfo || !userPresence.presence) ? userInfo.isLoading ? (
		<Skeleton className={classes.skeletonContainer}>
			<SkeletonItem size={32} shape={'circle'} />
			<div className={classes.skeletonInnerContainer}>
				<SkeletonItem size={16} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={8} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={8} />
			</div>

		</Skeleton>
	) : <Body1>{t('telephony.userNotFound', { user: userMail })}</Body1> : (
		<Button className={classes.button} onClick={onClickPersona} appearance={'subtle'}>
			<Persona size={'medium'}
						primaryText={userInfo.userInfo.name}
						secondaryText={userInfo.userInfo.jobTitle}
						tertiaryText={t(`userActivity.${userPresence.presence.activity}`)}
						presence={presence}
						avatar={profileImg}
			/>
		</Button>
	);
};

export default UserInfo;
