import useGetArchiveProjectJobList from '../hooks/queries/useGetArchiveProjectJobList';
import React, { useCallback, useMemo, useState } from 'react';
import {
	Button,
	createTableColumn,
	DataGrid,
	DataGridBody,
	DataGridCell,
	DataGridHeader,
	DataGridHeaderCell,
	DataGridRow,
	Dialog,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Link,
	makeStyles,
	SelectTabData,
	SelectTabEvent,
	Spinner,
	Tab,
	TableCellLayout,
	TableColumnDefinition,
	TabList,
	Text,
	Title2,
	Toast,
	ToastBody,
	Toaster,
	ToastTitle,
	tokens,
	useId,
	useToastController,
} from '@fluentui/react-components';
import { ArrowClockwiseFilled, CheckmarkCircle24Filled, ErrorCircle24Filled, MoreCircle24Filled, OpenRegular, SubtractCircle24Filled } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';
import useGetArchiveJobDetails from '../hooks/queries/useGetArchiveProjectJobDetails';
import ArchiveJobDetailsProjectEntryInterface from '../interfaces/response/ArchiveJobDetailsProjectEntryInterface';
import CreateArchiveProjectJobModal from '../components/tools/CreateArchiveProjectJobModal';
import useCreateArchiveProjectJob from '../hooks/mutations/useCreateArchiveProjectJob';
import CreateArchiveProjectJobRequestEntryInterface from '../interfaces/request/CreateArchiveProjectJobRequestEntryInterface';
import { TFunction } from 'i18next';
import ArchiveProjectJobListResponseInterface from '../interfaces/response/ArchiveProjectJobListResponseInterface';
import ArchiveJobListResponseInterface from '../interfaces/response/ArchiveJobListResponseInterface';
import useGetArchiveFolderJobList from '../hooks/queries/useGetArchiveFolderJobList';
import CreateArchiveFolderJobModal from '../components/tools/CreateArchiveFolderJobModal';
import CreateArchiveFolderJobRequestEntryInterface from '../interfaces/request/CreateArchiveFolderJobRequestInterface';
import useCreateArchiveFolderJob from '../hooks/mutations/useCreateArchiveFolderJob';
import { getSizeInMegabytes } from '../helpers/numberHelper';
import useValidateToolPermissions from '../hooks/useValidateToolPermissions';
import ToolId from '../interfaces/ToolId';

const useClasses = makeStyles({
	container: {
		marginTop: tokens.spacingVerticalXXXL,
		marginBottom: tokens.spacingVerticalXXXL,
		flexDirection: 'column',
		flex: 1,
		display: 'flex',
		marginLeft: tokens.spacingHorizontalXXXL,
		marginRight: tokens.spacingHorizontalXXXL,
	},
	table: {
		overflowY: 'scroll',
		width: '100%',
		userSelect: 'none',
		cursor: 'pointer',
		flexShrink: 1,
		maxHeight: '70vh',
	},
	title: {
		marginBottom: tokens.spacingVerticalL,
	},
	createArchiveJobButton: {
		marginBottom: tokens.spacingVerticalL,
		flexShrink: 0,
		display: 'flex',
		width: 'fit-content',
	},
	jobDetailsModalContainer: {
		maxWidth: '75%',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: tokens.spacingHorizontalSNudge,
	},
	lastRow: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: tokens.spacingHorizontalSNudge,
		marginBottom: tokens.spacingVerticalXXL,
	},
	refreshButton: {
		marginBottom: '16px',
	},
	tabList: {
		marginBottom: tokens.spacingVerticalS,
	},
});

const parseStartDate = (language: string, date?: Date) => {
	const intl = new Intl.DateTimeFormat(language, {
		day: 'numeric',
		year: 'numeric',
		month: 'short',
		hour: 'numeric',
		minute: 'numeric',
	});
	return date ? intl.format(new Date(date)) : 'Not started';
};

const parseTimePassed = (timePassed?: string) => {
	if (!timePassed) {
		return 'onbekend';
	}
	const timeSections = timePassed?.split(':') ?? ['00', '00', '00'];
	const hours = +timeSections[0];
	const minutes = +timeSections[1];
	const seconds = +timeSections[2].slice(0, 2);

	let result = '';
	if (hours > 0) {
		result += `${hours}h `;
	}
	if (minutes > 0) {
		result += `${minutes}m `;
	}

	result += `${seconds}s`;
	return result;
};

const createArchiveFolderJobListColumns = (language: string, t: TFunction): TableColumnDefinition<ArchiveJobListResponseInterface>[] => [
	createTableColumn<ArchiveJobListResponseInterface>({
		columnId: 'jobName',
		renderHeaderCell: () => t('toolsPage.archive.name'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.jobName}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobListResponseInterface>({
		columnId: 'start',
		renderHeaderCell: () => t('toolsPage.archive.startTime'),
		renderCell: (item) => (
			<TableCellLayout>
				{parseStartDate(language, item.start)}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobListResponseInterface>({
		columnId: 'status',
		renderHeaderCell: () => t('toolsPage.archive.state'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.status === 'Waiting' ? <SubtractCircle24Filled style={{ color: 'grey' }} /> :
					item.status === 'Running' ? <MoreCircle24Filled style={{ color: 'orange' }} /> :
						item.status === 'Success' ? <CheckmarkCircle24Filled style={{ color: 'green' }} /> :
							item.status === 'Error' ? <ErrorCircle24Filled style={{ color: 'red' }} /> : null
				}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobListResponseInterface>({
		columnId: 'amountOfFiles',
		renderHeaderCell: () => t('toolsPage.archive.fileCount'),
		renderCell: (item) => (
			<TableCellLayout description={getSizeInMegabytes(item.totalSize, language)}>
				{item.amountOfFiles.toString()}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobListResponseInterface>({
		columnId: 'duration',
		renderHeaderCell: () => t('toolsPage.archive.duration'),
		renderCell: (item) => (
			<TableCellLayout>
				{parseTimePassed(item.timePassed)}
			</TableCellLayout>
		),
	}),

];
const createArchiveProjectJobListColumns = (setActiveJobId: (id: number) => void, language: string, t: TFunction): TableColumnDefinition<ArchiveProjectJobListResponseInterface>[] => [
	createTableColumn<ArchiveProjectJobListResponseInterface>({
		columnId: 'action',
		renderHeaderCell: () => t('toolsPage.archive.actions'),
		renderCell: (item) => {
			const onClick = () => {
				setActiveJobId(item.id);
			};
			return (
				<TableCellLayout>
					<Button onClick={onClick} icon={<OpenRegular />}>{t('open')}</Button>
				</TableCellLayout>
			);
		},
	}),
	createTableColumn<ArchiveProjectJobListResponseInterface>({
		columnId: 'start',
		compare: (a, b) => (!a.start || !b.start) ? -1 : (a.start > b.start ? 1 : -1),
		renderHeaderCell: () => t('toolsPage.archive.startTime'),
		renderCell: (item) => (
			<TableCellLayout>
				{parseStartDate(language, item.start)}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveProjectJobListResponseInterface>({
		columnId: 'jobName',
		compare: (a, b) => a.jobName.localeCompare(b.jobName),
		renderHeaderCell: () => t('toolsPage.archive.name'),
		renderCell: (item) => (
			<TableCellLayout description={t('toolsPage.archive.projectCount', { count: item.amountOfProjects })}>
				{item.jobName}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveProjectJobListResponseInterface>({
		columnId: 'status',
		compare: (a, b) => a.status.localeCompare(b.status),
		renderHeaderCell: () => t('toolsPage.archive.state'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.status === 'Waiting' ? <SubtractCircle24Filled style={{ color: 'grey' }} /> :
					item.status === 'Running' ? <MoreCircle24Filled style={{ color: 'orange' }} /> :
						item.status === 'Success' ? <CheckmarkCircle24Filled style={{ color: 'green' }} /> :
							item.status === 'Error' ? <ErrorCircle24Filled style={{ color: 'red' }} /> : null
				}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveProjectJobListResponseInterface>({
		columnId: 'amountOfFiles',
		compare: (a, b) => b.amountOfFiles - a.amountOfFiles,
		renderHeaderCell: () => t('toolsPage.archive.fileCount'),
		renderCell: (item) => (
			<TableCellLayout description={getSizeInMegabytes(item.totalSize, language)}>
				{item.amountOfFiles.toString()}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveProjectJobListResponseInterface>({
		columnId: 'duration',
		compare: (a, b) => b.amountOfFiles - a.amountOfFiles,
		renderHeaderCell: () => t('toolsPage.archive.duration'),
		renderCell: (item) => (
			<TableCellLayout>
				{parseTimePassed(item.timePassed)}
			</TableCellLayout>
		),
	}),

];
const createArchiveProjectJobProjectListColumns = (language: string, t: TFunction): TableColumnDefinition<ArchiveJobDetailsProjectEntryInterface>[] => [
	createTableColumn<ArchiveJobDetailsProjectEntryInterface>({
		columnId: 'name',
		renderHeaderCell: () => t('toolsPage.archive.projectName'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.name}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobDetailsProjectEntryInterface>({
		columnId: 'companyName',
		renderHeaderCell: () => t('toolsPage.archive.company'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.companyName}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobDetailsProjectEntryInterface>({
		columnId: 'number',
		renderHeaderCell: () => t('toolsPage.archive.projectNumber'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.isTloProject ? <Link rel={'noreferrer'} as={'a'} target={'_blank'} href={`https://teamleaderorbit.unitedexperts.be/project/${item.number}`}>{item.number}</Link> : item.number}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobDetailsProjectEntryInterface>({
		columnId: 'status',
		renderHeaderCell: () => t('toolsPage.archive.state'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.status === 'Waiting' ? <SubtractCircle24Filled style={{ color: 'grey' }} /> :
					item.status === 'Running' ? <MoreCircle24Filled style={{ color: 'orange' }} /> :
						item.status === 'Success' ? <CheckmarkCircle24Filled style={{ color: 'green' }} /> :
							item.status === 'Error' ? <ErrorCircle24Filled style={{ color: 'red' }} /> : null
				}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobDetailsProjectEntryInterface>({
		columnId: 'timePassed',
		renderHeaderCell: () => t('toolsPage.archive.duration'),
		renderCell: (item) => (
			<TableCellLayout>
				{parseTimePassed(item.timePassed)}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobDetailsProjectEntryInterface>({
		columnId: 'processedFiles',
		renderHeaderCell: () => t('toolsPage.archive.filesProcessed'),
		renderCell: (item) => (
			<TableCellLayout>
				{item.totalProcessedFiles.toString()}/{item.amountOfFiles.toString()}
			</TableCellLayout>
		),
	}),
	createTableColumn<ArchiveJobDetailsProjectEntryInterface>({
		columnId: 'totalSize',
		renderHeaderCell: () => t('toolsPage.archive.totalSize'),
		renderCell: (item) => (
			<TableCellLayout>
				{getSizeInMegabytes(item.totalSize, language)}
			</TableCellLayout>
		),
	}),
];

const tabNames = ['projectList', 'folderList'];

const ArchivePage = () => {
	useValidateToolPermissions(ToolId.ToolsArchive);
	const classes = useClasses();
	const archiveProjectJobList = useGetArchiveProjectJobList(50, 0);
	const archiveFolderJobList = useGetArchiveFolderJobList(50, 0);
	const { i18n: { language }, t } = useTranslation();
	const [activeJobId, setActiveJobId] = useState<number>();
	const [archiveJobDetailsOpen, setArchiveJobDetailsOpen] = useState(false);
	const [createNewArchiveProjectJobOpen, setCreateNewArchiveProjectJobOpen] = useState(false);
	const [createNewArchiveFolderJobOpen, setCreateNewArchiveFolderJobOpen] = useState(false);
	const createArchiveProjectJob = useCreateArchiveProjectJob();
	const createArchiveFolderJob = useCreateArchiveFolderJob();
	const jobDetails = useGetArchiveJobDetails(activeJobId);
	const toasterId = useId('createdArchiveJob');
	const { dispatchToast } = useToastController(toasterId);
	const [selectedTab, setSelectedTab] = useState(tabNames[0]);

	const openArchiveJobDetailModal = useCallback((jobId: number) => {
		setActiveJobId(jobId);
		setArchiveJobDetailsOpen(true);
	}, []);

	const onArchiveJobDetailsModalOpenChange: DialogOpenChangeEventHandler = (event, data) => {
		event.preventDefault();
		setArchiveJobDetailsOpen(data.open);
	};

	const onCreateNewArchiveProjectJobModalOpenChange: DialogOpenChangeEventHandler = (_, data) => {
		setCreateNewArchiveProjectJobOpen(data.open);
	};

	const onCreateNewArchiveFolderJobModalOpenChange: DialogOpenChangeEventHandler = (_, data) => {
		setCreateNewArchiveFolderJobOpen(data.open);
	};

	const archiveProjectJobListColumns = useMemo(() => createArchiveProjectJobListColumns(openArchiveJobDetailModal, language, t), [t, language, openArchiveJobDetailModal]);
	const archiveFolderJobListColumns = useMemo(() => createArchiveFolderJobListColumns(language, t), [t, language]);
	const archiveProjectJobProjectListColumns = useMemo(() => createArchiveProjectJobProjectListColumns(language, t), [language, t]);

	const doCreateArchiveProjectJob = useCallback((entries: CreateArchiveProjectJobRequestEntryInterface[]) => {
		setCreateNewArchiveProjectJobOpen(false);
		createArchiveProjectJob.mutateAsync(entries).then(() => {
			archiveProjectJobList.refetchArchiveJobs();
			dispatchToast(
				<Toast>
					<ToastTitle>{t('toolsPage.archive.toast.title')}</ToastTitle>
					<ToastBody>{t('toolsPage.archive.toast.descriptionSuccess')}</ToastBody>
				</Toast>,
				{ intent: 'success' },
			);
		}).catch(() => {
			dispatchToast(
				<Toast>
					<ToastTitle>{t('toolsPage.archive.toast.title')}</ToastTitle>
					<ToastBody>{t('toolsPage.archive.toast.descriptionFail')}</ToastBody>
				</Toast>,
				{ intent: 'error' },
			);
		});
	}, [createArchiveProjectJob, dispatchToast, archiveProjectJobList, t]);

	const doCreateArchiveFolderJob = useCallback((request: CreateArchiveFolderJobRequestEntryInterface) => {
		setCreateNewArchiveFolderJobOpen(false);
		createArchiveFolderJob.mutateAsync(request).then(() => {
			archiveFolderJobList.refetchArchiveJobs();
			dispatchToast(
				<Toast>
					<ToastTitle>{t('toolsPage.archive.toast.title')}</ToastTitle>
					<ToastBody>{t('toolsPage.archive.toast.descriptionSuccess')}</ToastBody>
				</Toast>,
				{ intent: 'success' },
			);
		}).catch(() => {
			dispatchToast(
				<Toast>
					<ToastTitle>{t('toolsPage.archive.toast.title')}</ToastTitle>
					<ToastBody>{t('toolsPage.archive.toast.descriptionFail')}</ToastBody>
				</Toast>,
				{ intent: 'error' },
			);
		});
	}, [createArchiveFolderJob, archiveFolderJobList, dispatchToast, t]);

	const refreshDetails = useCallback(() => {
		jobDetails.refetch();
	}, [jobDetails]);

	const ProjectsTab = useMemo(() => {
		return (
			<div className={classes.table}>
				{archiveProjectJobList.isLoading ? <Spinner /> : (
					<DataGrid
						items={archiveProjectJobList.archiveJobs}
						columns={archiveProjectJobListColumns}
						sortable
						resizableColumns={false}
						getRowId={(item) => item.id}
					>
						<DataGridHeader>
							<DataGridRow>
								{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
							</DataGridRow>
						</DataGridHeader>
						<DataGridBody<ArchiveProjectJobListResponseInterface>>
							{({ item, rowId }) => (
								<DataGridRow<ArchiveProjectJobListResponseInterface> key={rowId}>
									{({ renderCell }) => (
										<DataGridCell>{renderCell(item)}</DataGridCell>
									)}
								</DataGridRow>
							)}
						</DataGridBody>
					</DataGrid>
				)}
			</div>
		);
	}, [archiveProjectJobList, archiveProjectJobListColumns, classes.table]);

	const FoldersTab = useMemo(() => {
		return (
			<div className={classes.table}>
				{archiveFolderJobList.isLoading ? <Spinner /> : (
					<DataGrid
						items={archiveFolderJobList.archiveJobs}
						columns={archiveFolderJobListColumns}
						sortable
						resizableColumns={false}
						getRowId={(item) => item.id}
					>
						<DataGridHeader>
							<DataGridRow>
								{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
							</DataGridRow>
						</DataGridHeader>
						<DataGridBody<ArchiveJobListResponseInterface>>
							{({ item, rowId }) => (
								<DataGridRow<ArchiveJobListResponseInterface> key={rowId} title={item.status}>
									{({ renderCell }) => (
										<DataGridCell>{renderCell(item)}</DataGridCell>
									)}
								</DataGridRow>
							)}
						</DataGridBody>
					</DataGrid>
				)}
			</div>
		);
	}, [archiveFolderJobList, archiveFolderJobListColumns, classes.table]);

	const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
		setSelectedTab(data.value as string);
	};

	const refreshList = useCallback(() => {
		if (selectedTab === tabNames[0]) {
			archiveProjectJobList.refetchArchiveJobs();
		} else {
			archiveFolderJobList.refetchArchiveJobs();
		}
	}, [selectedTab, archiveProjectJobList, archiveFolderJobList]);

	return (
		<div className={classes.container}>
			<Toaster toasterId={toasterId} />
			<Title2 className={classes.title}>{t('toolsPage.archive.title')}</Title2>
			<div className={classes.row}>
				<Dialog modalType={'alert'} onOpenChange={onCreateNewArchiveProjectJobModalOpenChange} open={createNewArchiveProjectJobOpen}>
					<DialogTrigger>
						<Button className={classes.createArchiveJobButton} appearance={'primary'}>{t('toolsPage.archive.newProjectJob')}</Button>
					</DialogTrigger>
					<CreateArchiveProjectJobModal createArchiveJob={doCreateArchiveProjectJob} />
				</Dialog>
				<Dialog modalType={'alert'} onOpenChange={onCreateNewArchiveFolderJobModalOpenChange} open={createNewArchiveFolderJobOpen}>
					<DialogTrigger>
						<Button className={classes.createArchiveJobButton} appearance={'primary'}>{t('toolsPage.archive.newFolderJob')}</Button>
					</DialogTrigger>
					<CreateArchiveFolderJobModal createArchiveJob={doCreateArchiveFolderJob} />
				</Dialog>
				<Button className={classes.refreshButton} icon={<ArrowClockwiseFilled />} onClick={refreshList}>{t('refresh')}</Button>
			</div>
			<TabList onTabSelect={onTabSelect} selectedValue={selectedTab} className={classes.tabList}>
				<Tab value={tabNames[0]}>
					{t('toolsPage.archive.projectsJob')}
				</Tab>
				<Tab value={'foldersTab'}>
					{t('toolsPage.archive.foldersJob')}
				</Tab>
			</TabList>
			{selectedTab === tabNames[0] ? ProjectsTab : FoldersTab}
			<Dialog modalType={'modal'} onOpenChange={onArchiveJobDetailsModalOpenChange} open={archiveJobDetailsOpen}>
				<DialogSurface className={classes.jobDetailsModalContainer}>
					<DialogBody>
						{jobDetails.isLoading ? <Spinner /> : (
							<>
								<DialogTitle action={<Button
									appearance={'subtle'}
									onClick={refreshDetails}
									icon={<ArrowClockwiseFilled />}
								/>}>{jobDetails.jobDetails?.jobName}</DialogTitle>
								<DialogContent>
									<div className={classes.row}>
										<Text>{t('toolsPage.archive.startedAt')}:</Text>
										<Text><b>{parseStartDate(language, jobDetails.jobDetails?.start)}</b></Text>
									</div>
									<div className={classes.row}>
										<Text>{t('toolsPage.archive.state')}:</Text>
										<Text><b>{t(`toolsPage.archive.status.${jobDetails.jobDetails?.status.toLowerCase()}`)}</b></Text>
									</div>
									<div className={classes.row}>
										<Text>{t('toolsPage.archive.totalTimePassed')}:</Text>
										<Text><b>{parseTimePassed(jobDetails.jobDetails?.timePassed)}</b></Text>
									</div>
									<div className={classes.lastRow}>
										<Text>{t('toolsPage.archive.totalSize')}:</Text>
										<Text><b>{getSizeInMegabytes(jobDetails.jobDetails?.totalSize ?? 0, language)}</b></Text>
									</div>
									<DataGrid
										items={jobDetails.jobDetails?.projects ?? []}
										columns={archiveProjectJobProjectListColumns}
										sortable
										resizableColumns={false}
										getRowId={(item) => item.id}
									>
										<DataGridHeader>
											<DataGridRow>
												{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
											</DataGridRow>
										</DataGridHeader>
										<DataGridBody<ArchiveJobDetailsProjectEntryInterface> className={classes.table}>
											{({ item, rowId }) => (
												<DataGridRow<ArchiveJobDetailsProjectEntryInterface> key={rowId}>
													{({ renderCell }) => (
														<DataGridCell>{renderCell(item)}</DataGridCell>
													)}
												</DataGridRow>
											)}
										</DataGridBody>
									</DataGrid>
								</DialogContent>
							</>
						)}
					</DialogBody>
				</DialogSurface>
			</Dialog>
		</div>
	);
};

export default ArchivePage;
