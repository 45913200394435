import { MutateFunction, useMutation } from '@tanstack/react-query';
import { api } from '../../index';
import CreateArchiveFolderJobRequestEntryInterface from '../../interfaces/request/CreateArchiveFolderJobRequestInterface';

const createArchiveJob: MutateFunction<void, Error, CreateArchiveFolderJobRequestEntryInterface> = async (body) => {
	const response = await api.post(`/api/archive/folders`, JSON.stringify(body), {
		headers: {
			'Content-Type': 'application/json',
		},
	});
	if (response.status !== 201) {
		throw new Error(response.data);
	}
};

const useCreateArchiveFolderJob = () => {
	const { isSuccess, mutateAsync, isError, error } = useMutation({
		mutationFn: createArchiveJob,
	});

	return {
		mutateAsync,
		isError,
		isSuccess,
		error,
	};
};

export default useCreateArchiveFolderJob;
