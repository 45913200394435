import {
	Body1,
	Body1Strong,
	Button,
	Caption1,
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Divider,
	makeStyles,
	Spinner,
	Subtitle1,
	Toast,
	ToastBody,
	Toaster,
	ToastTitle,
	tokens,
	useId,
	useToastController,
} from '@fluentui/react-components';
import React, { useMemo, useState } from 'react';
import UserInfo from '../components/UserInfo';
import ProjectInfo from '../components/ProjectInfo';
import EntityInfo from '../components/EntityInfo';
import ExternalContactInfo from '../components/ExternalContactInfo';
import AutofillSearchBar from '../components/AutofillSearchBar';
import NameAndId from '../interfaces/NameAndId';
import useSearchUsers from '../hooks/queries/useSearchUsers';
import useGetEntitiesList from '../hooks/queries/useGetEntitiesList';
import useGetProjectList from '../hooks/queries/useGetProjectList';
import UserInfoResponseInterface from '../interfaces/response/UserInfoResponseInterface';
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';
import useGetSuggestedContacts from '../hooks/queries/useGetSuggestedContacts';
import useGetContactsList from '../hooks/queries/useGetContactsList';
import useGetClientInfo from '../hooks/queries/useGetClientInfo';
import { Trans, useTranslation } from 'react-i18next';
import useInnovaphoneTransferCallMutation from '../hooks/mutations/useInnovaphoneTransferCallMutation';
import InnovaphoneCallsResponseInterface from '../interfaces/response/InnovaphoneCallsResponseInterface';

type Props = {
	clientId?: string;
	callInfo: InnovaphoneCallsResponseInterface;
	transferCallDialogOpen: boolean;
	setTransferCallDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setSummaryDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useClasses = makeStyles({
	root: {
		flexDirection: 'column',
		flex: 1,
		display: 'flex',
		paddingTop: tokens.spacingVerticalM,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	subtitle: {
		color: tokens.colorBrandForeground1,
		marginTop: tokens.spacingVerticalM,
	},
	divider: {
		marginTop: tokens.spacingVerticalSNudge,
		marginBottom: tokens.spacingVerticalM,
	},
	titleDoorverbinden: {
		marginTop: tokens.spacingVerticalL,
	},
	marginTop: {
		marginTop: tokens.spacingVerticalM,
	},
	row: {
		flexDirection: 'row',
		display: 'flex',
	},
	rowScroll: {
		flexDirection: 'row',
		display: 'flex',
		overflowX: 'scroll',
		paddingBottom: tokens.spacingVerticalL,
	},
	verticalDivider: {
		marginLeft: tokens.spacingHorizontalM,
		marginRight: tokens.spacingHorizontalM,
	},
	grid: {
		display: 'grid',
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

const TelephonyTloSummary: React.FC<Props> = ({ clientId, callInfo, transferCallDialogOpen, setTransferCallDialogOpen, setSummaryDialogOpen }) => {
	const toastId = useId('innovaphoneTransferCall');
	const { t } = useTranslation();
	const classes = useClasses();
	const [contactSearchTerm, setContactSearchTerm] = useState<string>('');
	const [selectedContact, setSelectedContact] = useState<NameAndId>();
	const searchedUsers = useSearchUsers(contactSearchTerm);
	const contacts = useGetContactsList('*', clientId);
	const entities = useGetEntitiesList(clientId);
	const projects = useGetProjectList('*', clientId);
	const clientInfo = useGetClientInfo(clientId);
	const [selectedUserToTransferTo, setSelectedUserToTransferTo] = useState<UserInfoResponseInterface>();
	const projectIds = useMemo(() => projects.projectResults.map(c => c.id), [projects.projectResults]);
	const suggestedContacts = useGetSuggestedContacts(projectIds);
	const innovaphoneTransferCall = useInnovaphoneTransferCallMutation();
	const { dispatchToast } = useToastController(toastId);

	const transferCallToUser = (userInfo: UserInfoResponseInterface) => {
		setTransferCallDialogOpen(true);
		setSelectedUserToTransferTo(userInfo);
	};

	const onTransferCallDialogOpenChange: DialogOpenChangeEventHandler = (_, data) => {
		setTransferCallDialogOpen(data.open);
	};

	if (!clientId) {
		return null;
	}

	if (contacts.isLoading || entities.isLoading || projects.isLoading || suggestedContacts.isLoading || clientInfo.isLoading) {
		return <Spinner className={classes.root} />;
	}

	const transferInnovaphoneCall = () => {
		if (!selectedUserToTransferTo) return;
		innovaphoneTransferCall.mutateAsync({
			Callee: callInfo.callee,
			Caller: callInfo.caller,
			PersonToTransferTo: selectedUserToTransferTo.name,
		}).then(success => {
			if (success) {
				dispatchToast(
					<Toast>
						<ToastTitle>{t('telephony.callTransferredTitleSuccess')}</ToastTitle>
						<ToastBody>
							<Trans i18nKey={'telephony.callTransferredDescriptionSuccess'} values={{ name: selectedUserToTransferTo.name }} />
						</ToastBody>
					</Toast>,
					{ intent: 'success' },
				);
				setTransferCallDialogOpen(false);
				setSummaryDialogOpen(false);
			} else {
				dispatchToast(
					<Toast>
						<ToastTitle>{t('telephony.callTransferredTitleFailure')}</ToastTitle>
						<ToastBody>
							<Trans i18nKey={'telephony.callTransferredDescriptionFailure'} values={{ reason: 'onbekend' }} />
						</ToastBody>
					</Toast>,
					{ intent: 'error' },
				);
			}
		}).catch((error: Error) => {
			dispatchToast(
				<Toast>
					<ToastTitle>{t('telephony.callTransferredTitleFailure')}</ToastTitle>
					<ToastBody>
						<Trans i18nKey={'telephony.callTransferredDescriptionFailure'} values={{ reason: error.message }} />
					</ToastBody>
				</Toast>,
				{ intent: 'error' },
			);
		});
	};

	return (
		<div className={classes.root}>
			<Toaster toasterId={toastId} />
			<Dialog modalType={'alert'} open={transferCallDialogOpen} onOpenChange={onTransferCallDialogOpenChange}>
				<DialogSurface>
					<DialogBody>
						<DialogTitle>{t(innovaphoneTransferCall.isPending ? 'telephony.transferringCall' : 'telephony.transferCall')}</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							{innovaphoneTransferCall.isPending ? <Spinner className={classes.spinner} /> : (
								<>
									<Body1><Trans i18nKey={'telephony.transferCallConfirmation'} values={{ clientName: clientInfo.data?.name, userName: selectedUserToTransferTo?.name }} /></Body1>
									<Caption1><Trans i18nKey={'telephony.phoneNumber'} values={{ number: !!selectedUserToTransferTo?.phone ? selectedUserToTransferTo.phone : '?' }} /></Caption1>
									<Caption1><Trans i18nKey={'telephony.internalPhoneNumber'}
														  values={{ number: !!selectedUserToTransferTo?.internalPhone ? selectedUserToTransferTo.internalPhone : '?' }} /></Caption1>
								</>
							)}
						</DialogContent>
						{!innovaphoneTransferCall.isPending && (
							<DialogActions>
								<DialogTrigger>
									<Button appearance="secondary">{t('telephony.cancel')}</Button>
								</DialogTrigger>
								<Button appearance="primary" onClick={transferInnovaphoneCall}>{t('telephony.transfer')}</Button>
							</DialogActions>
						)}
					</DialogBody>
				</DialogSurface>
			</Dialog>
			<div className={classes.container}>
				<Subtitle1>{clientInfo.data?.name}</Subtitle1>
				<Body1Strong className={classes.subtitle}>{t('telephony.contactInfo')}</Body1Strong>
				<Divider className={classes.divider} />
				<div className={classes.grid}>
					<div className={classes.rowScroll}>
						{contacts.contactsResults.length > 0 ? contacts.contactsResults.map((c, index) => {
							if (index === contacts.contactsResults.length - 1) {
								return <ExternalContactInfo contactId={c.id} key={index} />;
							}
							return (
								<div className={classes.row} key={index}>
									<ExternalContactInfo contactId={c.id} />
									<Divider className={classes.verticalDivider} vertical />
								</div>
							);
						}) : <Body1>{t('telephony.noContactsFound')}</Body1>}
					</div>
				</div>
				<Body1Strong className={classes.subtitle}>{t('telephony.entity')}</Body1Strong>
				<Divider className={classes.divider} />
				<div className={classes.grid}>
					<div className={classes.rowScroll}>
						{entities.entityResults.length > 0 ? entities.entityResults.map((c, index) => {
							if (index === entities.entityResults.length - 1) {
								return <EntityInfo entityId={c.id} key={index} />;
							}
							return (
								<div className={classes.row} key={index}>
									<EntityInfo entityId={c.id} />
									<Divider className={classes.verticalDivider} vertical />
								</div>
							);
						}) : <Body1>{t('telephony.noEntitiesFound')}</Body1>}
					</div>
				</div>
				<Body1Strong className={classes.subtitle}>{t('telephony.projects')}</Body1Strong>
				<Divider className={classes.divider} />
				<div className={classes.grid}>
					<div className={classes.rowScroll}>
						{projects.projectResults.length > 0 ? projects.projectResults.map((p, index) => {
							if (index === projects.projectResults.length - 1) {
								return <ProjectInfo projectId={p.id} key={index} />;
							}
							return (
								<div className={classes.row} key={index}>
									<ProjectInfo projectId={p.id} />
									<Divider className={classes.verticalDivider} vertical />
								</div>
							);
						}) : <Body1>{t('telephony.noProjectsFound')}</Body1>}
					</div>
				</div>
				<Subtitle1 className={classes.titleDoorverbinden}>{t('telephony.transferCall')}</Subtitle1>
				<Body1Strong className={classes.subtitle}>{t('telephony.suggestedContacts')}</Body1Strong>
				<Divider className={classes.divider} />
				<div className={classes.grid}>
					<div className={classes.rowScroll}>
						{suggestedContacts.data.length > 0 ? suggestedContacts.data.map((c, index) => {
							if (index === suggestedContacts.data.length - 1) {
								return <UserInfo userMail={c.name} key={index} onClick={transferCallToUser} />;
							}
							return (
								<div className={classes.row} key={index}>
									<UserInfo userMail={c.name} onClick={transferCallToUser} />
									<Divider className={classes.verticalDivider} vertical />
								</div>
							);
						}) : <Body1>{t('telephony.noContactsFound')}</Body1>}
					</div>
				</div>

				<Body1Strong className={classes.subtitle}>{t('telephony.searchContacts')}</Body1Strong>
				<Divider className={classes.divider} />
				<AutofillSearchBar searchedSearchTerm={contactSearchTerm}
										 onSearch={setContactSearchTerm}
										 placeholder={'Zoeken'}
										 loading={searchedUsers.isFetching}
										 onSelectEntry={setSelectedContact}
										 selectedEntry={selectedContact}
										 options={searchedUsers.data} />
				{selectedContact?.id && <div className={classes.marginTop}><UserInfo onClick={transferCallToUser} userMail={selectedContact?.id} /></div>}
			</div>
		</div>
	);
};

export default TelephonyTloSummary;
