import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ContactInfoResponseInterface from '../../interfaces/response/ContactInfoResponseInterface';

const getContactInfo: QueryFunction<ContactInfoResponseInterface> = async ({ queryKey }: QueryFunctionContext) => {
	const url = `/api/tlo/contacts/${queryKey[0]}`;
	const response = await api.get<ContactInfoResponseInterface>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetContactInfo = (contactId?: string) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: [contactId, 'getContactInfo'],
		queryFn: getContactInfo,
		enabled: !!contactId,
	});

	return {
		data,
		isLoading,
		isError,
	};
};

export default useGetContactInfo;
