import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';
import TloSearchResponseInterface from '../../interfaces/response/TloSearchResponseInterface';

const searchTlo: QueryFunction<{ response: TloSearchResponseInterface, searchTerm: string }> = async ({ queryKey }: QueryFunctionContext) => {
	const url = `/api/tlo/search?searchString=${queryKey[0]}`;
	const response = await api.get<TloSearchResponseInterface>(url);
	if (response.status === 200) {
		return { response: response.data, searchTerm: queryKey[0] as string };
	}
	throw new Error(response.data as any);
};

const emptyResult: NameAndId[] = [];

const useSearchTlo = (searchTerm?: string) => {
	const { data, isLoading, isError, refetch } = useQuery({
		queryKey: [searchTerm, 'searchTlo'],
		queryFn: searchTlo,
		enabled: false,
	});

	return {
		projects: data?.response.projects ?? emptyResult,
		clients: data?.response.clients ?? emptyResult,
		geoLocations: data?.response.geoLocations ?? emptyResult,
		contacts: data?.response.contacts ?? emptyResult,
		searchTerm: data?.searchTerm,
		refetch,
		isLoading,
		isError,
	};
};

export default useSearchTlo;
