import { createContext } from 'react';
import NameAndId from '../interfaces/NameAndId';

type DocumentGenerationContextType = {
	selectedContact?: NameAndId;
	selectedProject?: NameAndId;
	selectedDocument?: NameAndId;
	selectedCompany?: NameAndId;
	selectedClient?: NameAndId;
	isCopyingExistingSettings: boolean;
	setSelectedProject: (project?: NameAndId) => void;
	setSelectedClient: (client?: NameAndId) => void;
	setSelectedContact: (contact?: NameAndId) => void;
	setSelectedCompany: (company?: NameAndId) => void;
	setSelectedDocument: (document?: NameAndId) => void;
	updateRequestDataSection: (section: string, data: any) => void;
}

const defaultValue: DocumentGenerationContextType = {
	isCopyingExistingSettings: false,
	setSelectedClient: _ => {
	},
	setSelectedContact: _ => {
	},
	setSelectedCompany: _ => {
	},
	setSelectedDocument: _ => {
	},
	setSelectedProject: _ => {
	},
	updateRequestDataSection: (_, __) => {
	},
};

const DocumentGenerationContext = createContext<DocumentGenerationContextType>(defaultValue);

export default DocumentGenerationContext;
export type { DocumentGenerationContextType };
