import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import useGetClientList from '../../hooks/queries/useGetClientList';
import useGetProjectList from '../../hooks/queries/useGetProjectList';
import AutofillSearchBar from '../AutofillSearchBar';
import useGetCompanies from '../../hooks/queries/useGetCompanies';
import NameAndId from '../../interfaces/NameAndId';
import useGetContactsList from '../../hooks/queries/useGetContactsList';
import { Dropdown, Label, makeStyles, Option, OptionOnSelectData, PositioningShorthand, SelectionEvents, Spinner, Title2, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import Region from '../../interfaces/Region';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import DocumentGenerationContext from '../../context/DocumentGenerationContext';

type Props = {
	setSelectedRegion: (region: Region) => void;
	positioning: PositioningShorthand;
};

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
		alignItems: 'center',
		gap: '1em',
		paddingLeft: tokens.spacingHorizontalXXXL,
		paddingRight: tokens.spacingHorizontalXXXL,
		display: 'flex',
	},
	column: {
		flexDirection: 'column',
		gap: '2px',
		width: '100%',
		display: 'flex',
	},
	loadingContainer: {
		marginTop: tokens.spacingVerticalL,
		marginBottom: tokens.spacingVerticalL,
		justifyContent: 'flex-start',
		display: 'flex',
	},
	alignStart: {
		alignSelf: 'flex-start',
	},
});

const DocumentGenerationSelectProjectStep: React.FC<Props> = ({ setSelectedRegion, positioning }) => {
	const {
		selectedClient,
		selectedCompany,
		isCopyingExistingSettings,
		selectedProject,
		selectedContact,
		setSelectedCompany,
		setSelectedProject,
		setSelectedClient,
		setSelectedContact,
	} = useContext(DocumentGenerationContext);
	const isAdmin = useSelector<RootState, boolean>((state) => state.app.activeUserInfo?.isAdmin ?? false);
	const { t } = useTranslation();
	const classes = useClasses();
	const { results: companiesResult } = useGetCompanies();
	const [clientSearchTerm, setClientSearchTerm] = useState<string>('');
	const [projectSearchTerm, setProjectSearchTerm] = useState<string>('');
	const [contactSearchTerm, setContactSearchTerm] = useState<string>('');
	const { clientResults, isFetching: isFetchingClients } = useGetClientList(clientSearchTerm);
	const { projectResults, isFetching: isFetchingProjects } = useGetProjectList(projectSearchTerm, selectedClient?.id);
	const { contactsResults, isFetching: isFetchingContacts } = useGetContactsList(contactSearchTerm, selectedClient?.id);

	const regions = useMemo<{ id: Region, name: string }[]>(() => {
		return [
			{
				id: 'Flanders',
				name: t('navigation.flanders'),
			},
			{
				id: 'Wallonia',
				name: t('navigation.wallonia'),
			},
		];
	}, [t]);

	useEffect(() => {
		if (companiesResult?.length === 0 || !!selectedCompany) return;
		setSelectedCompany(companiesResult[0]);
	}, [setSelectedCompany, selectedCompany, companiesResult]);

	const onChangeProjectSearchTerm = (newTerm: string) => {
		if (isCopyingExistingSettings) return;
		if (newTerm === '') {
			setProjectSearchTerm('*');
			setSelectedProject(undefined);
		} else {
			setProjectSearchTerm(newTerm);
		}
	};

	const onChangeContactSearchTerm = (newTerm: string) => {
		if (isCopyingExistingSettings) return;
		if (newTerm === '') {
			setContactSearchTerm('*');
			setSelectedContact(undefined);
		} else {
			setContactSearchTerm(newTerm);
		}
	};

	const onChangeClientSearchTerm = (newTerm: string) => {
		if (isCopyingExistingSettings) return;
		if (newTerm === '') {
			setClientSearchTerm('*');
			setSelectedClient(undefined);
		} else {
			setClientSearchTerm(newTerm);
		}
	};

	const onCompanySelect = (_: SelectionEvents, data: OptionOnSelectData) => {
		setSelectedCompany(companiesResult.find(company => company.id === data.optionValue)!);
	};

	const onRegionSelect = (_: SelectionEvents, data: OptionOnSelectData) => {
		setSelectedRegion(data.optionValue! as Region);
	};

	const onProjectSelect = (nameAndId?: NameAndId) => {
		if (!!nameAndId) {
			const client = projectResults.find(p => p.id === nameAndId.id)!.client;
			setSelectedClient(client);
			onChangeClientSearchTerm(client.name);
		}
		setSelectedProject(nameAndId);
	};

	const onClientSelect = (nameAndId: NameAndId) => {
		const isSelectingNewClient = nameAndId && selectedProject && projectResults.find(p => p.id === nameAndId.id)?.client?.id !== nameAndId.id;
		if (isSelectingNewClient || !nameAndId) {
			setSelectedProject(undefined);
			onChangeProjectSearchTerm('');
		}
		setSelectedClient(nameAndId);
	};

	return (
		<div className={classes.container}>
			<Title2 className={classes.alignStart}>Nieuw document maken</Title2>
			{companiesResult?.length === 0 ? (
				<div className={classes.loadingContainer}>
					<Spinner />
				</div>
			) : (
				<>
					<AutofillSearchBar positioning={positioning} searchedSearchTerm={clientSearchTerm} required={!selectedProject} label={t('documentsPage.clients')} options={clientResults}
											 onSelectEntry={onClientSelect}
											 loading={isFetchingClients}
											 onSearch={onChangeClientSearchTerm}
											 selectedEntry={selectedClient} placeholder={t('typeToSearch')} />
					{!!selectedClient &&
						<AutofillSearchBar positioning={positioning} searchedSearchTerm={contactSearchTerm} label={t('documentsPage.contacts')} options={contactsResults} onSelectEntry={setSelectedContact}
												 loading={isFetchingContacts}
												 selectedEntry={selectedContact}
												 onSearch={onChangeContactSearchTerm}
												 placeholder={t('typeToSearch')} />}
					<AutofillSearchBar positioning={positioning} searchedSearchTerm={projectSearchTerm} required={!selectedClient} label={t('documentsPage.projects')} options={projectResults}
											 onSelectEntry={onProjectSelect}
											 loading={isFetchingProjects}
											 selectedEntry={selectedProject}
											 onSearch={onChangeProjectSearchTerm} placeholder={t('typeToSearch')} />
					<div className={classes.column}>
						<Label required>{t('documentsPage.company')}</Label>
						<Dropdown onOptionSelect={onCompanySelect} defaultValue={companiesResult[0].name}>
							{companiesResult.map((company) => (
								<Option key={company.id} value={company.id}>
									{company.name}
								</Option>
							))}
						</Dropdown>
					</div>
					{isAdmin && (
						<div className={classes.column}>
							<Label required>{t('documentsPage.region')}</Label>
							<Dropdown onOptionSelect={onRegionSelect} defaultValue={t('navigation.flanders')}>
								{regions.map((region) => (
									<Option key={region.id} value={region.id}>
										{region.name}
									</Option>
								))}
							</Dropdown>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default memo(DocumentGenerationSelectProjectStep);
