import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ArchivableProjectFolderInfoResponseEntryInterface from '../../interfaces/response/ArchivableProjectFolderInfoResponseEntryInterface';
import ArchivableProjectResponseEntryInterface from '../../interfaces/response/ArchivableProjectResponseEntryInterface';

const emptyResult: ArchivableProjectFolderInfoResponseEntryInterface[] = [];

const getArchivableProjectFolderInfo: QueryFunction<ArchivableProjectFolderInfoResponseEntryInterface[]> = async ({ queryKey }: QueryFunctionContext) => {
	const projects = (queryKey[0] as ArchivableProjectResponseEntryInterface[]);
	const response = await api.post<ArchivableProjectFolderInfoResponseEntryInterface[]>('/api/archive/projects/archiveable/folder-info', JSON.stringify(projects), {
		headers: {
			'Content-Type': 'application/json',
		}
	});
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetArchivableProjectFolderInfo = (projects: ArchivableProjectResponseEntryInterface[]) => {
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: [projects, 'getArchivableProjectFolderInfo'],
		queryFn: getArchivableProjectFolderInfo,
		queryKeyHashFn: (key) => `${(key[0] as ArchivableProjectResponseEntryInterface[]).map(c => c.id).join(',')}getArchivableProjectFolderInfo`,
		enabled: false,
	});

	return {
		refetch,
		folderInfoList: data ?? emptyResult,
		isFetching,
		error,
	};
};

export default useGetArchivableProjectFolderInfo;
