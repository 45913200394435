import { MutateFunction, useMutation } from '@tanstack/react-query';
import { api } from '../../index';
import InnovaphoneTransferCallRequestInterface from '../../interfaces/request/InnovaphoneTransferCallRequestInterface';

const innovaphoneTransferCall: MutateFunction<boolean, Error, InnovaphoneTransferCallRequestInterface> = async (params) => {
	const url = `/api/innovaphone/transfer`;
	const response = await api.post<boolean | string>(url, JSON.stringify(params), {
		headers: {
			'Content-Type': 'application/json',
		},
	});
	if (response.status !== 200) {
		throw new Error(JSON.stringify(response.data as string));
	}
	return response.data as boolean;
};

const useInnovaphoneTransferCallMutation = () => {
	const { isSuccess, mutateAsync, isError, isPending, data: response } = useMutation({
		mutationFn: innovaphoneTransferCall,
	});

	return {
		mutateAsync,
		isError,
		isSuccess,
		response,
		isPending,
	};
};

export default useInnovaphoneTransferCallMutation;
