import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import { OptionTemplateConfiguration } from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import GenerateDocumentDataInterface from '../../interfaces/request/GenerateDocumentDataInterface';
import { useEffect, useState } from 'react';

const emptyResult: OptionTemplateConfiguration[] = [];

const getDocumentApiOptionsList: QueryFunction<OptionTemplateConfiguration[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}

	const response = await api.get<OptionTemplateConfiguration[]>(queryKey[0] as string);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetDocumentApiOptionsList = (requestData: GenerateDocumentDataInterface, url?: string, index?: number) => {
	const [actualUrl, setActualUrl] = useState<string>();

	useEffect(() => {
		if (!url) {
			setActualUrl(undefined);
			return;
		}
		const regExp = new RegExp('{{[\\w.#-]+}}');
		let wantedUrl = url;
		regExp.exec(wantedUrl)?.forEach(result => {
			const sanitizedResult = index !== undefined ? result.replace('#index', index.toString()) : result;
			const value = sanitizedResult.replace('{{', '').replace('}}', '');
			const sections = value.split('.');
			let currentObject: any = requestData;
			sections.forEach((section) => {
				if (!currentObject) return;
				currentObject = currentObject[section];
			});
			wantedUrl = wantedUrl.replace(result, currentObject ?? '');
		});
		setActualUrl(wantedUrl);
	}, [url, requestData, index]);

	const { data, isFetching, error, refetch } = useQuery({
		queryKey: [actualUrl, 'getDocumentApiOptionsList'],
		queryFn: getDocumentApiOptionsList,
		enabled: !!actualUrl,
		refetchOnWindowFocus: false,
	});

	return {
		results: data || emptyResult,
		isFetching,
		error,
		refetch,
	};
};

export default useGetDocumentApiOptionsList;
