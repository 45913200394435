import ToolId from '../interfaces/ToolId';
import { useEffect } from 'react';
import { useAppSelector } from '../redux/useAppSelector';
import { useNavigate } from 'react-router-dom';

const useValidateToolPermissions = (toolId: ToolId) => {
	const appState = useAppSelector((state) => state.app);
	const navigate = useNavigate();

	useEffect(() => {
		if (!appState.availableTools.includes(toolId)) {
			navigate('/unauthorized', {
				replace: true,
				flushSync: true,
			});
		}
	}, [appState.availableTools, toolId, navigate]);
};

export default useValidateToolPermissions;
