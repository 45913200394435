import { QueryFunction, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import InnovaphoneCallsResponseInterface from '../../interfaces/response/InnovaphoneCallsResponseInterface';

const getInnovaphoneCalls: QueryFunction<InnovaphoneCallsResponseInterface[]> = async () => {
	const response = await api.get<InnovaphoneCallsResponseInterface[]>(`/api/innovaphone/calls`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetInnovaphoneCalls = () => {
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: ['getInnovaphoneCalls'],
		queryFn: getInnovaphoneCalls,
	});

	return {
		data,
		isFetching,
		error,
		refetch,
	};
};

export default useGetInnovaphoneCalls;
