import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import FilesToBeDeletedResponseInterface from '../../interfaces/response/FilesToBeDeletedResponseInterface';

const getFilesToBeDeleted: QueryFunction<FilesToBeDeletedResponseInterface> = async ({ queryKey }: QueryFunctionContext) => {
	const response = await api.get<FilesToBeDeletedResponseInterface>(`/api/tools/files-cleanup?page=${queryKey[0]}&count=${queryKey[1]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetFilesToBeDeleted = (page: number, count: number) => {
	const { data, error, isFetching, refetch } = useQuery({
		queryKey: [page, count, 'getEntitiesList'],
		queryFn: getFilesToBeDeleted,
	});

	return {
		filesToBeDeleted: data,
		error,
		isFetching,
		refetch,
	};
};

export default useGetFilesToBeDeleted;
