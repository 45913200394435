import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const emptyResult: NameAndId[] = [];

const getEntitiesList: QueryFunction<NameAndId[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	const response = await api.get<NameAndId[]>(`/api/tlo/entities?clientId=${queryKey[0]}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetEntitiesList = (clientId?: string) => {
	const { data, isLoading, isFetching, error, isFetched } = useQuery({
		queryKey: [clientId, 'getEntitiesList'],
		queryFn: getEntitiesList,
		enabled: !!clientId,
	});

	return {
		entityResults: !!clientId ? (data || emptyResult) : emptyResult,
		isLoading,
		isFetching,
		error,
		isFetched
	};
};

export default useGetEntitiesList;
