import React, { memo, useContext, useEffect } from 'react';
import useGetDocumentInfoList from '../../hooks/queries/useGetDocumentInfoList';
import { DocumentType } from '../../interfaces/response/DocumentInfo';
import useGetDocumentSectionInfoList from '../../hooks/queries/useGetDocumentSectionInfoList';
import { Body1, Dropdown, Label, makeStyles, Option, OptionOnSelectData, SelectionEvents, Spinner, Text, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import Region from '../../interfaces/Region';
import useGetProjectInfo from '../../hooks/queries/useGetProjectInfo';
import DocumentGenerationContext from '../../context/DocumentGenerationContext';
import DocumentGenerationOptionSection from './DocumentGenerationOptionSection';
import OptionSectionApiTemplateConfigurationResponse from '../../interfaces/response/OptionSectionApiTemplateConfigurationResponse';
import DocumentGenerationOptionSectionApi from './DocumentGenerationOptionSectionApi';
import OptionSectionTemplateConfigurationResponse from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';

type Props = {
	types: DocumentType[];
	region: Region;
};

const useClasses = makeStyles({
	container: {
		gap: '1em',
		overflow: 'auto',
		flexDirection: 'column',
		paddingLeft: tokens.spacingHorizontalXXXL,
		paddingRight: tokens.spacingHorizontalXXXL,
		flex: 1,
		minHeight: 0,
		marginBottom: tokens.spacingVerticalL,
		display: 'flex',
	},
	textContainer: {
		flexDirection: 'column',
		display: 'flex',
	},
	h1: {
		fontWeight: tokens.fontWeightSemibold,
		fontSize: tokens.fontSizeBase600,
		marginBottom: tokens.spacingVerticalL,
	},
	h2: {
		fontSize: tokens.fontSizeBase400,
	},
	companyName: {
		fontWeight: tokens.fontWeightBold,
		fontSize: tokens.fontSizeHero800,
		marginBottom: tokens.spacingVerticalL,
		paddingTop: '1px',
	},
	column: {
		flexDirection: 'column',
		gap: '2px',
		width: '100%',
		display: 'flex',
	},
	flex: {
		display: 'flex',
	},
	redText: {
		color: tokens.colorPaletteRedForeground1,
		fontWeight: tokens.fontWeightBold,
	},
});

const DocumentGenerationSelectDocumentOptions: React.FC<Props> = ({ types, region }) => {
	const {
		selectedDocument,
		selectedCompany,
		isCopyingExistingSettings,
		selectedProject,
		selectedClient,
		selectedContact,
		setSelectedDocument,
	} = useContext(DocumentGenerationContext);
	const classes = useClasses();
	const { t } = useTranslation();
	const { results: documentsResult, isLoading: isLoadingDocuments } = useGetDocumentInfoList(types, region);
	const { results: sectionsResult } = useGetDocumentSectionInfoList(region, selectedDocument?.id);
	const projectInfo = useGetProjectInfo(selectedProject?.id);

	useEffect(() => {
		if (documentsResult.length === 0) return;
		setSelectedDocument(documentsResult[0]);
	}, [setSelectedDocument, documentsResult]);

	const onDocumentSelected = (_: SelectionEvents, data: OptionOnSelectData) => {
		setSelectedDocument(documentsResult.find(document => document.id === data.optionValue)!);
	};

	return (
		<div className={classes.container}>
			<div className={classes.textContainer}>
				<Text className={classes.companyName}>{selectedCompany?.name}</Text>
				<Text className={classes.h1}>{selectedProject?.name ?? t('documentsPage.noProjectSelected')}</Text>
				{selectedClient?.name && <Text className={classes.h2}>{`${selectedClient.name}${selectedContact?.name ? ` - ${selectedContact?.name}` : ''}`}</Text>}
				<Body1
					className={!projectInfo.data?.address ? classes.redText : undefined}>{!!projectInfo.data?.address ? projectInfo.data.address : !projectInfo.isLoading ? t('documentsPage.noProjectLocation') : ''}</Body1>
			</div>
			{isCopyingExistingSettings && selectedDocument ? <Body1>{selectedDocument.name}</Body1> : (
				(isLoadingDocuments || (documentsResult.length > 0 && !selectedDocument)) ? (
					<div className={classes.flex}>
						<Spinner />
					</div>
				) : documentsResult.length === 0 ? <Text>{t('documentsPage.noTemplates')}</Text> : (
					<div className={classes.column}>
						<Label required>{t('documentsPage.documentToGenerate')}</Label>
						<Dropdown onOptionSelect={onDocumentSelected} defaultValue={selectedDocument!.name} defaultSelectedOptions={[selectedDocument!.id]}>
							{documentsResult.map((document) => (
								<Option key={document.id} value={document.id}>
									{document.name}
								</Option>
							))}
						</Dropdown>
					</div>
				)
			)}
			{sectionsResult.map((section) => {
				if (section.type === 'OptionSection') {
					const typedSection = section as OptionSectionTemplateConfigurationResponse;
					return <DocumentGenerationOptionSection key={typedSection.id} section={typedSection} />;
				} else if (section.type === 'OptionSectionApi') {
					const typedSection = section as OptionSectionApiTemplateConfigurationResponse;
					return <DocumentGenerationOptionSectionApi key={typedSection.id} section={typedSection} />;
				}
				return null;
			})}
		</div>
	);
};

export default memo(DocumentGenerationSelectDocumentOptions);
