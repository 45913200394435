import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ArchivableProjectResponseEntryInterface from '../../interfaces/response/ArchivableProjectResponseEntryInterface';

const emptyResult: ArchivableProjectResponseEntryInterface[] = [];

const getArchivableProjects: QueryFunction<ArchivableProjectResponseEntryInterface[]> = async ({ queryKey }: QueryFunctionContext) => {
	const url = `/api/archive/projects/archiveable?months=${queryKey[0]}&maximumResults=${queryKey[1]}&startDate=${queryKey[2]}&findTloProjects=${queryKey[3]}&projectLeadName=${queryKey[4]}&projectNr=${queryKey[5]}`;
	const response = await api.get<ArchivableProjectResponseEntryInterface[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetArchiveJobList = (months: number, maximumResults: number, startDate: Date, findTloProjects: boolean, projectLeadName: string, projectNumber: string) => {
	const { data, isFetching, error } = useQuery({
		queryKey: [months, maximumResults, startDate.toISOString(), findTloProjects, projectLeadName, projectNumber, 'getArchivableProjects'],
		queryFn: getArchivableProjects,
		enabled: maximumResults > 0 && months > 0,
	});

	return {
		projects: data ?? emptyResult,
		isFetching,
		error,
	};
};

export default useGetArchiveJobList;
