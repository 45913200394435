import { MutateFunction, useMutation } from '@tanstack/react-query';
import { api } from '../../index';

const deleteFilesMutation: MutateFunction<void, Error, string[]> = async (ids: string[]) => {
	const response = await api.delete(`/api/tools/files-cleanup`, {
		data: JSON.stringify(ids),
		headers: {
			'Content-Type': 'application/json',
		},
	});
	if (response.status !== 200) {
		throw new Error(response.data);
	}
};

const useDeleteFilesToDeleteMutation = () => {
	const { isPending, mutate, isSuccess } = useMutation({
		mutationFn: deleteFilesMutation,
	});

	return {
		mutate,
		isPending,
		isSuccess,
	};
};

export default useDeleteFilesToDeleteMutation;
