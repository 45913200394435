import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import DocumentInfo, { DocumentType } from '../../interfaces/response/DocumentInfo';
import Region from '../../interfaces/Region';

const emptyResult: DocumentInfo[] = [];

const getDocumentInfoList: QueryFunction<DocumentInfo[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	let url = `/api/document/company/document-infos?types=${queryKey[0]}&region=${queryKey[1]}`;
	const response = await api.get<DocumentInfo[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetDocumentInfoList = (types: DocumentType[], region: Region) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [types.join(','), region, 'getDocumentInfoList'],
		queryFn: getDocumentInfoList,
		enabled: types.length > 0,
	});

	return {
		results: data || emptyResult,
		isLoading,
		error,
	};
};

export default useGetDocumentInfoList;
