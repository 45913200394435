import {
	Button,
	createTableColumn,
	DataGrid,
	DataGridBody,
	DataGridCell,
	DataGridHeader,
	DataGridHeaderCell,
	DataGridRow,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Label,
	Link,
	makeStyles,
	OnSelectionChangeData,
	Select,
	Spinner,
	TableCellLayout,
	TableColumnDefinition,
	TableColumnId,
	TableRowId,
	Text,
	tokens,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import useGetFilesToBeDeleted from '../../hooks/queries/useGetFilesToBeDeleted';
import FileToBeDeleted from '../../interfaces/response/FileToBeDeleted';
import { getSizeInMegabytes } from '../../helpers/numberHelper';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { TableRowData } from '@fluentui/react-table';
import useDeleteFilesToDeleteMutation from '../../hooks/mutations/useDeleteFilesToDeleteMutation';

const useClasses = makeStyles({
	container: {
		maxWidth: '60em',
	},
	table: {
		maxHeight: '60vh',
		overflowY: 'scroll',
	},
	bottomContainer: {
		marginTop: tokens.spacingVerticalMNudge,
	},
	noSelect: {
		userSelect: 'none',
		wordBreak: 'break-word',
	},
	infoContainer: {
		marginBottom: tokens.spacingVerticalMNudge,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	flex: {
		display: 'flex',
		flex: 1,
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
	},
});

const CloseButton = (
	<DialogTrigger action="close">
		<Button
			appearance="subtle"
			aria-label="close"
			icon={<Dismiss24Regular />}
		/>
	</DialogTrigger>
);

const createColumns = (classes: any, t: TFunction, language: string): TableColumnDefinition<FileToBeDeleted>[] => ([
	createTableColumn<FileToBeDeleted>({
		columnId: 'name',
		renderHeaderCell: (_) => (
			<Text className={classes.noSelect}>
				<b>{t('toolsPage.cleanup.fileName')}</b>
			</Text>
		),
		renderCell: (item) => (
			<TableCellLayout>
				<Link as={'a'} rel={'noreferrer'} href={item.url} target={'_blank'}>{item.filename}</Link>
			</TableCellLayout>
		),
	}),
	createTableColumn<FileToBeDeleted>({
		columnId: 'size',
		renderHeaderCell: (_) => (
			<Text className={classes.noSelect}>
				<b>{t('toolsPage.cleanup.fileSize')}</b>
			</Text>
		),
		renderCell: (item) => (
			<TableCellLayout>
				{getSizeInMegabytes(item.size, language)}
			</TableCellLayout>
		),
	}),
]);

const getStyle = (id: TableColumnId) => {
	return {
		minWidth: id.toString() === 'name' ? '70%' : '20%',
	};
};

const pageSizes = [100, 250, 500, 1000, 2000];

const SharepointCleanupDialog: React.FC = () => {
	const [selectedItems, setSelectedItems] = useState<Set<TableRowId>>(new Set());
	const { t, i18n: { language } } = useTranslation();
	const classes = useClasses();
	const [page] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(pageSizes[0]);
	const { filesToBeDeleted, isFetching, refetch } = useGetFilesToBeDeleted(page, pageSize);
	const deleteFilesToDeleteMutation = useDeleteFilesToDeleteMutation();
	const columns = useMemo(() => createColumns(classes, t, language), [classes, t, language]);

	useEffect(() => {
		setSelectedItems(new Set(filesToBeDeleted?.files.map(f => f.id) ?? []));
	}, [filesToBeDeleted]);

	useEffect(() => {
		if (deleteFilesToDeleteMutation.isSuccess && !deleteFilesToDeleteMutation.isPending) {
			refetch();
		}
	}, [deleteFilesToDeleteMutation.isSuccess, deleteFilesToDeleteMutation.isPending, refetch]);

	const onSelectionChange = useCallback((_: any, data: OnSelectionChangeData) => {
		setSelectedItems(data.selectedItems);
	}, []);

	const FilesGrid = useMemo(() => {
		if (!filesToBeDeleted) return null;
		return (
			<DataGrid
				items={filesToBeDeleted.files}
				columns={columns}
				selectionMode={'multiselect'}
				resizableColumns={false}
				selectedItems={selectedItems}
				onSelectionChange={onSelectionChange}
				getRowId={(item) => item.id}
			>
				<DataGridHeader>
					<DataGridRow>
						{({ renderHeaderCell, columnId }) => <DataGridHeaderCell style={getStyle(columnId)}>{renderHeaderCell()}</DataGridHeaderCell>}
					</DataGridRow>
				</DataGridHeader>
				<DataGridBody<FileToBeDeleted> className={classes.table}>
					{(props) => <MemoizedFileRow {...props} />}
				</DataGridBody>
			</DataGrid>
		);
	}, [filesToBeDeleted, selectedItems, onSelectionChange, classes, columns]);

	const deleteFiles = useCallback(() => {
		const ids: string[] = [];
		selectedItems.forEach(item => ids.push(item.toString()));
		deleteFilesToDeleteMutation.mutate(ids);
	}, [selectedItems, deleteFilesToDeleteMutation]);

	const onChangePageSize: React.ChangeEventHandler<HTMLSelectElement> = (evt) => {
		setPageSize(+evt.target.value);
	};

	return (
		<DialogSurface className={classes.container}>
			<DialogBody>
				<DialogTitle action={CloseButton}>{t('toolsPage.cleanup.title')}</DialogTitle>
				<DialogContent>
					{isFetching ? <Spinner /> : filesToBeDeleted && (
						<>
							<div className={classes.infoContainer}>
								<div className={classes.column}>
									<Label><b>{t('toolsPage.archive.totalSize')}:</b> {getSizeInMegabytes(filesToBeDeleted.totalSize, language)}</Label>
									<Label><b>{t('toolsPage.cleanup.totalFileCount')}:</b> {filesToBeDeleted.totalCount}</Label>
								</div>
								<div>
									<Label>{t('toolsPage.cleanup.count')}</Label>
									<Select onChange={onChangePageSize} value={pageSize}>
										{pageSizes.map(size => <option value={size}>{size}</option>)}
									</Select>
								</div>
							</div>
							{FilesGrid}
						</>
					)}
				</DialogContent>
				<DialogActions className={classes.bottomContainer}>
					<Button disabled={isFetching || deleteFilesToDeleteMutation.isPending} onClick={deleteFiles} appearance={'primary'}>
						{((deleteFilesToDeleteMutation.isPending || isFetching) ? <Spinner size={'tiny'} /> : t('toolsPage.cleanup.markForDeletion', { count: selectedItems.size }))}
					</Button>
				</DialogActions>
			</DialogBody>
		</DialogSurface>
	);
};

const FileRow: React.FC<TableRowData<FileToBeDeleted>> = ((props) => (
	<DataGridRow<FileToBeDeleted> key={props.rowId}>
		{({ renderCell, columnId }) => (
			<DataGridCell style={getStyle(columnId)} key={columnId}>{renderCell(props.item)}</DataGridCell>
		)}
	</DataGridRow>
));
const MemoizedFileRow = memo(FileRow, (prev, next) => prev.rowId === next.rowId);

export default SharepointCleanupDialog;
