import React, { memo, useContext, useEffect, useMemo } from 'react';
import { OptionTemplateConfiguration } from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import {
	Button,
	Checkbox,
	CheckboxOnChangeData,
	Dropdown,
	FlatTree,
	HeadlessFlatTreeItemProps,
	Input,
	Label,
	makeStyles,
	Option,
	OptionOnSelectData,
	SelectionEvents,
	Spinner,
	Text,
	Textarea,
	tokens,
	TreeItem,
	TreeItemLayout,
	useHeadlessFlatTree_unstable,
} from '@fluentui/react-components';
import LocalizedDatePicker from '../LocalizedDatePicker';
import useGetDocumentApiOptionsList from '../../hooks/queries/useGetDocumentApiOptionsList';
import { useTranslation } from 'react-i18next';
import DocumentGenerationContext from '../../context/DocumentGenerationContext';
import DocumentGenerationRequestDataContext from '../../context/DocumentGenerationRequestDataContext';
import { TreeCheckedChangeData, TreeCheckedChangeEvent } from '@fluentui/react-tree';

type Props = {
	index?: number;
	section: string;
	option: OptionTemplateConfiguration;
	data: any;
}

const useClasses = makeStyles({
	column: {
		flexDirection: 'column',
		gap: '2px',
		marginBottom: tokens.spacingVerticalM,
		display: 'flex',
	},
	emptyResultContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: tokens.spacingHorizontalL,
	},
});

type FlatItem = HeadlessFlatTreeItemProps & { content: string, hasChildren: boolean };

const FlatTreeItem: React.FC<{ props: any }> = memo(({ props }) => {
	const { content, hasChildren, parents, value, ...treeItemProps } = props.getTreeItemProps();
	return (
		<TreeItem {...treeItemProps} value={value} key={value}>
			<TreeItemLayout>
				{content}
			</TreeItemLayout>
		</TreeItem>
	);
});

const DocumentGenerationOptionCheckbox: React.FC<Props> = ({ option, section, data }) => {
	const { updateRequestDataSection } = useContext(DocumentGenerationContext);
	const selectedIds = useMemo<string[]>(() => {
		return data ? Object.entries(data)
			.filter(([_, value]) => value === true)
			.map(([key]) => key) : [];
	}, [data]);


	const onCheckedChange = (_: TreeCheckedChangeEvent, changeData: TreeCheckedChangeData) => {
		const copy = { ...data };
		changeData.checkedItems.forEach((checkedItem, id) => {
			copy[id] = checkedItem !== false;
		});
		updateRequestDataSection(section, copy);
	};

	const flatTreeItems = useMemo(() => {
		const addChildren = (option: OptionTemplateConfiguration, parentValue?: string) => {
			list.push({ value: option.id, content: option.name, parentValue, hasChildren: option.children.length > 0 });
			option.children.forEach(child => addChildren(child, option.id));
		};

		const list: FlatItem[] = [];
		addChildren(option, undefined);
		return list;
	}, [option]);

	const flatTree = useHeadlessFlatTree_unstable(flatTreeItems, {
		checkedItems: selectedIds,
		onCheckedChange: onCheckedChange,
		selectionMode: 'multiselect',
	});

	useEffect(() => {
		if (!option.isPreselected || option.children.length > 0) return;
		const copy = { ...data };
		copy[option.id] = true;
		updateRequestDataSection(section, copy);
		// eslint-disable-next-line
	}, [option, section, updateRequestDataSection]);

	const onChange = (_: any, changeData: CheckboxOnChangeData) => {
		const copy = { ...data };
		copy[option.id] = changeData.checked !== false;
		updateRequestDataSection(section, copy);
	};

	return option.children.length > 0 ? (
		<FlatTree {...flatTree.getTreeProps()} aria-label={section}>
			{Array.from(flatTree.items(), (flatTreeItem) => <FlatTreeItem key={flatTreeItem.value} props={flatTreeItem} />)}
		</FlatTree>
	) : <Checkbox label={option.name} checked={selectedIds.includes(option.id)} onChange={onChange} />;
};

const DocumentGenerationOptionText: React.FC<Props> = ({ option, section, data }) => {
	const { updateRequestDataSection } = useContext(DocumentGenerationContext);
	const content = useMemo(() => data[option.id] ?? '', [data, option]);
	const classes = useClasses();

	const updateContentTextInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		const copy = { ...data } ?? {};
		copy[option.id] = event.target.value;
		updateRequestDataSection(section, copy);
	};

	return (
		<div className={classes.column}>
			<Label required={option.required}>{option.name}</Label>
			<Input value={content} onChange={updateContentTextInput} placeholder={option.placeholder} />
		</div>
	);
};

const DocumentGenerationOptionDate: React.FC<Props> = ({ option, section, data }) => {
	const { updateRequestDataSection } = useContext(DocumentGenerationContext);
	const content = useMemo(() => data[option.id] ?? new Date(), [data, option]);
	const classes = useClasses();

	const updateDateInput = (date: Date | null | undefined) => {
		if (!date) return;
		const copy = { ...data } ?? {};
		copy[option.id] = date.toISOString();
		updateRequestDataSection(section, copy);
	};

	return (
		<div className={classes.column}>
			<Label required={option.required}>{option.name}</Label>
			<LocalizedDatePicker value={new Date(content)} onSelectDate={updateDateInput} maxDate={new Date()} />
		</div>
	);
};

const DocumentGenerationOptionTextArea: React.FC<Props> = ({ option, section, data }) => {
	const { updateRequestDataSection } = useContext(DocumentGenerationContext);
	const content = useMemo(() => data[option.id] ?? '', [data, option]);
	const classes = useClasses();

	const updateContentTextArea: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
		const copy = { ...data } ?? {};
		copy[option.id] = event.target.value;
		updateRequestDataSection(section, copy);
	};

	return (
		<div className={classes.column}>
			<Label required={option.required}>{option.name}</Label>
			<Textarea value={content} onChange={updateContentTextArea} placeholder={option.placeholder} />
		</div>
	);
};


const DocumentGenerationOptionDropdown: React.FC<Props> = ({ option, section, data }) => {
	const classes = useClasses();
	const { updateRequestDataSection } = useContext(DocumentGenerationContext);
	const selectedValue = useMemo(() => data[option.id] ?? option.values[0], [data, option]);

	const onOptionSelect = (_: SelectionEvents, sData: OptionOnSelectData) => {
		const index = option.values.findIndex(value => value === sData.optionValue);
		const copy = { ...data };
		copy[option.id] = option.values[index];
		updateRequestDataSection(section, copy);
	};

	return (
		<div className={classes.column}>
			<Label required={option.required}>{option.name}</Label>
			<Dropdown value={selectedValue} onOptionSelect={onOptionSelect}>
				{option.values.map(value => (
					<Option key={value}>
						{value}
					</Option>
				))}
			</Dropdown>
		</div>
	);
};

const DocumentGenerationApi: React.FC<Props> = (props) => {
	const classes = useClasses();
	const { t } = useTranslation();
	const requestData = useContext(DocumentGenerationRequestDataContext);
	const options = useGetDocumentApiOptionsList(requestData, props.option.endpoint, props.index);

	const refreshData = () => {
		options.refetch();
	};

	return <>
		{options.isFetching ? <Spinner /> : options.results.length > 0 ? (
			<div className={classes.column}>
				<Label required={props.option.required}>{props.option.name}</Label>
				<div>
					{options.results.map(option => <DocumentGenerationOption {...props} option={option} key={option.id} />)}
				</div>
			</div>
		) : (
			<div className={classes.emptyResultContainer}>
				<Text>{t('documentsPage.nothingFound')}</Text>
				<Button onClick={refreshData}>{t('refresh')}</Button>
			</div>
		)}
	</>;
};

const DocumentGenerationOption: React.FC<Props> = (props) => {
	switch (props.option.type) {
		case 'Checkbox':
			return <DocumentGenerationOptionCheckbox {...props} key={props.option.id} />;
		case 'TextArea':
			return <DocumentGenerationOptionTextArea {...props} key={props.option.id} />;
		case 'Text':
			return <DocumentGenerationOptionText {...props} key={props.option.id} />;
		case 'Date':
			return <DocumentGenerationOptionDate {...props} key={props.option.id} />;
		case 'Dropdown':
			return <DocumentGenerationOptionDropdown {...props} key={props.option.id} />;
		case 'Api':
			return <DocumentGenerationApi {...props} key={props.option.id} />;
	}
};

export default memo(DocumentGenerationOption);
