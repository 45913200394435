import { createContext } from 'react';
import GenerateDocumentDataInterface from '../interfaces/request/GenerateDocumentDataInterface';


const defaultValue: GenerateDocumentDataInterface = {
	RequestData: {},
};

const DocumentGenerationRequestDataContext = createContext<GenerateDocumentDataInterface>(defaultValue);

export default DocumentGenerationRequestDataContext;
