import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const getSuggestedContacts: QueryFunction<NameAndId[]> = async ({ queryKey }: QueryFunctionContext) => {
	let params = '';
	const projectIds = (queryKey[0] as string).split('&');
	projectIds.forEach((id, index) => {
		params += `projectIds=${id}`;
		if (index < projectIds.length - 1) {
			params += `&`;
		}
	});
	const url = `/api/tlo/suggested-contacts?${params}`;
	const response = await api.get<NameAndId[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const emptyResult: NameAndId[] = [];

const useGetSuggestedContacts = (projectIds: string[]) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: [projectIds.join('&'), 'getSuggestedContacts'],
		queryFn: getSuggestedContacts,
		enabled: projectIds.length > 0,
	});

	return {
		data: data ?? emptyResult,
		isLoading,
		isError,
	};
};

export default useGetSuggestedContacts;
