import React, { useMemo } from 'react';
import { makeStyles, Persona, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components';
import useGetContactInfo from '../hooks/queries/useGetContactInfo';

type Props = {
	contactId: string;
}

const useClasses = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	smallLineHeight: {
		lineHeight: tokens.lineHeightBase200,
	},
	skeletonSpacing: {
		height: tokens.spacingHorizontalSNudge,
	},
});

const ExternalContactInfo: React.FC<Props> = ({ contactId }) => {
	const classes = useClasses();
	const contact = useGetContactInfo(contactId);
	const name = useMemo<string | undefined>(() => contact.data ? `${contact.data.firstName} ${contact.data.lastName}` : undefined, [contact.data]);

	if (!contact.data) {
		return (
			<Skeleton className={classes.container}>
				<SkeletonItem size={16} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
			</Skeleton>
		);
	}
	return (
		<div className={classes.container}>
			<Persona size={'medium'}
						primaryText={name}
						secondaryText={contact.data.function ? `${contact.data.function} - ${contact.data.clientName}` : contact.data.clientName}
						tertiaryText={contact.data.mail}
						avatar={{ name }}
						quaternaryText={contact.data.telephoneNumber ? `Tel. ${contact.data.telephoneNumber}` : undefined}
			/>
		</div>
	);
};

export default ExternalContactInfo;
