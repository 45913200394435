import {
	Button,
	Combobox,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Input,
	Label,
	makeStyles,
	OptionOnSelectData,
	Select,
	SelectionEvents,
	Spinner,
	Subtitle2,
	Text,
	tokens,
	Tree,
	TreeItem,
	TreeItemLayout,
	useComboboxFilter,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useReadExcelDocumentLinesMutation from '../../hooks/mutations/useReadExcelDocumentLinesMutation';
import NameAndId from '../../interfaces/NameAndId';
import useGetClientList from '../../hooks/queries/useGetClientList';
import AutofillSearchBar from '../AutofillSearchBar';
import useGetEntitiesList from '../../hooks/queries/useGetEntitiesList';
import useCreateOfferMutation from '../../hooks/mutations/useCreateTloOfferMutation';
import { CreateTloOfferRequestInterfaceFolder } from '../../interfaces/request/CreateTloOfferRequestInterface';
import { useTranslation } from 'react-i18next';
import FileInput from '../FileInput';

const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const useClasses = makeStyles({
	content: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '10px',
		maxHeight: '70vh',
	},
	listContainer: {
		flexDirection: 'column',
		display: 'flex',
		rowGap: tokens.spacingVerticalS,
	},
	noSelection: {
		userSelect: 'none',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		columnGap: tokens.spacingHorizontalS,
	},
	subtitle: {
		marginBottom: tokens.spacingVerticalSNudge,
	},
});

enum State {
	UploadExcel,
	ReadingExcel,
	ParsingResults,
	UploadingToTlo,
	Finished
}

type ItemDict = {
	[key: string]: {
		name: string;
		key: string;
		values: ItemDict;
	};
}

const TloOfferteImport: React.FC = () => {
	const readExcelTloImportLines = useReadExcelDocumentLinesMutation();
	const fileUploadRef = useRef<HTMLInputElement>(null);
	const classes = useClasses();
	const [state, setState] = useState<State>(State.UploadExcel);
	const [selectedFileName, setSelectedFileName] = useState<string>();
	const [selectedEntity, setSelectedEntity] = useState<NameAndId>();
	const [selectedClient, setSelectedClient] = useState<NameAndId>();
	const [clientSearchTerm, setClientSearchTerm] = useState<string>('');
	const [entitySearchTerm, setEntitySearchTerm] = useState<string>('');
	const [offerName, setOfferName] = useState<string>('');
	const searchedClients = useGetClientList(clientSearchTerm);
	const searchedEntities = useGetEntitiesList(selectedClient?.id);
	const createOfferMutation = useCreateOfferMutation();
	const { t } = useTranslation();
	const [selectedChapterColumn, setSelectedChapterColumn] = useState<string>('A');
	const [selectedChapterNameColumn, setSelectedChapterNameColumn] = useState<string>('B');
	const CloseButton = useMemo(() => {
		return (
			<DialogTrigger action="close">
				<Button
					appearance="subtle"
					aria-label="close"
					icon={<Dismiss24Regular />}
				/>
			</DialogTrigger>
		);
	}, []);

	useEffect(() => {
		setSelectedEntity(undefined);
		setEntitySearchTerm('');
	}, [selectedClient]);

	useEffect(() => {
		if (!createOfferMutation.isError && !createOfferMutation.isSuccess) return;
		setState(State.Finished);
	}, [createOfferMutation.isSuccess, createOfferMutation.isError]);

	useEffect(() => {
		if (readExcelTloImportLines.isSuccess) {
			setState(State.ParsingResults);
		}
	}, [readExcelTloImportLines.isSuccess]);

	const onUploadExcelClicked: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
		setState(State.ReadingExcel);
		readExcelTloImportLines.mutate({
			file: fileUploadRef.current!.files![0],
			sheetIndex: '0',
			chapterNameColumn: selectedChapterNameColumn,
			chapterColumn: selectedChapterColumn,
		});
	}, [readExcelTloImportLines, selectedChapterNameColumn, selectedChapterColumn]);

	const items = useMemo<ItemDict>(() => {
		const items: ItemDict = {};
		readExcelTloImportLines.response?.lines.forEach(line => {
			if (line.values.length <= 1 || !line.values[0] || !line.values[1]) return;
			if (!line.values[0].includes('.')) return;
			const chapters = line.values[0].split('.').filter(c => !!c);
			chapters.reduce((map, curr, currentIndex) => {
				const key = chapters.slice(0, currentIndex + 1).join('.');
				if (!map[curr]) {
					map[curr] = {
						name: line.values[1],
						key,
						values: {},
					};
				}
				return map[curr].values;
			}, (items));
		});
		return items;
	}, [readExcelTloImportLines.response]);

	const onCreateOfferClicked = useCallback(() => {
		if (!selectedEntity) return;
		setState(State.UploadingToTlo);
		const folders: CreateTloOfferRequestInterfaceFolder[] = [];
		const addFolders = (itemDict: ItemDict, parentFolder: CreateTloOfferRequestInterfaceFolder[]) => {
			Object.entries(itemDict).sort(([, valueA], [, valueB]) => valueA.key.localeCompare(valueB.key)).forEach(([_, value]) => {
				const childFolder: CreateTloOfferRequestInterfaceFolder[] = [];
				parentFolder.push({
					name: value.name,
					children: childFolder,
					description: value.key,
				});
				addFolders(value.values, childFolder);
			});
		};
		addFolders(items, folders);
		createOfferMutation.mutate({
			entityId: selectedEntity.id,
			offerName,
			folders,
		});
	}, [selectedEntity, items, createOfferMutation, offerName]);

	const onSearchEntity = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
		setEntitySearchTerm(event.target.value);
	}, []);

	const onSelectEntity = useCallback((_: SelectionEvents, data: OptionOnSelectData) => {
		const option = searchedEntities.entityResults.find(offer => offer.id === data.optionValue);
		setSelectedEntity(option);
		setEntitySearchTerm(option?.name ?? '');
	}, [searchedEntities]);

	const entityOptions = useComboboxFilter(entitySearchTerm, searchedEntities.entityResults.map(offer => ({
		children: offer.name,
		value: offer.id,
	})), {
		optionToText: (option) => option.children,
		noOptionsMessage: searchedEntities.isLoading ? t('loading') : searchedEntities.isFetched ? t('toolsPage.noEntitiesFound') : t('toolsPage.selectClientFirst'),
	});

	const onChangeOfferName: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
		setOfferName(event.target.value);
	}, []);

	const canImportOffer = useMemo<boolean>(() => {
		return !selectedEntity || offerName.length === 0;
	}, [selectedEntity, offerName]);

	const renderItemDict = useCallback((itemDict: ItemDict) => {
		return (
			<Tree aria-label={'tree'}>
				{Object.entries(itemDict).sort((a, b) => +a[0] - +b[0]).map(([key, value]) => (
					<TreeItem itemType={Object.values(value.values).length > 0 ? 'branch' : 'leaf'} key={value.key} value={value.key}>
						<TreeItemLayout className={classes.noSelection}>{key} - {value.name}</TreeItemLayout>
						{renderItemDict(value.values)}
					</TreeItem>
				))}
			</Tree>
		);
	}, [classes]);

	const onChangeSelectedChapterColumn: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
		setSelectedChapterColumn(event.target.value);
	};

	const onChangeSelectedChapterColumnName: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
		setSelectedChapterNameColumn(event.target.value);
	};

	return (
		<DialogSurface>
			<DialogBody>
				<DialogTitle action={CloseButton}>{t('toolsPage.tloOfferImport.title')}</DialogTitle>
				<DialogContent className={classes.content}>
					{state === State.UploadExcel && (
						<>
							<div className={classes.column}>
								<Label>{t('toolsPage.tloOfferImport.chapterColumn')}</Label>
								<Select value={selectedChapterColumn} onChange={onChangeSelectedChapterColumn}>
									{columns.map(c => <option value={c} key={c}>{c}</option>)}
								</Select>
							</div>
							<div className={classes.column}>
								<Label>{t('toolsPage.tloOfferImport.chapterNameColumn')}</Label>
								<Select value={selectedChapterNameColumn} onChange={onChangeSelectedChapterColumnName}>
									{columns.map(c => <option value={c} key={c}>{c}</option>)}
								</Select>
							</div>
							<FileInput title={t('toolsPage.tloOfferImport.header')}
										  accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
										  setFilename={setSelectedFileName}
										  ref={fileUploadRef} />
						</>
					)}
					{(state === State.ReadingExcel || state === State.UploadingToTlo) && <Spinner />}
					{state === State.ParsingResults && (
						<div className={classes.listContainer}>
							<AutofillSearchBar required
													 onSearch={setClientSearchTerm}
													 searchedSearchTerm={clientSearchTerm}
													 label={t('toolsPage.client')}
													 options={searchedClients.clientResults}
													 onSelectEntry={setSelectedClient}
													 loading={searchedClients.isFetching}
													 placeholder={t('typeToSearch')}
													 selectedEntry={selectedClient}
							/>
							<div className={classes.column}>
								<Label required>{t('toolsPage.entity')}</Label>
								<Combobox
									clearable
									freeform
									onOptionSelect={onSelectEntity}
									placeholder={t('typeToSearch')}
									onChange={onSearchEntity}
									value={entitySearchTerm}
								>
									{entityOptions}
								</Combobox>
							</div>
							<div className={classes.column}>
								<Label required>{t('toolsPage.tloOfferImport.offerName')}</Label>
								<Input type={'text'} value={offerName} onChange={onChangeOfferName} placeholder={t('toolsPage.tloOfferImport.offerNamePlaceholder')} />
							</div>

							<div className={classes.column}>
								<Subtitle2 className={classes.subtitle}>{t('toolsPage.tloOfferImport.folderStructure')}:</Subtitle2>
								{renderItemDict(items)}
							</div>
						</div>
					)}
					{state === State.Finished && (
						<div>
							{createOfferMutation.isSuccess && <Text>{t('toolsPage.tloOfferImport.successMessage')}</Text>}
							{createOfferMutation.isError && <Text>{t('toolsPage.tloOfferImport.errorMessage', { error: createOfferMutation.error?.message })}</Text>}
						</div>
					)}
				</DialogContent>
				<DialogActions>
					{state === State.UploadExcel && <Button appearance={'primary'} disabled={!selectedFileName} onClick={onUploadExcelClicked}>{t('toolsPage.upload')}</Button>}
					{state === State.ParsingResults && (
						<Button appearance={'primary'} disabled={canImportOffer} onClick={onCreateOfferClicked}>
							{t('toolsPage.tloOfferImport.importOfferData')}
						</Button>
					)}
					{state === State.Finished && !createOfferMutation.isError &&
						<Button appearance={'primary'} as={'a'} target={'_blank'} href={createOfferMutation.response}>{t('toolsPage.tloOfferImport.toTloOffer')}</Button>}
				</DialogActions>
			</DialogBody>
		</DialogSurface>
	);
};

export default TloOfferteImport;
