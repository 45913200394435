import { MutateFunction, useMutation } from '@tanstack/react-query';
import { api } from '../../index';
import ReadExcelLinesResponseInterface from '../../interfaces/response/ReadExcelLinesResponseInterface';

type ReadExcelDocumentLinesRequestParams = {
	file: File;
	sheetIndex: string;
	chapterColumn: string;
	chapterNameColumn: string;
}

const readExcelDocumentLinesMutation: MutateFunction<ReadExcelLinesResponseInterface, Error, ReadExcelDocumentLinesRequestParams> = async (params) => {
	const formData = new FormData();
	formData.append('file', params.file);
	const url = `/api/tools/read-excel-offer?sheetIndex=${params.sheetIndex}&chapterColumn=${params.chapterColumn}&chapterNameColumn=${params.chapterNameColumn}`;
	const response = await api.post<ReadExcelLinesResponseInterface>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return response.data;
};

const useReadExcelDocumentLinesMutation = () => {
	const { isSuccess, mutate, isError, data: response } = useMutation({
		mutationFn: readExcelDocumentLinesMutation,
	});

	return {
		mutate,
		isError,
		isSuccess,
		response
	};
};

export default useReadExcelDocumentLinesMutation;
