import React from 'react';
import useGetProjectInfo from '../hooks/queries/useGetProjectInfo';
import { Body1, Caption1, Caption1Stronger, Link, makeStyles, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

type Props = {
	projectId: string;
}

const useClasses = makeStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '15em',
	},
	smallLineHeight: {
		lineHeight: tokens.lineHeightBase200,
	},
	projectTeamContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: tokens.spacingVerticalSNudge,
	},
	teamList: {
		listStyle: 'inside',
	},
	skeletonSpacing: {
		height: tokens.spacingHorizontalSNudge,
	},
});

const ProjectInfo: React.FC<Props> = ({ projectId }) => {
	const { t } = useTranslation();
	const project = useGetProjectInfo(projectId);
	const classes = useClasses();

	if (!project.data) {
		return (
			<Skeleton className={classes.container}>
				<SkeletonItem size={16} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
				<div className={classes.skeletonSpacing} />
				<SkeletonItem size={12} />
			</Skeleton>
		);
	}
	return (
		<div className={classes.container}>
			<Body1>
				<Link as={'a'} target={'_blank'} href={`https://teamleaderorbit.unitedexperts.be/project/${project.data.extId}`}>
					{!!project.data.projectName.trim() ? `${project.data.extId}: ${project.data.projectName}` : project.data.extId}
				</Link>
			</Body1>
			<Caption1 className={classes.smallLineHeight}>{!!project.data.address ? project.data.address : t('telephony.noAddressFound')}</Caption1>
			{project.data.teamMembers.length > 0 && (
				<div className={classes.projectTeamContainer}>
					<Caption1Stronger>{t('telephony.projectTeam')}</Caption1Stronger>
					<ul className={classes.teamList}>
						{project.data.teamMembers.map((member, index) => (
							<li className={classes.smallLineHeight} key={index}>
								<Caption1>
									<Link href={`https://teamleaderorbit.unitedexperts.be/user/${member.id}`} target={'_blank'} as={'a'}>{member.firstName} {member.lastName}</Link>
								</Caption1>
								{member.isProjectLead && <Caption1>{` - ${t('telephony.projectLead')}`}</Caption1>}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default ProjectInfo;
