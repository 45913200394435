import React, { useEffect, useMemo, useState } from 'react';
import useSearchTlo from '../hooks/queries/useSearchTlo';
import { Body1, Combobox, ComboboxProps, Label, makeStyles, Option, OptionGroup, Spinner, tokens } from '@fluentui/react-components';
import TelephonyTloSummary from './TelephonyTloSummary';
import InnovaphoneCallsResponseInterface from '../interfaces/response/InnovaphoneCallsResponseInterface';
import useGetContactInfoByPhoneNumber from '../hooks/queries/useGetContactInfoByPhoneNumber';
import { useTranslation } from 'react-i18next';

type Props = {
	call: InnovaphoneCallsResponseInterface;
	transferCallDialogOpen: boolean;
	setTransferCallDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setSummaryDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useClasses = makeStyles({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
	},
	padding: {
		padding: tokens.spacingHorizontalSNudge,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
	searchBarLabel: {
		marginRight: tokens.spacingHorizontalS,
	},
	searchBarContainer: {
		flexDirection: 'column',
		gap: '2px',
		display: 'flex',
	},
});

const searchDelay: number = 200;

const TelephoneCallInformation: React.FC<Props> = ({ call, transferCallDialogOpen, setTransferCallDialogOpen, setSummaryDialogOpen }) => {
	const { t } = useTranslation();
	const classes = useClasses();
	const contactsByPhoneNumber = useGetContactInfoByPhoneNumber(call?.callerNumber);
	const [tloSearchString, setTloSearchString] = useState<string>('');
	const { clients, contacts, geoLocations, refetch, projects, isLoading, searchTerm } = useSearchTlo(tloSearchString);
	const [selectedClientId, setSelectedClientId] = useState<string>();

	useEffect(() => {
		if (!contactsByPhoneNumber.isLoading && contactsByPhoneNumber.data?.clientId) {
			setSelectedClientId(contactsByPhoneNumber.data.clientId);
		}
	}, [contactsByPhoneNumber.isLoading, contactsByPhoneNumber.data]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (!tloSearchString) return;
			refetch();
		}, searchDelay);
		return () => clearTimeout(timeout);
	}, [tloSearchString, refetch]);

	const onSearchTloOptionSelect: ComboboxProps['onOptionSelect'] = (_, data) => {
		setSelectedClientId(data.optionValue);
	};

	const onSearchTloChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setTloSearchString(event.target.value);
	};

	const ComboxBoxContent = useMemo(() => {
		if (isLoading) return <Spinner />;
		if (!tloSearchString) return <Body1 className={classes.padding}>{t('telephony.enterSearchTerm')}</Body1>;
		else if (tloSearchString !== searchTerm) return <Spinner />;
		const isEmptyResults = clients.length === 0 && contacts.length === 0 && geoLocations.length === 0 && projects.length === 0;
		if (isEmptyResults) return <Body1 className={classes.padding}>{t('telephony.noResults')}</Body1>;
		else {
			return (
				<>
					<OptionGroup label={t('telephony.geolocations')}>
						{geoLocations.map((g, index) => (
							<Option key={g.id + index} value={g.id}>{g.name}</Option>
						))}
					</OptionGroup>
					<OptionGroup label={t('documentsPage.projects')}>
						{projects.map((p, index) => (
							<Option key={p.id + index} value={p.id}>{p.name}</Option>
						))}
					</OptionGroup>
					<OptionGroup label={t('documentsPage.clients')}>
						{clients.map((c, index) => (
							<Option key={c.id + index} value={c.id}>{c.name}</Option>
						))}
					</OptionGroup>
					<OptionGroup label={t('documentsPage.contacts')}>
						{contacts.map((c, index) => (
							<Option key={c.id + index} value={c.id}>{c.name}</Option>
						))}
					</OptionGroup>
				</>
			);
		}
	}, [classes.padding, clients, contacts, geoLocations, isLoading, projects, searchTerm, tloSearchString, t]);


	return (
		<div className={classes.root}>
			<div className={classes.searchBarContainer}>
				<div className={classes.row}>
					<Label className={classes.searchBarLabel}>{t('telephony.search')}</Label>
				</div>
				<Combobox clearable freeform onOptionSelect={onSearchTloOptionSelect} placeholder={t('typeToSearch')}
							 value={tloSearchString}
							 onChange={onSearchTloChange}>
					{ComboxBoxContent}
				</Combobox>
			</div>
			<TelephonyTloSummary setSummaryDialogOpen={setSummaryDialogOpen} clientId={selectedClientId} callInfo={call} transferCallDialogOpen={transferCallDialogOpen}
										setTransferCallDialogOpen={setTransferCallDialogOpen} />
		</div>
	);
};

export default TelephoneCallInformation;
