import { Body1, Button, Caption1, Card, CardFooter, CardHeader, makeStyles, useRestoreFocusTarget } from '@fluentui/react-components';
import { OpenFolder16Regular } from '@fluentui/react-icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	icon: JSX.Element;
	title: string;
	content: string;
	subTitle: string;
	onClick: () => void;
}

const useClasses = makeStyles({
	text: {
		margin: '0',
	},
	card: {
		width: '400px',
		maxWidth: '100%',
		height: 'fit-content',
	},
});

const ToolsItem: React.FC<Props> = ({ onClick, icon, title, subTitle, content }) => {
	const { t } = useTranslation();
	const classes = useClasses();
	const restoreFocusTargetAttribute = useRestoreFocusTarget();
	const Header = useMemo(() => <Body1><b>{title}</b></Body1>, [title]);
	const Description = useMemo(() => <Caption1>{subTitle}</Caption1>, [subTitle]);

	const onClickCard: React.MouseEventHandler<HTMLElement> = (event) => {
		event.stopPropagation();
		onClick();
	}

	return (
		<Card
			className={classes.card}
			onClick={onClickCard}
			focusMode={'off'}
		>
			<CardHeader
				image={icon}
				header={Header}
				description={Description} />
			<p className={classes.text}>
				{content}
			</p>
			<CardFooter>
				<Button
					{...restoreFocusTargetAttribute}
					onClick={onClickCard}
					appearance="primary"
					icon={<OpenFolder16Regular />}
				>
					{t('toolsPage.start')}
				</Button>
			</CardFooter>
		</Card>
	);
};

export default ToolsItem;
