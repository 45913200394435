import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ContactInfoResponseInterface from '../../interfaces/response/ContactInfoResponseInterface';

const getContactInfoByPhoneNumber: QueryFunction<ContactInfoResponseInterface> = async ({ queryKey }: QueryFunctionContext) => {
	const url = `/api/tlo/contacts?phoneNumber=${encodeURIComponent(queryKey[0] as string)}`;
	const response = await api.get<ContactInfoResponseInterface>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetContactInfoByPhoneNumber = (phoneNumber?: string) => {
	const { data, isLoading } = useQuery({
		queryKey: [phoneNumber, 'getContactInfoByPhoneNumber'],
		queryFn: getContactInfoByPhoneNumber,
		enabled: !!phoneNumber,
	});

	return {
		data,
		isLoading,
	};
};

export default useGetContactInfoByPhoneNumber;
