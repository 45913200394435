import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Button, Label, makeStyles, tokens } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

type Props = {
	title: string;
	setFilename?: (name: string | undefined) => void;
	accept: string;
}

const useClasses = makeStyles({
	fileInputContainer: {
		flexDirection: 'column',
		display: 'flex',
	},
	fileInput: {
		display: 'none',
	},
	excelFileButton: {
		marginTop: tokens.spacingVerticalS,
		width: 'fit-content',
	},
});

const FileInput = forwardRef<HTMLInputElement, Props>(({ title, accept, setFilename }, ref) => {
	const classes = useClasses();
	const { t } = useTranslation();
	const [selectedFileName, setSelectedFileName] = useState<string>();

	useEffect(() => {
		if (!setFilename) return;
		setFilename(selectedFileName);
	}, [selectedFileName, setFilename]);

	const onClickSelectFile: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
		if (!ref) return;
		(ref as React.RefObject<HTMLInputElement>).current?.click();
	}, [ref]);

	const onFileSelected: React.ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
		if (event.target.files) {
			setSelectedFileName(event.target.files.length > 0 ? event.target.files[0].name : undefined);
		} else {
			setSelectedFileName(undefined);
		}
	}, []);

	return (
		<div className={classes.fileInputContainer}>
			<Label required>
				{title}
			</Label>
			<Button className={classes.excelFileButton} onClick={onClickSelectFile}>{selectedFileName ?? t('toolsPage.selectFile')}</Button>
			<input onChange={onFileSelected} ref={ref} type={'file'} accept={accept} className={classes.fileInput} />
		</div>
	);
});

export default FileInput;
