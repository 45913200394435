import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ProjectInfoResponseInterface from '../../interfaces/response/ProjectInfoResponseInterface';

const getProjectInfo: QueryFunction<ProjectInfoResponseInterface> = async ({ queryKey }: QueryFunctionContext) => {
	const url = `/api/tlo/projects/${queryKey[0]}`;
	const response = await api.get<ProjectInfoResponseInterface>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetProjectInfo = (projectId?: string) => {
	const { data, isLoading } = useQuery({
		queryKey: [projectId, 'getProjectInfo'],
		queryFn: getProjectInfo,
		enabled: !!projectId,
	});

	return {
		data,
		isLoading,
	};
};

export default useGetProjectInfo;
