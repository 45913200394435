import { AxiosResponse } from 'axios';

const downloadBlob = (blobResponse: AxiosResponse, name: string) => {
	const url = window.URL.createObjectURL(blobResponse.data);
	const a = document.createElement('a');
	a.href = url;
	a.download = name;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
};

export { downloadBlob };