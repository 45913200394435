import { MutateFunction, useMutation } from '@tanstack/react-query';
import { api } from '../../index';

type UploadParams = {
	file: File;
	documentId: string;
	driveId: string;
}

const uploadSharepointDocument: MutateFunction<void, Error, UploadParams> = async (params) => {
	const formData = new FormData();
	formData.append('file', params.file);
	const url = `/api/sharepoint/upload?documentId=${params.documentId}&driveId=${params.driveId}`;
	await api.put<void>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

const useUploadSharepointDocument = () => {
	const { isSuccess, mutateAsync, isError, isPending, data: response } = useMutation({
		mutationFn: uploadSharepointDocument,
	});

	return {
		mutateAsync,
		isError,
		isSuccess,
		response,
		isPending,
	};
};

export default useUploadSharepointDocument;
