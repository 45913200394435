import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ArchivableProjectFolderInfoResponseInterface from '../../interfaces/response/ArchivableFolderInfoResponseInterface';


const getFolderInfo: QueryFunction<ArchivableProjectFolderInfoResponseInterface> = async ({ queryKey }: QueryFunctionContext) => {
	const response = await api.get<ArchivableProjectFolderInfoResponseInterface>(`/api/archive/folders/info?folderUrl=${encodeURI(queryKey[0] as string)}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetFolderInfo = (folderUrl?: string) => {
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: [folderUrl, 'getFolderInfo'],
		queryFn: getFolderInfo,
		enabled: false,
	});

	return {
		folderInfo: data,
		isFetching,
		error,
		refetch,
	};
};

export default useGetFolderInfo;
