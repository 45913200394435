import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import ProjectSearchResponse from '../../interfaces/response/ProjectSearchResponse';

const pageSize = 100;
const emptyResult: ProjectSearchResponse[] = [];

const getProjectList: QueryFunction<ProjectSearchResponse[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	let url = `/api/google/projects?searchTerm=${encodeURIComponent(queryKey[0] as string)}&count=${pageSize}`;
	if (queryKey[1]) {
		url += `&clientId=${queryKey[1]}`;
	}
	const response = await api.get<ProjectSearchResponse[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetProjectList = (searchTerm?: string, clientId?: string) => {
	const { data, isLoading, isFetching, error } = useQuery({
		queryKey: [searchTerm, clientId, 'getProjectList'],
		queryFn: getProjectList,
		enabled: !!searchTerm,
	});

	return {
		projectResults: data || emptyResult,
		isLoading,
		isFetching,
		error,
	};
};

export default useGetProjectList;
