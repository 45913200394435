import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import EntityInfoResponseInterface from '../../interfaces/response/EntityInfoResponseInterface';

const getEntityInfo: QueryFunction<EntityInfoResponseInterface> = async ({ queryKey }: QueryFunctionContext) => {
	const url = `/api/tlo/entities/${queryKey[0]}`;
	const response = await api.get<EntityInfoResponseInterface>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetEntityInfo = (entityId: string) => {
	const { data, isLoading } = useQuery({
		queryKey: [entityId, 'getEntityInfo'],
		queryFn: getEntityInfo,
	});

	return {
		data,
		isLoading,
	};
};

export default useGetEntityInfo;
