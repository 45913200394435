import ToolsItem from './ToolsItem';
import { Dialog } from '@fluentui/react-components';
import React, { useState } from 'react';
import { DialogOpenChangeEventHandler } from '@fluentui/react-dialog';

type Props = {
	content: string;
	icon: JSX.Element;
	title: string;
	subTitle: string;
	children: ((setOpen: (value: boolean) => void) => JSX.Element) | JSX.Element;
}

const ToolsItemDialog: React.FC<Props> = ({ children, content, title, subTitle, icon }) => {
	const [isOpen, setIsOpen] = useState(false);

	const openDialog = () => {
		setIsOpen(true);
	};

	const onIsOpenChange: DialogOpenChangeEventHandler = (_, data) => {
		setIsOpen(data.open);
	};

	return (
		<Dialog modalType={'modal'} onOpenChange={onIsOpenChange} open={isOpen}>
			<ToolsItem
				onClick={openDialog}
				content={content}
				subTitle={subTitle}
				title={title}
				icon={icon}
			/>
			{typeof (children) === 'function' ? children(setIsOpen) : children}
		</Dialog>
	);
};

export default ToolsItemDialog;
