import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const getClientInfo: QueryFunction<NameAndId> = async ({ queryKey }: QueryFunctionContext) => {
	const url = `/api/tlo/clients/${queryKey[0]}`;
	const response = await api.get<NameAndId>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetClientInfo = (clientId?: string) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: [clientId, 'getClientInfo'],
		queryFn: getClientInfo,
		enabled: !!clientId,
	});

	return {
		data,
		isLoading,
		isError,
	};
};

export default useGetClientInfo;
