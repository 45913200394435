import OptionSectionTemplateConfigurationResponse from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import React, { memo, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import DocumentGenerationOption from './DocumentGenerationOption';
import { Button, Divider, makeStyles, Text, tokens } from '@fluentui/react-components';
import { AddCircleFilled } from '@fluentui/react-icons';
import DocumentGenerationContext from '../../context/DocumentGenerationContext';
import DocumentGenerationRequestDataContext from '../../context/DocumentGenerationRequestDataContext';
import GenerateDocumentDataInterface from '../../interfaces/request/GenerateDocumentDataInterface';

type Props = {
	section: OptionSectionTemplateConfigurationResponse;
}

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
		display: 'flex',
	},
	sectionName: {
		fontSize: tokens.fontSizeBase400,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalM,
	},
	column: {
		flexDirection: 'column',
		display: 'flex',
	},
	row: {
		flexDirection: 'row',
		display: 'flex',
	},
	addButton: {
		marginBottom: tokens.spacingVerticalS,
	},
	divider: {
		marginBottom: tokens.spacingVerticalS,
		marginTop: tokens.spacingVerticalS,
	},
});

type SectionProps = {
	section: OptionSectionTemplateConfigurationResponse;
	requestData: GenerateDocumentDataInterface;
	index: number;
	selectedDocumentId?: string;
}

const getSectionId = (section: OptionSectionTemplateConfigurationResponse, index: number) => {
	return section.allowMultiple ? `${section.id}-${index}` : section.id;
};

const Section: React.FC<SectionProps> = memo(({ section, requestData, index, selectedDocumentId }) => {
	const classes = useClasses();
	const sectionId = getSectionId(section, index);
	const [sectionData, updateSectionData] = useReducer(() => requestData[sectionId], requestData[sectionId] ?? {});

	useEffect(() => {
		if (!requestData?.[sectionId] || JSON.stringify(sectionData) === JSON.stringify(requestData[sectionId])) return;
		updateSectionData();
	}, [requestData, sectionData, sectionId]);

	return (
		<div className={classes.column}>
			{section.allowMultiple && <Divider className={classes.divider}>{section.name} {index + 1}</Divider>}
			{section.options.map((option) => (
				<DocumentGenerationOption
					index={index}
					data={sectionData}
					section={sectionId}
					key={option.id + selectedDocumentId + index}
					option={option} />
			))}
		</div>
	);
});

const DocumentGenerationOptionSection: React.FC<Props> = ({ section }) => {
	const classes = useClasses();
	const requestData = useContext(DocumentGenerationRequestDataContext);
	const { selectedDocument, updateRequestDataSection } = useContext(DocumentGenerationContext);
	const count = useMemo<number>(() => {
		if (!section.allowMultiple) return 1;
		return Object.keys(requestData).filter(c => c.startsWith(section.id)).length;
	}, [requestData, section]);

	const sections = useMemo(() => Array.from({ length: count }, (_, index) => {
		return <Section section={section} requestData={requestData} selectedDocumentId={selectedDocument?.id} index={index} key={index} />;
	}), [requestData, selectedDocument, count, section]);

	const add = useCallback(() => {
		updateRequestDataSection(`${section.id}-${count}`, {});
	}, [count, section, updateRequestDataSection]);

	return (
		<div className={classes.container}>
			<div className={classes.row}>
				<Text className={classes.sectionName}>{section.name}</Text>
				{section.allowMultiple && <Button onClick={add} className={classes.addButton} size="small" appearance={'transparent'} icon={<AddCircleFilled />} />}
			</div>
			<div className={classes.column}>
				{sections}
			</div>
		</div>
	);
};

export default DocumentGenerationOptionSection;
