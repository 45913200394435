import {
	Body1,
	Body1Strong,
	Button,
	Caption1,
	Card,
	CardHeader,
	CardPreview,
	Dialog,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogProps,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	makeStyles,
	mergeClasses,
	Spinner,
	Title2,
	tokens,
} from '@fluentui/react-components';
import React, { ReactElement, useState } from 'react';
import useGetInnovaphoneCalls from '../hooks/queries/useGetInnovaphoneCalls';
import TelephoneCallInformation from '../components/TelephonyCallInformation';
import { CallCheckmarkFilled, CallConnectingFilled, CallFilled, CallParkFilled } from '@fluentui/react-icons';
import InnovaphoneCallsResponseInterface from '../interfaces/response/InnovaphoneCallsResponseInterface';
import innovaphoneCallsResponseInterface from '../interfaces/response/InnovaphoneCallsResponseInterface';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const useClasses = makeStyles({
	blurred: {
		filter: 'blur(2px)',
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		margin: tokens.spacingVerticalXXL,
	},
	column: {
		flexDirection: 'column',
		display: 'flex',
	},
	card: {
		height: 'fit-content',
		width: '360px',
		userSelect: 'none',
	},
	horizontalCardImage: {
		height: '32px',
		paddingLeft: tokens.spacingHorizontalM,
	},
	cardContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: tokens.spacingHorizontalMNudge,
	},
	refreshButton: {
		marginBottom: tokens.spacingVerticalM,
		width: '12em',
	},
	title: {
		marginBottom: tokens.spacingVerticalL,
	},
	dialogContainer: {
		maxWidth: '75%',
	},
});

const getIconForCall = (call: InnovaphoneCallsResponseInterface): ReactElement => {
	switch (call.state) {
		case 'Connecting':
			return <CallConnectingFilled style={{ color: tokens.colorPaletteYellowForeground1 }} />;
		case 'OnHold':
			return <CallParkFilled style={{ color: tokens.colorPaletteRedForeground1 }} />;
		case 'Unknown':
			return <CallFilled style={{ color: tokens.colorPaletteBlueForeground2 }} />;
		default:
			return <CallCheckmarkFilled style={{ color: tokens.colorPaletteGreenForeground1 }} />;
	}
};

const getCallDescription = (call: innovaphoneCallsResponseInterface, t: TFunction): ReactElement => {
	switch (call.state) {
		case 'Connecting':
			return <Caption1><Trans i18nKey={'telephony.connectingDescription'} values={{ caller: call.caller }} /></Caption1>;
		case 'OnHold':
			return <Caption1><Trans i18nKey={'telephony.onHoldDescription'} values={{ caller: call.caller }} /></Caption1>;
		case 'Unknown':
		case 'Active':
			return <Caption1><Trans i18nKey={'telephony.activeCallDescription'} values={{ caller: call.caller }} /></Caption1>;
	}
};

const TelephonyPage = () => {
	const [transferCallDialogOpen, setTransferCallDialogOpen] = useState<boolean>(false);
	const { t } = useTranslation();
	const calls = useGetInnovaphoneCalls();
	const classes = useClasses();
	const [selectedCall, setSelectedCall] = useState<InnovaphoneCallsResponseInterface>();
	const [dialogOpen, setDialogOpen] = useState(false);

	const refreshCalls = () => {
		calls.refetch();
	};

	const openCallInfo = (call: InnovaphoneCallsResponseInterface) => {
		setSelectedCall(call);
		setDialogOpen(true);
	};

	const onDialogOpenChange: DialogProps['onOpenChange'] = (_, data) => {
		setDialogOpen(data.open);
	};

	const closeDialog = () => {
		setDialogOpen(false);
	};

	const dialogRoot = mergeClasses(classes.dialogContainer, transferCallDialogOpen ? classes.blurred : undefined);
	return (
		<div className={classes.root}>
			<Title2 className={classes.title}>{t('telephony.title')}</Title2>
			<Button onClick={refreshCalls} appearance={'primary'} className={classes.refreshButton}>{t('telephony.refreshCalls')}</Button>
			{calls.isFetching ? <Spinner /> : (
				(calls.data && calls.data.length > 0) ? (
					<div className={classes.cardContainer}>
						{calls.data.map((call, index) => (
							<Card className={classes.card} orientation={'horizontal'} onClick={() => openCallInfo(call)} key={index}>
								<CardPreview className={classes.horizontalCardImage}>
									{getIconForCall(call)}
								</CardPreview>
								<CardHeader
									header={<Body1Strong>{call.callee}</Body1Strong>}
									description={getCallDescription(call, t)}
								/>
							</Card>
						))}
					</div>
				) : <Body1>{t('telephony.noActiveCalls')}</Body1>
			)}
			<Dialog open={dialogOpen} onOpenChange={onDialogOpenChange} modalType={'modal'}>
				<DialogSurface className={dialogRoot}>
					<DialogBody>
						<DialogTitle>{t('telephony.callFrom', { callee: selectedCall?.callee })}</DialogTitle>
						<DialogContent>
							{selectedCall && <TelephoneCallInformation setSummaryDialogOpen={setDialogOpen} call={selectedCall} setTransferCallDialogOpen={setTransferCallDialogOpen}
																					 transferCallDialogOpen={transferCallDialogOpen} />}
						</DialogContent>
						<DialogActions>
							<DialogTrigger>
								<Button appearance="secondary" onClick={closeDialog}>{t('telephony.close')}</Button>
							</DialogTrigger>
						</DialogActions>
					</DialogBody>
				</DialogSurface>
			</Dialog>

		</div>
	);
};

export default TelephonyPage;
