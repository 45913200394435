import {
	Button,
	createTableColumn,
	DataGrid,
	DataGridBody,
	DataGridCell,
	DataGridHeader,
	DataGridHeaderCell,
	DataGridProps,
	DataGridRow,
	DialogActions,
	DialogBody,
	DialogContent,
	DialogSurface,
	DialogTitle,
	DialogTrigger,
	Link,
	makeStyles,
	Spinner,
	TableCellLayout,
	TableColumnDefinition,
	TableColumnId,
	Text,
	tokens,
} from '@fluentui/react-components';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronLeftFilled, ChevronRightFilled, Dismiss24Regular } from '@fluentui/react-icons';
import useGetTypeformFormResponses, { TypeformResponse } from '../../hooks/queries/useGetTypeformFormResponses';
import { useTranslation } from 'react-i18next';

const tevredenheidsBevragingFormId = 'YvV52LEw';
const CloseButton = (
	<DialogTrigger action="close">
		<Button
			appearance="subtle"
			aria-label="close"
			icon={<Dismiss24Regular />}
		/>
	</DialogTrigger>
);

const useClasses = makeStyles({
	container: {
		maxWidth: '75%',
	},
	table: {
		maxHeight: '60vh',
		overflowY: 'scroll',
	},
	noSelect: {
		userSelect: 'none',
		wordBreak: 'break-word',
	},
	bottomContainer: {
		marginTop: tokens.spacingVerticalMNudge,
	},
	monthName: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const getDate = (date: Date, language: string): string => {
	const dateObject = new Date(date);
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	return dateObject.toLocaleDateString(language === 'nl' ? 'nl-BE' : language === 'fr' ? 'fr-BE' : language, options);
};

const TypeformDialog = () => {
	const defaultSortState = React.useMemo<
		Parameters<NonNullable<DataGridProps['onSortChange']>>[1]
	>(() => ({ sortColumn: 'submittedAt', sortDirection: 'descending' }), []);
	const { t, i18n: { language } } = useTranslation();
	const classes = useClasses();
	const [endDate, setEndDate] = useState<Date>(new Date());
	const startDate = useMemo<Date>(() => {
		const month = endDate.getMonth();
		if (month === 1) {
			return new Date(endDate.getFullYear() - 1, 12, endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), endDate.getMilliseconds());
		}
		return new Date(endDate.getFullYear(), month - 1, endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), endDate.getMilliseconds());
	}, [endDate]);
	const typeformResponses = useGetTypeformFormResponses(startDate, endDate, tevredenheidsBevragingFormId);
	const responseColumns = useMemo<TableColumnDefinition<TypeformResponse>[]>(() => {
		if (!typeformResponses.form || typeformResponses.form.length === 0) return [];
		const allQuestions: string[] = [];
		const columns: TableColumnDefinition<TypeformResponse>[] = [
			createTableColumn<TypeformResponse>({
				columnId: 'submittedAt',
				renderHeaderCell: (_) => (
					<Text className={classes.noSelect}>
						<b>{t('toolsPage.tevredenheidsBevraging.submittedAt')}</b>
					</Text>
				),
				renderCell: (item) => (
					<TableCellLayout>
						{getDate(item.date, language)}
					</TableCellLayout>
				),
				compare: (a, b) => a.date.valueOf() - b.date.valueOf(),
			}),
			createTableColumn<TypeformResponse>({
				columnId: 'projectLink',
				renderHeaderCell: (_) => (
					<Text className={classes.noSelect}>
						<b>{t('toolsPage.tevredenheidsBevraging.projectLead')}</b>
					</Text>
				),
				renderCell: (item) => (
					<TableCellLayout>
						{!!item.projectId ?
							<Link as={'a'} rel={'noreferrer'} href={`https://teamleaderorbit.unitedexperts.be/project/${item.projectId}`}
									target={'_blank'}>{item.projectLead}</Link> :
							t('toolsPage.tevredenheidsBevraging.noProjectFound')
						}
					</TableCellLayout>
				),
			}),
		];
		typeformResponses.form.forEach(entry => {
			entry.answers.forEach(answer => {
				if (allQuestions.includes(answer.id)) return;
				allQuestions.push(answer.id);
				columns.push(createTableColumn<TypeformResponse>({
					columnId: answer.id,
					renderHeaderCell: (_) => (
						<Text className={classes.noSelect}>
							<b>{answer.question}</b>
						</Text>
					),
					renderCell: (item) => (
						<TableCellLayout>
							{item.answers.find(a => a.id === answer.id)?.answer ?? ''}
						</TableCellLayout>
					),
				}));
			});
		});

		return columns;
	}, [typeformResponses.form, classes, t, language]);

	const getStyle = useCallback((columnId: TableColumnId) => {
		if (!typeformResponses.form || typeformResponses.form.length === 0) return {};
		const type = typeformResponses.form[0].answers.find(c => c.id === columnId)?.type;
		return (type === 'number' || type === 'boolean') ? { minWidth: '10em' } : { minWidth: '20em' };
	}, [typeformResponses.form]);

	const months = useMemo(() => [
		t('datepicker.months.january'),
		t('datepicker.months.february'),
		t('datepicker.months.march'),
		t('datepicker.months.april'),
		t('datepicker.months.may'),
		t('datepicker.months.june'),
		t('datepicker.months.july'),
		t('datepicker.months.august'),
		t('datepicker.months.september'),
		t('datepicker.months.october'),
		t('datepicker.months.november'),
		t('datepicker.months.december'),
	], [t]);
	const canGoToNextMonth = useMemo<boolean>(() => endDate.toDateString() === new Date().toDateString(), [endDate]);
	const goToPreviousMonth = useCallback(() => setEndDate(startDate), [startDate]);
	const goToNextMonth = useCallback(() => {
		setEndDate(_ => {
			const month = endDate.getMonth();
			if (month === 12) {
				return new Date(endDate.getFullYear() + 1, 1, endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), endDate.getMilliseconds());
			}
			return new Date(endDate.getFullYear(), month + 1, endDate.getDate(), endDate.getHours(), endDate.getMinutes(), endDate.getSeconds(), endDate.getMilliseconds());
		});
	}, [endDate]);

	return (
		<DialogSurface className={classes.container}>
			<DialogBody>
				<DialogTitle action={CloseButton}>{t('toolsPage.tevredenheidsBevraging.title')} - {months[endDate.getMonth()]} {endDate.getFullYear()}</DialogTitle>
				<DialogContent>
					{typeformResponses.isLoading && <Spinner />}
					{typeformResponses.form && (
						<DataGrid
							items={typeformResponses.form}
							columns={responseColumns}
							resizableColumns
							sortable
							defaultSortState={defaultSortState}
							resizableColumnsOptions={{ autoFitColumns: false }}
							getRowId={(item) => item.id}
						>
							<DataGridHeader>
								<DataGridRow>
									{({ renderHeaderCell, columnId }) => <DataGridHeaderCell style={getStyle(columnId)}>{renderHeaderCell()}</DataGridHeaderCell>}
								</DataGridRow>
							</DataGridHeader>
							<DataGridBody<TypeformResponse> className={classes.table}>
								{({ item, rowId }) => (
									<DataGridRow<TypeformResponse> key={rowId}>
										{({ renderCell, columnId }) => (
											<DataGridCell key={columnId} style={getStyle(columnId)}>{renderCell(item)}</DataGridCell>
										)}
									</DataGridRow>
								)}
							</DataGridBody>
						</DataGrid>
					)}
				</DialogContent>
				<DialogActions className={classes.bottomContainer}>
					<Button appearance={'primary'} iconPosition={'before'} onClick={goToPreviousMonth} icon={<ChevronLeftFilled />}>{t('toolsPage.tevredenheidsBevraging.previousMonth')}</Button>
					<Button appearance={'primary'} disabled={canGoToNextMonth} iconPosition={'after'} onClick={goToNextMonth} icon={<ChevronRightFilled />}>
						{t('toolsPage.tevredenheidsBevraging.nextMonth')}
					</Button>
				</DialogActions>
			</DialogBody>
		</DialogSurface>
	);
};

export default TypeformDialog;
